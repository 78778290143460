import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers';
import { TourProvider } from '@reactour/tour';
import { seenOnboardingModal } from './api';
import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Button,
    CardActions,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
const steps = [
    {
        selector: '[data-tour="step-1"]',
        content:
            'Welcome! looks like this is your first time in the Entermedicare app. To view plans you will want to click here.',
    },
    {
        selector: '[data-tour="step-2"]',
        content:
            'This is where you can get caught up to speed and learn more about medicare programs. This is open to you at any time whether you are loggedin or not.',
    },
    {
        selector: '[data-tour="step-3"]',
        content:
            'This is where all your answers from questions get stored. Entermedicare professionals will be able to view these answers when you book an appointment with us.',
    },
    {
        selector: '[data-tour="step-4"]',
        content:
            'This is where you can view plans in your area. While all offerings are valid for your zipcode, we cannot display plan names due to CMS regulations and you will need to talk to a medicare professional to learn more about them',
    },
    {
        selector: '[data-tour="step-5"]',
        content:
            'If you still would like to speak to us, please fill out the contact form or use the chat box popup in the bottom right corner',
    },
    // ...
];

Sentry.init({
    dsn:
        'https://cdbf0177b3e2499f9bd35ac11f64bd85@o340186.ingest.sentry.io/5380459',
    whitelistUrls: [/(https?:\/\/)?learn\.entermedicare\.com/],
});

const store = createStore(rootReducer);

function ContentComponent(props) {
    const isLastStep = props.currentStep === props.steps.length - 1;
    const content = props.steps[props.currentStep].content;
    return (
        <Card>
            <CardHeader
                action={
                    <IconButton
                        onClick={async () => {
                            props.setIsOpen(false);
                            window.localStorage.setItem('closeModal', true);
                            await seenOnboardingModal(true);
                        }}
                    >
                        <Close />
                    </IconButton>
                }
                title="Entermedicare Welcome Tour"
                subheader={`Step ${props.currentStep + 1}`}
            />
            <CardContent>{content}</CardContent>

            <CardActions>
                <Button
                    onClick={async () => {
                        if (isLastStep) {
                            props.setIsOpen(false);
                            window.localStorage.setItem('closeModal', true);
                            await seenOnboardingModal(true);
                        } else {
                            props.setCurrentStep(s => s + 1);
                        }
                    }}
                >
                    {isLastStep ? 'Close' : 'Next Step'}
                </Button>
            </CardActions>
        </Card>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <TourProvider steps={steps} ContentComponent={ContentComponent}>
                <App />
            </TourProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
