import React, { Component } from 'react';
import { Typography, withStyles, Grid, TextField } from '@material-ui/core';
import EMButton from '../components/EmButton';
import EMTextField from '../components/EMTextfield';
import EMSnackbar from '../components/EMSnackbar';
import { updatePersonalDetails, getProfile } from '../api';
import { find, get } from 'lodash';
import moment from 'moment';

const styles = theme => ({
    pageContainer: {
        background: theme.palette.primary.white,
        border: '1px solid #E1E7ED',
        boxShadow: '0 1px 8px 0 rgb(0 0 0 / 8%)',
        borderRadius: 8,
        marginTop: 66,
        padding: 0,
        paddingBottom: theme.spacing(4),
        margin: '0 auto',
    },
    header: {
        lineHeight: 0.5,
        borderBottom: '2px solid #EFF2F5',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        whiteSpace: 'pre-wrap',
        color: '#4d5055',
    },
    subheader: {
        padding: theme.spacing(4),
        paddingBottom: 0,
        color: '#4d5055',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        paddingTop: '16px',
    },
    submitButton: {
        width: '180px',
        marginLeft: 'auto',
        marginRight: 0,
        float: 'right',
    },
});

const promptText = `Settings`;
const promptSubText = 'You can update your settings below';
const successText = 'We will be in touch soon!';
const successSubText =
    'We are finding the right expert for your message, and will get back in touch!';
class Settings extends Component {
    state = {
        body: '',
        error: false,
        isLoading: false,
        subject: '',
        submitSuccessful: false,
        snackbar: { show: false, message: '', variant: '' },
        profile: {
            broker: {},
        },
        editMode: false,
        firstName: '',
        lastName: '',
        zip: '',
        dob: '',
        email: '',
    };

    async componentDidMount() {
        // grab the customer's broker;
        const { result } = await getProfile();
        let nameObject = find(get(result, 'sections[0].data', []), datum => {
            return datum.label === 'Name:';
        });
        let birthdateObject = find(
            get(result, 'sections[0].data', []),
            datum => {
                return datum.decisionType === 'BIRTH_DATE';
            }
        );
        let zipcodeOpject = find(get(result, 'sections[0].data', []), datum => {
            return datum.decisionType === 'ZIP_CODE';
        });
        let dob = get(birthdateObject, 'value', '');
        let zip = get(zipcodeOpject, 'value', '');
        let name = get(nameObject, 'value');
        let firstName = '';
        let lastName = '';
        let email = result.email;
        if (name) {
            [firstName, lastName] = name.split(' ');
        }
        this.setState({
            profile: result,
            firstName,
            lastName,
            dob,
            zip,
            email,
        });
    }

    maybeRenderSnackbar = () => {
        const { snackbar } = this.state;
        return (
            snackbar.show && (
                <EMSnackbar
                    variant={snackbar.variant}
                    message={snackbar.message}
                />
            )
        );
    };

    handleUpdatePersonalDetails = async () => {
        const { firstName, lastName, zip, dob, email } = this.state;
        const { error, result } = await updatePersonalDetails({
            firstName,
            lastName,
            zip,
            dob,
            email,
        });
        if (!error)
            this.setState({
                profile: result,
                snackbar: { show: true, variant: 'success', message: 'saved' },
            });
        else
            this.setState({
                snackbar: {
                    show: true,
                    variant: 'error',
                    message: 'did not save',
                },
            });
    };

    render() {
        const { classes } = this.props;
        const { isLoading, submitSuccessful } = this.state;

        return (
            <div className={classes.root}>
                <Grid container className={classes.gridParent}>
                    (
                    <>
                        <Grid item sm={2} />
                        <Grid item sm={8} style={{ height: 1200 }}>
                            <div className={classes.pageContainer}>
                                <div
                                    className={classes.header}
                                    id={'settings-header-content'}
                                >
                                    {submitSuccessful
                                        ? successText
                                        : promptText}
                                </div>
                                <div className={classes.subheader}>
                                    <Typography variant="subtitle2">
                                        {submitSuccessful
                                            ? successSubText
                                            : promptSubText}
                                    </Typography>
                                </div>
                                {!submitSuccessful && (
                                    <div className={classes.form}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <EMTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id={'firstName'}
                                                    label={'First Name'}
                                                    name={'firstName'}
                                                    disabled={
                                                        this.state.editMode
                                                    }
                                                    value={this.state.firstName}
                                                    autoFocus
                                                    onChange={e => {
                                                        this.setState({
                                                            firstName:
                                                                e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id={'lastName'}
                                                    label={'Last Name'}
                                                    name={'lastName'}
                                                    value={this.state.lastName}
                                                    disabled={
                                                        this.state.editMode
                                                    }
                                                    onChange={e => {
                                                        this.setState({
                                                            lastName:
                                                                e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id={'email'}
                                                    label={'email'}
                                                    name={'email'}
                                                    value={this.state.email}
                                                    autoFocus
                                                    onChange={e => {
                                                        this.setState({
                                                            email:
                                                                e.target.value,
                                                        });
                                                    }}
                                                    InputLabelProps={{
                                                        shrink:
                                                            this.state.email !==
                                                            '',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id={'zip'}
                                                    label={'Zipcode'}
                                                    name={'zip'}
                                                    value={this.state.zip}
                                                    disabled={
                                                        this.state.editMode
                                                    }
                                                    autoFocus
                                                    onChange={e => {
                                                        this.setState({
                                                            zip: e.target.value,
                                                        });
                                                    }}
                                                    InputLabelProps={{
                                                        shrink:
                                                            this.state.zip !==
                                                            '',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <EMTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    type={'date'}
                                                    id={'dob'}
                                                    label={'Date of Birth'}
                                                    name={'dob'}
                                                    value={moment(
                                                        this.state.dob,
                                                        'MM/DD/YYYY'
                                                    ).format('YYYY-MM-DD')}
                                                    disabled={
                                                        this.state.editMode
                                                    }
                                                    autoFocus
                                                    onChange={e => {
                                                        this.setState({
                                                            dob: moment(
                                                                e.target.value,
                                                                'YYYY-MM-DD'
                                                            ).format(
                                                                'MM/DD/YYYY'
                                                            ),
                                                        });
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <EMButton
                                            isLoading={isLoading}
                                            className={classes.submitButton}
                                            onClick={
                                                this.handleUpdatePersonalDetails
                                            }
                                            text={'Submit'}
                                            color="primary"
                                        />
                                    </div>
                                )}
                                {this.maybeRenderSnackbar()}
                            </div>
                        </Grid>
                        <Grid item sm={2}>
                            {' '}
                        </Grid>
                    </>
                    )
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Settings);
