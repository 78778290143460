import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import DoctorLady from '../assets/DoctorLady.png';

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: '2em',
        fontWeight: 500,
        margin: '15px 0px',
    },
    body: {
        padding: theme.spacing(8),
        marginTop: 100,
        marginBottom: 100,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
        textAlign: 'left',
        flexDirection: 'column',
        width: '50%',
    },
}));

function NotFound() {
    const classes = useStyles();
    let subtext = `The page you're looking for does not exist.`;
    let header = `404`;

    return (
        <div className={classes.body}>
            <img src={DoctorLady} width="75" alt="ProducerAscent Doctor" />
            <Typography className={classes.header}>{header}</Typography>
            <Typography>{subtext}</Typography>
        </div>
    );
}

export default withRouter(NotFound);
