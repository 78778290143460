import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
    Drawer,
    Hidden,
    Link,
    List,
    ListItemText,
    MenuItem,
    Typography,
    Divider,
} from '@material-ui/core';

import EMButton from '../components/EmButton';
import { NavButtons } from '../constants';
import { logout, linkWithAccessToken } from '../utils';
import EntermedicareLogo from '../assets/LogoWhite.png';

const useStyles = makeStyles(theme => ({
    authButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    leftContainer: {
        fontFamily: 'Source Sans Pro, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        width: 280,
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectedOverride: {
        backgroundColor: `#E1E7ED`,
        borderRadius: '4px',
    },
    list: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    listItem: {
        color: '#092744',
        paddingLeft: 32,
        height: theme.spacing(8),
        minHeight: theme.spacing(8),
    },
    logoContainer: {
        marginLeft: 32,
        background: '#092744',
        borderRadius: 8,
        width: 'fit-content',
        minWidth: 50,
        textAlign: 'center',
        marginTop: 32,
    },
    logo: {
        height: 32,
        padding: 8,
    },
    paddingLeft: {
        paddingLeft: 16,
    },
    menuHeader: {
        textAlign: 'left',
        width: '100%',
        fontSize: theme.spacing(3.5),
    },
    toolbar: {
        ...theme.mixins.toolbar,
        paddingBottom: 16,
    },
}));

function EmDrawer(props) {
    const classes = useStyles();
    const toggleDrawer = (side, open) => event => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (!open) {
            props.handleClose();
        }
    };

    const handleLogout = () => {
        logout();
        props.logoutCallback();
        props.history.push('/');
    };

    const handleMenuClick = item => {
        if (item.isExternal) {
            let urlToUse = item.url;
            if (item.id === 'view-plans') {
                urlToUse = linkWithAccessToken(item.url);
            }
            window.location.href = urlToUse;
        } else {
            props.history.push(item.url);
        }
    };

    const sideList = side => (
        <div>
            <div className={classes.toolbar}>
                <Link href="/">
                    <div className={classes.logoContainer}>
                        <img
                            alt="entermedicare logo"
                            src={EntermedicareLogo}
                            className={classes.logo}
                        />
                    </div>
                </Link>
            </div>
            <Divider />
            <div className={classes.listContainer}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={toggleDrawer(side, false)}
                    onKeyDown={toggleDrawer(side, false)}
                >
                    <List>
                        {props.drawerItems.map((item, ind) => (
                            <div data-tour={item.dataStep} key={ind}>
                                <MenuItem
                                    button
                                    onClick={e => {
                                        e.preventDefault();
                                        handleMenuClick(item);
                                    }}
                                    selected={item.isActive ? true : false}
                                    classes={{
                                        root: classes.listItem,
                                    }}
                                >
                                    <ListItemText
                                        selected={item.isActive ? true : false}
                                        primary={
                                            <Typography
                                                type="body2"
                                                style={{
                                                    fontWeight: item.isActive
                                                        ? 'bold'
                                                        : undefined,
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {item.displayName}
                                            </Typography>
                                        }
                                    />
                                </MenuItem>
                            </div>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );

    const renderAuthButtons = () => {
        return (
            <div className={classes.authButtonContainer}>
                <Divider />
                {props.isAuthorized ? (
                    <List>
                        <MenuItem
                            button
                            onClick={() => {
                                props.history.push('/settings');
                                props.handleClose();
                            }}
                            classes={{ root: classes.listItem }}
                        >
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'body2',
                                    style: { fontSize: 20 },
                                }}
                                primary={'Settings'}
                            />
                        </MenuItem>
                        <MenuItem
                            button
                            onClick={handleLogout}
                            classes={{ root: classes.listItem }}
                        >
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'body2',
                                    style: { fontSize: 20 },
                                }}
                                primary={'Logout'}
                            />
                        </MenuItem>
                    </List>
                ) : (
                    NavButtons.map(b => (
                        <EMButton
                            color={'primary'}
                            key={b.displayName}
                            onClick={() => {
                                if (b.isSignUp) {
                                    props.signUp();
                                } else {
                                    props.signIn();
                                }
                                // props.history.push(b.url)
                            }}
                            style={{
                                marginTop: '10px',
                                backgroundColor: b.isSignUp
                                    ? ''
                                    : 'transparent',
                            }}
                            text={b.displayName}
                            variant={b.isSignUp ? 'contained' : 'text'}
                        />
                    ))
                )}
            </div>
        );
    };

    return (
        <nav>
            <Hidden mdUp implementation="css">
                <Drawer
                    open={props.open}
                    variant="temporary"
                    onClose={props.handleClose}
                    ModalProps={{ keepMounted: true }}
                >
                    <div className={classes.leftContainer}>
                        {sideList('left')}
                        {renderAuthButtons()}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer open variant="permanent">
                    <div className={classes.leftContainer}>
                        {sideList('left')}
                        {renderAuthButtons()}
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    );
}

export default withRouter(EmDrawer);

EmDrawer.propTypes = {
    drawerItems: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            url: PropTypes.string,
            icon: PropTypes.any,
        })
    ).isRequired,
    handleClose: PropTypes.func.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    logoutCallback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    signUp: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
};
