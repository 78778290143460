import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import EMButton from '../components/EmButton';
import EMModal from './Modal';

const useStyles = makeStyles(theme => ({
    modalContent: {
        paddingTop: theme.spacing(12),
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(24),
        fontFamily: theme.typography.h4.fontFamily,
        fontSize: 54,
        fontWeight: 500,
    },
    divider: {
        marginTop: theme.spacing(4.5),
        color: 'yellow',
        background: '#f9d76d',
        width: '110%',
        marginBottom: theme.spacing(4.5),
    },
    buttonGroup: {
        width: '100%',
        marginTop: theme.spacing(4.5),
        display: 'flex',
        flexDirection: 'row',
    },
    ctaButton: {
        width: '175px',
        height: theme.spacing(6.5),
        fontWeight: 'bold',
        whitespace: 'nowrap',
    },
    modalBodyFont: {
        fontWeight: 600,
        fontSize: 20,
    },
}));

function CourseCTAModal(props) {
    const { hideModalCallback, nextCourseId, profileComplete } = props;

    const classes = useStyles();

    const handleFinishProfileClick = () => {
        props.hideModalCallback();
        window.FS.event('Finish Profile Clicked');
        props.history.push('/profile');
    };

    const handleNextCourseClick = async () => {
        await props.history.push(
            `/course/${nextCourseId}${props.location.search}`
        );
        window.FS.event('Next Course Clicked', {
            nextCourseId,
            params: props.location.search,
        });
        props.hideModalCallback();
    };

    const handleLearningCenterClick = () => {
        props.hideModalCallback();
        window.FS.event('Learning Center Clicked', {
            params: props.location.search,
        });
        props.history.push(`/learning-center${props.location.search}`);
    };

    const modalContent = (
        <div className={classes.modalContent}>
            <div>
                <Typography variant="h1">Great!</Typography>
                <Divider light classes={{ root: classes.divider }} />
                <Typography
                    variant="subtitle2"
                    className={classes.modalBodyFont}
                >
                    What would you like to do next?
                </Typography>
                <div className={classes.buttonGroup}>
                    {!profileComplete && (
                        <EMButton
                            className={classes.ctaButton}
                            color="primary"
                            variant="contained"
                            onClick={handleFinishProfileClick}
                            text={'Finish Profile'}
                        />
                    )}
                    {nextCourseId && (
                        <EMButton
                            className={classes.ctaButton}
                            color={!profileComplete ? 'secondary' : 'primary'}
                            variant="contained"
                            onClick={handleNextCourseClick}
                            text={'Next Course'}
                        />
                    )}
                    <EMButton
                        className={classes.ctaButton}
                        color="secondary"
                        variant="contained"
                        onClick={handleLearningCenterClick}
                        text={'Learning Center'}
                    />
                </div>
            </div>
        </div>
    );

    return !profileComplete || nextCourseId ? (
        <EMModal
            title={''}
            isOpen
            content={modalContent}
            size="lg"
            handleClose={hideModalCallback}
        />
    ) : null;
}

export default withRouter(CourseCTAModal);
