import React from 'react';
import { green, red } from '@material-ui/core/colors';

export const determineCoverageColor = doctorCoverageStatus => {
    switch (doctorCoverageStatus) {
        case 'In-Network':
            return (
                <span style={{ color: green[500] }}>
                    {' '}
                    {doctorCoverageStatus}{' '}
                </span>
            );
        case 'No Data':
            return (
                <span style={{ color: '#666' }}> {doctorCoverageStatus} </span>
            );
        default:
            return (
                <span style={{ color: red[500] }}>
                    {' '}
                    {doctorCoverageStatus}{' '}
                </span>
            );
    }
};
