import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { EMYellow } from './constants';

const useStyles = makeStyles(theme => ({
    divider: {
        backgroundColor: EMYellow,
        marginBottom: theme.spacing(2),
    },
}));

function EMDivider(props) {
    const classes = useStyles();
    return (
        <Divider
            className={props.className ? props.className : classes.divider}
        />
    );
}

EMDivider.propTypes = {
    className: PropTypes.string,
};

export default EMDivider;
