import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    useMediaQuery,
    Slide,
    IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import EMButton from '../components/EmButton';
import { Cancel as EMCancelIcon } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    cta: {
        marginBottom: theme.spacing(12),
        marginTop: theme.spacing(2),
        width: '150px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 10,
    },
    modalTitle: {
        fontFamily: 'Source Sans Pro, sans-serif',
        textAlign: 'center',
        marginTop: '15px',
    },
    closeButton: { position: 'absolute', right: '15px', top: '5px' },
    img: {
        margin: '0 auto',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    ctaContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        margin: '0 auto',
    },
    imgContainer: {
        width: 75,
        margin: '0 auto',
    },
}));

export default function EmModal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOnClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleOnClose}
            fullScreen={fullScreen}
            maxWidth={props.size}
            fullWidth
            TransitionComponent={Transition}
            transitionDuration={500}
        >
            <DialogTitle className={classes.modalTitle}>
                {props.imageSrc && (
                    <div className={classes.imgContainer}>
                        <img
                            className={classes.img}
                            src={props.imageSrc}
                            alt={props.imageAlt || 'Entermedicare'}
                        />
                    </div>
                )}
                {props.hideCancel ? null : (
                    <div className={classes.closeButton}>
                        <IconButton onClick={handleOnClose}>
                            <EMCancelIcon />
                        </IconButton>
                    </div>
                )}
                {props.title}
            </DialogTitle>

            <DialogContent
                className={classes.paper}
                style={{
                    minHeight: props.minHeight ? props.minHeight : '400px',
                }}
            >
                {typeof props.content === 'string' ? (
                    <DialogContentText>{props.content}</DialogContentText>
                ) : (
                    props.content
                )}
            </DialogContent>
            <div className={classes.ctaContainer}>
                {props.primaryCtaText && props.primaryCtaCallback && (
                    <EMButton
                        className={classes.cta}
                        color="primary"
                        variant="contained"
                        onClick={props.primaryCtaCallback}
                        text={props.primaryCtaText}
                        style={props.buttonStyle}
                    />
                )}
                {props.secondaryCtaText && props.secondaryCtaCallback && (
                    <EMButton
                        className={classes.cta}
                        color="white"
                        variant="contained"
                        onClick={props.secondaryCtaCallback}
                        text={props.secondaryCtaText}
                        style={{ ...props.buttonStyle, float: 'right' }}
                    />
                )}
            </div>
        </Dialog>
    );
}

EmModal.propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    content: PropTypes.any,
    hideCancel: PropTypes.bool,
    isOpen: PropTypes.bool,
    minHeight: PropTypes.number,
    primaryCtaText: PropTypes.string,
    primaryCtaCallback: PropTypes.func,
    secondaryCtaText: PropTypes.string,
    secondaryCtaCallback: PropTypes.func,
};
