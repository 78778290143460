import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { PlanType } from '../../constants';
import { urlForEnv } from '../../utils';

const useStyles = makeStyles(theme => ({
    accordion: {
        color: '#092744',
    },
    root: {
        padding: theme.spacing(2),
        width: '100%',
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    paperHeader: {
        borderRadius: '8px 8px 0px 0px',
        border: '1px solid #E1E7ED',
        marginBottom: '4px',
    },
    header: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        textAlign: 'left',
        fontFamily: 'Source Sans Pro, sans-serif',
        color: '#092744',
    },
    body1: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '30.17px',
    },
}));

export default function PlanFaq(props) {
    const classes = useStyles();
    window.planFaqScheduleCallback = props.scheduleCallCallback;

    const faqContent = {
        MA: [
            {
                id: 1,
                summary: 'How did you rank these plans for me?',
                details: `<span>
                        We rank doctor coverage first, because we want you to be
                        able to keep your doctors. Second is Drug coverage and cost,
                        followed by premium, deductible, star rating and finally
                        max-out-of-pocket.
                    </span>`,
            },
            {
                id: 2,
                summary: 'How do I enroll? What are next steps from here?',
                details: `<span>
                        We put your next enrollment window on the home screen. <a href="${urlForEnv()}">Click to go to the home screen.</a>
                        <br /><br />
                        If you are in or nearing an enrollment window, we recommend
                        you schedule a call with a medicare expert. <div style="cursor:pointer;color:blue;text-decoration:underline" onClick="window.planFaqScheduleCallback()">Click here to schedule a call.</div>
                        <br />
                        If you are more than three months from an enrollment window, you should check back as you near your enrollment time, to make sure you have the most up-to-date information.
                        </span>`,
            },
            {
                id: 3,
                summary: 'How should I compare? Should I just look at premium?',
                details: `<span>
                        Premium is a great start but there are other important
                        factors. Copays and coinsurance on visits and procedures, as
                        well as out of pocket drug costs are also important factors.
                        A Medicare expert can be helpful in understanding out of
                        pocket costs. <div style="cursor:pointer;color:blue;text-decoration:underline" onClick="window.planFaqScheduleCallback()">Click here to schedule a call.</div>
                    </span>`,
            },
            {
                id: 4,
                summary:
                    'PPO vs. HMO? What is good about each? What might I be giving up?',
                details: `<span>
                        PPO plans often have broader doctor choice and often do not
                        require referrals to see a specialist. The trade off is
                        these plans tend to have higher premiums or out of pocket
                        expenses compared to an HMO. Keep in mind, if a doctor is
                        not in network with the PPO plan, you likely will pay more
                        for that visit compared to an in network provider.
                        <br /><br />
                        HMO plans typically have lower monthly premiums and lower
                        out of pocket costs. However care is often not covered if
                        received out of network, and you likely need a referral to
                        see a specialist.
                    </span>`,
            },
            {
                id: 5,
                summary:
                    'What’s the difference between copays and coinsurance?',
                details: `<span>
                        A copay is a fixed cost for a service. $5 for a month’s
                        supply of a drug, or $10 per doctor visit are examples of
                        copays.
                        <br /><br />
                        Coinsurance is a cost sharing with your insurer. This is the
                        percent of the cost you must pay. For example, if you have
                        25% coinsurance for a bill of $200, you owe $50.
                    </span>`,
            },
            {
                id: 6,
                summary: 'Why can’t I add my spouse?',
                details: `<span>
                        You sign up for Medicare as an individual. You can sign up
                        for the same Medicare plan as your spouse, but each of you
                        will have your own coverage. Have your spouse create an
                        account so we can help them determine their eligibility,
                        timelines and great fit coverage!
                    </span>`,
            },
            {
                id: 7,
                summary:
                    'What’s the difference between Medicare Advantage and Medicare Supplement?',
                details: `<span>
                        Medicare Advantage is when a private insurance company
                        manages your Medicare Part A and Part B. These plans can,
                        but are not required to, include prescription drug coverage.
                        <br /><br />
                        Medicare Supplement covers Medicare approved costs, after
                        the government covers their Part A and Part B requirements.
                        Prescription drugs are not covered, and you must add this
                        coverage separately.
                    </span>`,
            },
            {
                id: 8,
                summary: 'How should I read star ratings?',
                details: `<span>
                        Every year, Medicare gives plans one to five stars,
                        evaluating everything from quality of care to customer
                        service. Plans with no star rating are too new to be rated.
                        You can switch to a five star plan at any point in the year,
                        not just during an enrollment period.
                    </span>`,
            },
            {
                id: 9,
                summary: 'What is COBRA?',
                details: `<span>
                        COBRA is a federal law that may let you keep your employer group 
                        health plan coverage for a limited time after your employment 
                        ends or you lose coverage as a dependent of the covered employee.  
                        This is called "continuation coverage. You can read more 
                        <a href="https://www.medicare.gov/supplements-other-insurance/how-medicare-works-with-other-insurance/cobra-7-important-facts">here</a>.
                </span>`,
            },
        ],
        SUPP: [
            {
                id: 1,
                summary: 'How did you rank these plans for me?',
                details: `<span>
                        First we start with plans from the largest, most stable
                        insurance carriers. Then we display the lowest cost plans in
                        your area for three of the most popular Medicare Supplement
                        Plan letters K, N and G.
                    </span>`,
            },
            {
                id: 2,
                summary: 'What is the difference between Plans G, N, K, and L?',
                details: `<span>
                        Plan G is the most comprehensive available if you became
                        eligible for Medicare after Jan 1, 2020. The main cost not
                        covered is the Part B deductible.
                        <br /><br />
                        Plan N is similar to G, but does not cover Medicare excess
                        charges. This plan might make more sense if you live and
                        receive care in a state that does not allow Medicare excess
                        charges (CN, MA, MN, NY, OH, PA, RI and VT). In other states
                        to avoid doctor excess charges (up to 15% above Medicare
                        approved rates), look for doctors who accept ‘assignment’.
                        <br /><br />
                        Plan K is more basic coverage, but lower premiums. You have
                        to pay coinsurance for some services on the amount left over
                        after the government has paid. Because there is coinsurance,
                        Plan K has a maximum out of pocket ($5,880 in 2020).
                        <br /><br />
                        Plan L is similar to Plan K because you pay a coinsurance for some services,
                        but Plan L covers a larger percent of the amount left over after the
                        government has paid. Plan L has a maximum out of pocket ($2,940 in 2020).
                    </span>`,
            },
            {
                id: 3,
                summary: 'How should I compare?',
                details: `<span>
                        First, determine which plan type fits, based on how much you
                        are comfortable paying each month and your comfort with the
                        coinsurance amount for each plan. Once you have an idea, a
                        Medicare expert can help you choose the exact policy. Many
                        insurers offer Plans G, N, and K, and a medicare expert will
                        be a great resource in making that decision. <div style="cursor:pointer;color:blue;text-decoration:underline" onClick="window.planFaqScheduleCallback()">Click here to schedule a call.</div>
                    </span>`,
            },
            {
                id: 4,
                summary: 'Does Medicare Supplement cover prescriptions?',
                details: `<span>
                        Medicare Supplement does not cover most prescriptions you
                        get from a pharmacy. You will need to buy this coverage
                        separately. Click the dropdown to view Prescription Drug Plans available
                        to you.
                    </span>`,
            },
        ],
        PDP: [
            {
                id: 1,
                summary: 'How did you rank these plans for me?',
                details: `<span>
                        In the EnterMedicare ranking, we first find the plans that
                        cover the most drugs you take, then we sort these plans by
                        cost. If there is still a tie, we rank the remaining plans
                        by premium, deductible, and star rating.
                    </span>`,
            },
            {
                id: 2,
                summary: 'How do I enroll? What are next steps from here?',
                details: `<span>
                        We put your next enrollment window on the home screen. <a href="${urlForEnv()}">Click to go to the home screen.</a>
                        <br /><br />
                        If you are in or nearing an enrollment window, we recommend
                        you schedule a call with a medicare expert. <div style="cursor:pointer;color:blue;text-decoration:underline" onClick="window.planFaqScheduleCallback()">Click here to schedule a call.</div>
                        <br /><br />
                        If you are more than three months from an enrollment window, you should check back as you near your enrollment time, to make sure you have the most up-to-date information.
                    </span>`,
            },
            {
                id: 3,
                summary: 'How should I compare? Should I just look at premium?',
                details: `<span>
                        Premium is a great start, copayment and coinsurance costs
                        are another important factor. A Medicare expert is often
                        helpful for this step.
                    </span>`,
            },
            {
                id: 4,
                summary:
                    'I chose a Medicare Advantage plan, do I need to choose a Prescription Drug Plan?',
                details: `<span>
                        Most Medicare Advantage plans may include prescription drug
                        coverage. If your Medicare Advantage plan does not include
                        this coverage, and you do not have other drug coverage, you
                        may face penalties for not choosing a Prescription Drug
                        Plan. If you are unsure, <div style="cursor:pointer;color:blue;text-decoration:underline" onClick="window.planFaqScheduleCallback()">Click here to schedule a call.</div>
                    </span>`,
            },
            {
                id: 5,
                summary:
                    'I chose a Medicare Supplement plan, do I need to choose a Prescription Drug Plan?',
                details: `<span>
                        Medicare Supplement plans do not include prescription drug
                        coverage. If you do not have other drug coverage, you may
                        face penalties for not choosing a Prescription Drug Plan.
                    </span>`,
            },
            {
                id: 6,
                summary:
                    'What’s the difference between copays and coinsurance?',
                details: `<span>
                        A copay is a fixed cost for a service. $5 for a month’s
                        supply of a drug, or $10 per doctor visit are examples of
                        copays.
                        <br /><br />
                        Coinsurance is a cost sharing with your insurer. This is the
                        percent of the cost you must pay. For example, if you have
                        25% coinsurance for a bill of $200, you owe $50.
                    </span>`,
            },
            {
                id: 7,
                summary: 'What is the donut hole?',
                details: `<span>
                        The donut hole is a period where you may have to pay much
                        more for your drugs than the plan copay or coinsurance
                        amounts. You enter the donut hole after total drug costs
                        exceed $4,020 (in 2020).
                        <br /><br />
                        This is an important topic, and hard to answer in one FAQ.
                        We recommend you take our quick course, which includes
                        detailed Donut Hole examples. <a href="${urlForEnv()}/course/7">Click here to see this course.</a>
                    </span>`,
            },
            {
                id: 8,
                summary: 'How should I read star ratings?',
                details: `<span>
                        Every year, Medicare gives plans one to five stars,
                        evaluating everything from quality of care to customer
                        service. Plans with no star rating are too new to be rated.
                        You can switch to a five star plan at any point in the year,
                        not just during an enrollment period.
                    </span>`,
            },
        ],
    };

    return (
        <Paper className={classes.paperHeader} elevation={0}>
            <div className={classes.header}>
                <Typography classes={{ body1: classes.body1 }}>FAQs</Typography>
            </div>
            {faqContent[props.planType].map(faq => (
                <Accordion className={classes.accordion} key={faq.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        id={`faq-${faq.id}`}
                    >
                        <Typography variant="subtitle2" color="primary">
                            {faq.summary}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="subtitle2">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: faq.details,
                                }}
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Paper>
    );
}

PlanFaq.propTypes = {
    planType: PropTypes.oneOf(Object.values(PlanType)).isRequired,
    // Used in the faq html above
    scheduleCallCallback: PropTypes.func.isRequired,
};
