import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
    makeStyles,
    createTheme,
    ThemeProvider,
} from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { find } from 'lodash';
import {
    Authentication,
    Appointments,
    CensusUpload,
    ChangePassword,
    Contact,
    CoursePage,
    LearningCenter,
    NotFound,
    Profile,
    ReviewInfo,
    Plan,
    Settings,
} from './pages';
import { Nav, ProfileIncomplete, DesktopCTAModal } from './components';
import EMErrorBoundary from './components/EMErrorBoundary';
import { isLoggedIn, setTrackingCookies, urlForEnv } from './utils';
import {
    AppName,
    DrawerItems,
    RoutesWithoutNav,
    ModalType,
    minScreenWidth,
} from './constants';
import { authenticate, getProfile } from './api';
import './App.css';
import { linkWithAccessToken } from './utils';
import { get } from 'lodash';
import Footer from './components/Footer';

const EmployeeCenterTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#092744',
            light: '#D2CEED',
            white: '#ffffff',
        },
        secondary: {
            main: '#5D47BD',
        },
        background: {
            default: '#F7F8FA',
        },
    },
    overrides: {
        MuiDrawer: {
            paperAnchorDockedLeft: {
                borderRight: 'none',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    toolbarLeftNavPanelRendered: {
        visibility: 'hidden',
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.toolbar,
            visibility: 'visible',
        },
    },
    maxWidthContainer: {
        maxWidth: 1200,
    },
    extraBlueBlock: {
        backgroundColor: '#092744',
        width: '100%',
        height: 125,
        zIndex: '-9999',
        position: 'absolute',
    },
    toolbarNotLeftNavPanelRendered: theme.mixins.toolbar,
}));

const AuthenticatedRoute = ({ component: Component, profile, ...rest }) => (
    <Route
        render={props =>
            isLoggedIn() === true ? (
                <>{get(profile, 'email') ? <Component {...props} /> : null}</>
            ) : (
                <Redirect
                    to={`/sign-in${
                        rest.location &&
                        rest.location.pathname &&
                        rest.location.pathname !== '/'
                            ? `?redirectUrl=${rest.location.pathname}`
                            : ''
                    }`}
                />
            )
        }
        {...rest}
    />
);

const App = () => {
    const shouldRenderDesktopCTAModal =
        window.innerWidth < minScreenWidth ? true : false;
    const classes = useStyles();
    const [loggedIn, setLoggedIn] = useState(isLoggedIn());
    const [shouldRenderModal, setShouldRenderModal] = useState(false);
    const [profile, setProfile] = useState({});
    const [isBroker, setIsBroker] = useState(false);
    const [modalType, setModalType] = '';
    const [customer, setCustomer] = useState(null);
    const initialize = async () => {
        setTrackingCookies();
        if (Cookies.get('accessToken')) {
            const { result } = await getProfile();
            let isComplete = false;
            let notInterstedInComparingMedicarePlans = false;
            result.sections.forEach(section => {
                if (section.isComplete) {
                    isComplete = true;
                }
                section.data.forEach(datum => {
                    if (
                        datum.decisionType === 'WANTS_TO_COMPARE_PLANS' &&
                        datum.value === 'No'
                    ) {
                        notInterstedInComparingMedicarePlans = true;
                    }
                });
            });
            // if (isComplete || notInterstedInComparingMedicarePlans) {
            setProfile(result);
            setCustomer(result);
            // } else {
            //     const section = result.sections[0];
            //     window.location.replace(
            //         linkWithAccessToken(
            //             `${section.conversationLink}?conversationId=em2022q1`
            //         )
            //     );
            // }
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (!isBroker && profile.isBroker) {
            setIsBroker(true);
        }
    }, [profile, isBroker]);

    const logout = () => {
        setLoggedIn(false);
        window.location.href = urlForEnv() + 'sign-in';
    };

    const signup = () => {
        setLoggedIn(false);
        const route = 'sign-up' + window.location.search;
        window.location.href = urlForEnv() + route;
    };

    const login = () => setLoggedIn(true);

    const showModal = modalType => {
        setModalType(modalType);
        setShouldRenderModal(true);
    };

    const hideModal = () => setShouldRenderModal(false);

    const shouldRenderLeftNavPanel = () => {
        if (window.location.pathname.includes('/course/')) {
            return false;
        }
        return !find(RoutesWithoutNav, x => x === window.location.pathname);
    };

    const getSignUp = () => {
        if (loggedIn) {
            return <Redirect to="/learning-center" />;
        } else {
            return (
                <Authentication
                    authenticatedCallback={login}
                    isSignUp={true}
                    redirectToOnSuccess={'/'}
                    authenticate={authenticate}
                    showModal={showModal}
                />
            );
        }
    };

    const maybeRenderDesktopCTAModal = () => {
        return shouldRenderDesktopCTAModal ? (
            <DesktopCTAModal show={shouldRenderDesktopCTAModal} />
        ) : null;
    };

    return (
        <div className="App" style={{ height: '100%' }}>
            <EMErrorBoundary sentry={Sentry}>
                <Nav
                    isAuthorized={loggedIn}
                    appName={AppName}
                    logoutCallback={logout}
                    signupCallback={signup}
                    drawerItems={DrawerItems}
                    shouldRenderLeftNavPanel={shouldRenderLeftNavPanel}
                    isBroker={isBroker}
                    profile={profile}
                />
                <div className={classes.extraBlueBlock} />
                {maybeRenderDesktopCTAModal()}
                <div className={classes.maxWidthContainer} />
                {shouldRenderModal &&
                    modalType === ModalType.PROFILE_INCOMPLETE && (
                        <ProfileIncomplete
                            hideModalCallback={hideModal}
                            show={shouldRenderModal}
                        />
                    )}
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => (
                            <LearningCenter
                                isAuthorized={loggedIn}
                                profile={profile}
                                customer={customer}
                            />
                        )}
                    />
                    <Route exact path="/learning-center">
                        <Redirect to="/" />
                    </Route>
                    {!isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/profile"
                            component={Profile}
                            profile={profile}
                        />
                    )}
                    {isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/appointments"
                            component={Appointments}
                            profile={profile}
                        />
                    )}
                    <Route exact path="/sign-up" component={getSignUp} />
                    <Route
                        exact
                        path="/sign-in"
                        render={() =>
                            loggedIn ? (
                                <Redirect to="/" />
                            ) : (
                                <Authentication
                                    authenticatedCallback={login}
                                    isSignUp={false}
                                    redirectToOnSuccess={'/'}
                                    authenticate={authenticate}
                                />
                            )
                        }
                    />
                    {!isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/quote"
                            component={Plan}
                            profile={profile}
                        />
                    )}

                    <Route
                        exact
                        path="/course/:id"
                        render={() => (
                            <CoursePage
                                profile={profile}
                                profileComplete={
                                    customer ? customer.isComplete : null
                                }
                                isAuthorized={loggedIn}
                            />
                        )}
                    />
                    {!isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/settings"
                            render={() => <Settings profile={profile} />}
                            profile={profile}
                        />
                    )}
                    {!isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/contact"
                            render={() => <Contact profile={profile} />}
                            profile={profile}
                        />
                    )}
                    {!isBroker && (
                        <AuthenticatedRoute
                            exact
                            path="/review-your-info"
                            render={() => {
                                return <ReviewInfo profile={profile} />;
                            }}
                            profile={profile}
                        />
                    )}
                    <Route
                        exact
                        path="/forgot-password"
                        render={() => <ChangePassword type="FORGOT" />}
                    />
                    <Route
                        exact
                        path="/user/password-reset"
                        render={() => <ChangePassword type="RESET" />}
                    />
                    <Route
                        exact
                        path="/user/account-unlock"
                        render={() => <ChangePassword type="UNLOCK" />}
                    />
                    <Route
                        exact
                        path="/census-upload"
                        render={() => <CensusUpload />}
                    />
                    <Route component={NotFound} />
                </Switch>
            </EMErrorBoundary>
        </div>
    );
};
class AppWrapper extends React.Component {
    render() {
        return (
            <ThemeProvider theme={EmployeeCenterTheme}>
                <Router>
                    <App />
                    <Footer />
                </Router>
            </ThemeProvider>
        );
    }
}

export default AppWrapper;
