import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import {
    Avatar,
    CssBaseline,
    Typography,
    Container,
    withStyles,
} from '@material-ui/core';
import { LockOutlined as EMLockOutlinedIcon } from '@material-ui/icons';
import EMButton from '../components/EmButton';
import EMTextField from '../components/EMTextfield';
import EMSnackbar from '../components/EMSnackbar';

import { forgotPassword, resetPassword, unlockAccount } from '../api';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgotText: {
        fontSize: '20px',
    },
});

class ChangePassword extends Component {
    state = {
        fieldVal: '',
        showError: false,
        error: '',
        secret: '',
        isSubmitted: false,
    };

    componentDidMount() {
        const { secret } = queryString.parse(this.props.location.search);
        this.setState({ secret });
    }

    handleOnSubmit = async e => {
        this.setState({ showError: false });
        e.preventDefault();
        const { fieldVal, secret } = this.state;
        const { type } = this.props;
        let result;
        if (type === 'FORGOT') {
            result = await forgotPassword(fieldVal);
            window.FS.event('Forgot Password Submitted', {
                email: fieldVal,
            });
        } else if (type === 'RESET') {
            result = await resetPassword({
                password: fieldVal,
                secret: secret,
            });
        } else if (type === 'UNLOCK') {
            result = await unlockAccount(secret);
        }

        if (result.error) {
            this.setState({ showError: true, error: result.error });
        } else {
            this.setState({ isSubmitted: true });
            if (type === 'RESET' || type === 'UNLOCK') {
                this.props.history.push('/sign-in');
            }
        }
    };

    handleFieldValChange = e => {
        this.setState({ fieldVal: e.target.value });
    };

    maybeRenderError = () => {
        return this.state.showError ? (
            <EMSnackbar variant={'error'} message={this.state.error} />
        ) : null;
    };

    maybeRenderForm = () => {
        const { classes, type } = this.props;
        const cta = type === 'FORGOT' ? 'Submit' : 'Reset Password';

        return !this.state.isSubmitted ? (
            <form
                className={classes.form}
                noValidate
                onSubmit={this.handleOnSubmit}
            >
                <p className={classes.forgotText}>
                    {type === 'FORGOT'
                        ? `Please enter your email and we will email you a link to
                    reset your password.`
                        : `Enter your new password and click the Reset Password button`}
                </p>
                <EMTextField
                    margin="normal"
                    required
                    fullWidth
                    id={type === 'FORGOT' ? 'email' : 'password'}
                    label={type === 'FORGOT' ? 'Email Address' : 'New Password'}
                    name={type === 'FORGOT' ? 'email' : 'password'}
                    autoComplete={type === 'FORGOT' ? 'email' : undefined}
                    type={
                        type === 'RESET' || type === 'UNLOCK'
                            ? 'password'
                            : undefined
                    }
                    autoFocus
                    onChange={this.handleFieldValChange}
                />
                <EMButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    text={cta}
                />
            </form>
        ) : null;
    };

    maybeRenderSubmittedText = () => {
        const { type } = this.props;
        if (this.state.isSubmitted) {
            if (type === 'FORGOT') {
                return (
                    <Typography component="subtitle1">
                        We sent a password reset link to your email
                    </Typography>
                );
            } else {
                return (
                    <Typography component="subtitle1">
                        Password reset successful!
                    </Typography>
                );
            }
        } else {
            return null;
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <EMLockOutlinedIcon />
                    </Avatar>
                    {this.maybeRenderSubmittedText()}
                    {this.maybeRenderForm()}
                </div>
                {this.maybeRenderError()}
            </Container>
        );
    }
}

ChangePassword.propTypes = {
    type: PropTypes.oneOf(['FORGOT', 'RESET']).isRequired,
};

export default withStyles(styles)(withRouter(ChangePassword));
