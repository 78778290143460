import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import EMModal from './Modal';

const useStyles = makeStyles(theme => ({
    modalContent: {
        paddingTop: theme.spacing(12),
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(24),
        fontFamily: theme.typography.h4.fontFamily,
        fontSize: 54,
        fontWeight: 500,
    },
    divider: {
        marginTop: theme.spacing(4.5),
        color: 'yellow',
        background: '#f9d76d',
        width: '100%',
        marginBottom: theme.spacing(4.5),
    },
}));

function DesktopCTAModal(props) {
    const { show } = props;

    const classes = useStyles();

    const modalContent = (
        <div className={classes.modalContent}>
            <div>
                <Typography variant="h1">
                    Please visit this site on a desktop or laptop computer.
                </Typography>
                <Divider light classes={{ root: classes.divider }} />
            </div>
        </div>
    );

    return show ? (
        <EMModal
            title={''}
            isOpen={show}
            content={modalContent}
            size="lg"
            handleClose={() => {}}
        />
    ) : null;
}

export default withRouter(DesktopCTAModal);
