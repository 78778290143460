import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CheckCircle, CheckCircleOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    validText: {
        color: green[500],
    },
    invalidText: {
        color: '#1F4160',
    },
    validatorLines: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));
const passwordContainsEightCharRegex = new RegExp('(?=.{8,})');
const passwordContainsLowercaseRegex = new RegExp('(?=.*[a-z])');
const passwordContainsUppercaseRegex = new RegExp('(?=.*[A-Z])');
const passwordContainsNumbersRegex = new RegExp('(?=.*[0-9])');

export default function EMPasswordValidator(props) {
    const classes = useStyles();

    props.isValid(
        passwordContainsEightCharRegex.test(props.password) &&
            passwordContainsNumbersRegex.test(props.password) &&
            passwordContainsLowercaseRegex.test(props.password) &&
            passwordContainsUppercaseRegex.test(props.password)
    );

    const renderPasswordChecklistItem = ({ valid, text }) => {
        return (
            <ListItem disableGutters className={classes.validatorLines}>
                <ListItemText
                    className={valid ? classes.validText : classes.invalidText}
                    primary={text}
                />
                <ListItemIcon style={{ minWidth: 0 }}>
                    {valid ? (
                        <CheckCircle className={classes.validText} />
                    ) : (
                        <CheckCircleOutlined className={classes.invalidText} />
                    )}
                </ListItemIcon>
            </ListItem>
        );
    };

    return (
        <List dense>
            {renderPasswordChecklistItem({
                valid: passwordContainsEightCharRegex.test(props.password),
                text: 'At least 8 characters',
            })}
            {renderPasswordChecklistItem({
                valid: passwordContainsNumbersRegex.test(props.password),
                text: 'Contains a number',
            })}
            {renderPasswordChecklistItem({
                valid:
                    passwordContainsLowercaseRegex.test(props.password) &&
                    passwordContainsUppercaseRegex.test(props.password),
                text: 'Uses upper and lowercase letters',
            })}
        </List>
    );
}

EMPasswordValidator.defaultProps = {
    password: '',
};

EMPasswordValidator.propTypes = {
    isValid: PropTypes.func.isRequired,
    password: PropTypes.string,
};
