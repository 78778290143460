import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    titleContainer: {
        width: 500,
        margin: '0 auto',
        marginTop: 100,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },

    title: {
        fontWeight: 600,
        fontFamily: 'Halant, serif',
        fontSize: 32,
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
    slideContainer: {
        background: 'linear-gradient(180deg, #5143BE 0%, #221B63 100%)',
        textAlign: 'center',
        width: '100%',
        borderRadius: '8px',
    },
}));

function Slide({ image, text }) {
    const classes = useStyles();

    return (
        <div className={classes.slideContainer}>
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={classes.titleContainer}>
                    <p className={classes.title}>{text}</p>
                </div>
                <img alt={`placerholder for: ${text}`} src={image}></img>
            </div>
        </div>
    );
}

export default withRouter(Slide);
