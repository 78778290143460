export const AppName = 'Entermedicare';
export const DrawerMarginLeft = 240;
export const DrawerMarginItem = 220;
export const NavBarHeight = 64;
export const EMYellow = '#ffd757';
export const EMPurple = '#342996';
export const EMRed = '#D22584';
export const EMCtaGold = '#F9D787';
export const fadedBlue = '#1F4160';
export const navbarBlue = '#37536C';

export const ModalType = { PROFILE_INCOMPLETE: 'PROFILE_INCOMPLETE' };
Object.freeze(ModalType);

export const PlanType = { MA: 'MA', PDP: 'PDP', SUPP: 'SUPP' };
Object.freeze(PlanType);

export const AuthenticationModalType = {
    PAY_WALL: 'PAY_WALL',
    EMAIL_TAKEN: 'EMAIL_TAKEN',
};
Object.freeze(AuthenticationModalType);

export const minScreenWidth = 300;
export const DrawerItems = [
    {
        id: 'learning-center',
        displayName: 'Learn',
        url: '/',
        isExternal: false,
        dataStep: 'step-2',
        dataStepExplanation:
            'This is where you can get caught up to speed and learn more about medicare programs. This is open to you at any time whether you are loggedin or not.',
    },
    {
        id: 'your-profile',
        displayName: 'Profile',
        url: '/profile',
        isExternal: false,
        dataStep: 'step-3',
        dataStepExplanation:
            'This is where all your answers from questions get stored. Entermedicare professionals will be able to view these answers when you book an appointment with us.',
        forBrokers: false,
    },
    {
        id: 'view-plans',
        displayName: 'Plans',
        url: '/review-your-info',
        isExternal: false,
        dataStep: 'step-4',
        dataStepExplanation:
            'This is where you can view plans in your area. While all offerings are valid for your zipcode, we cannot display plan names due to CMS regulations and you will need to talk to a medicare professional to learn more about them',
        forBrokers: false,
    },
    {
        id: 'contact',
        displayName: 'Contact',
        url: '/contact',
        isExternal: false,
        dataStep: 'step-5',
        dataStepExplanation:
            'If you still would like to speak to us, please fill out the contact form or use the chat box popup in the bottom right corner',
        forBrokers: false,
    },
    {
        id: 'appointments',
        displayName: 'Appointments',
        url: '/appointments',
        isExternal: false,
        dataStep: 'step-6',
        dataStepExplanation:
            'View user information who have scheduled an appointment',
        forBrokers: true,
    },
];

export const RoutesWithoutNav = [
    '/sign-in',
    '/sign-up',
    '/404',
    '/forgot-password',
    '/user/password-reset',
    '/user/account-unlock',
];

export const NavButtons = [
    {
        displayName: 'Sign up',
        url: '/sign-up',
        isSignUp: true,
        isAuthorized: false,
    },
    {
        displayName: 'Sign in',
        url: '/sign-in',
        isAuthorized: false,
    },
];

export const emPurple = '#673ab7';

export const loadingMessages = [
    'Comparing plans...',
    'Calculating potential savings...',
    'Comparing potential savings...',
    'Digging through .gov (this will take a sec)...',
    'Working on saving you $...',
    'Solving healthcare as we speak...',
    'Fixing all of healthcare...',
    'Automating healthcare as we speak...',
    'Calculating how much a woodchuck actually could chuck…',
];

export const TPMODisclaimer =
    'We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Please contact Medicare.gov or 1–800–MEDICARE to get information on all of your options.';

// paste a response from getQuoteById here during local development.
// export const plansResponse = {
