import React, { useEffect, useState } from 'react';
import { Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { getAppointments } from '../api';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
    gridParent: {
        width: '1200px',
        margin: '0 auto',
    },
    header: {
        lineHeight: 0.5,
        borderBottom: '2px solid #EFF2F5',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        whiteSpace: 'pre-wrap',
        color: '#4d5055',
    },
    pageContainer: {
        background: theme.palette.primary.white,
        border: '1px solid #E1E7ED',
        boxShadow: '0 1px 8px 0 rgb(0 0 0 / 8%)',
        borderRadius: 8,
        marginTop: 66,
        padding: 0,
        // paddingBottom: theme.spacing(4),
        margin: '0 auto',
    },
    subheader: {
        padding: theme.spacing(4),
        paddingBottom: 0,
        color: '#4d5055',
    },
}));

const Appointments = () => {
    const classes = useStyles();
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const appointmentsQuery = await getAppointments();
            setAppointments(appointmentsQuery.result);
        };
        initialize();
    }, []);

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridParent}>
                <Grid item sm={12} style={{ minHeight: 1000 }}>
                    <div className={classes.pageContainer}>
                        <div className={classes.subheader}>
                            <Typography variant="subtitle2">
                                You can find the details on users who have
                                signed up for an appointment with you below:
                            </Typography>
                        </div>

                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">
                                            Date Created
                                        </TableCell>
                                        <TableCell align="right">
                                            Email
                                        </TableCell>
                                        <TableCell align="right">
                                            Mobile
                                        </TableCell>
                                        <TableCell align="right">
                                            Home
                                        </TableCell>
                                        <TableCell align="right">
                                            Work
                                        </TableCell>
                                        <TableCell align="right">
                                            File
                                        </TableCell>
                                        <TableCell align="right">
                                            Requested Access
                                        </TableCell>
                                        <TableCell align="right">
                                            Do Not Share
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {appointments.map(appointment => (
                                        <TableRow key={appointment.id}>
                                            <TableCell align="right">
                                                {moment(
                                                    appointment.createdAt
                                                ).format('MM/DD/YYYY')}
                                            </TableCell>
                                            <TableCell align="right">
                                                {appointment.user.email}
                                            </TableCell>
                                            <TableCell align="right">
                                                {appointment.user.mobilePhone}
                                            </TableCell>
                                            <TableCell align="right">
                                                {appointment.user.homePhone}
                                            </TableCell>
                                            <TableCell align="right">
                                                {appointment.user.workPhone}
                                            </TableCell>
                                            <TableCell align="right">
                                                (
                                                <Link
                                                    href={appointment.file}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    View File{' '}
                                                </Link>
                                                )
                                            </TableCell>
                                            <TableCell align="right">
                                                {get(
                                                    appointment,
                                                    'user.requestedAccessToInfo'
                                                ) ? (
                                                    <CheckCircle
                                                        className={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                ) : (
                                                    <RadioButtonUnchecked
                                                        classname={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {get(
                                                    appointment,
                                                    'user.requestedDataNotBeShared'
                                                ) ? (
                                                    <CheckCircle
                                                        className={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                ) : (
                                                    <RadioButtonUnchecked
                                                        classname={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Appointments;
