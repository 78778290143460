import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BirdHouse from '../assets/Birdhouse.png';

const useStyles = makeStyles(theme => ({
    cta: {
        marginBottom: theme.spacing(2),
        width: '150px',
        boxShadow: '0px 10px 20px rgba(52, 41, 150, 0.4)',
    },
    dialog: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '100vh',
            height: '100vh',
        },
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '440px',
        maxWidth: '324px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
            height: '400px',
        },
    },
    dialogText: {
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    img: {
        width: '123px',
        height: '173px',
    },
}));

export default function HelpDialog(props) {
    const classes = useStyles();
    const { header, message } = props;
    return (
        <Dialog className={classes.dialog} onClose={props.onClose} open>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.img}>
                    <img
                        className={classes.img}
                        src={BirdHouse}
                        alt="ProducerAscent Birdhouse"
                    />
                </div>
                <DialogTitle className={classes.dialogText}>
                    {header}
                </DialogTitle>
                <Typography
                    className={classes.dialogText}
                    variant={'subtitle1'}
                >
                    {message}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

HelpDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};
