import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import {
    AppBar,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Drawer from './Drawer';
import CarouselNav from './CarouselNav';
import { isLoggedIn, logout } from '../utils';
import EmLogoWhite from '../assets/EmLogoWhite.png';
import PersonIcon from '@material-ui/icons/Person';

const styles = theme => ({
    backImg: {
        width: '24px',
        height: '24px',
    },
    backLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 40,
        width: '200px',
        left: 10,
        marginLeft: '20%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuSettingsButton: {
        marginRight: 16,
        float: 'right',
        fontFamily: 'Source Sans Pro, sans-serif',
        textTransform: 'none',
        fontSize: 20,
        color: theme.palette.primary.white,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logo: {
        width: '125px',
        height: '24px',
        marginLeft: '10px',
        display: 'visible',
    },
    appbar: {
        height: '100px',
    },
    logout: {
        textAlign: 'right !important',
        textTransform: 'none !important',
        color: '#fff',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        textAlign: 'right !important',
        textTransform: 'none !important',
        color: '#fff',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    navToolBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: 100,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1200,
            margin: '0 auto',
        },
    },
    gridContainer: {
        alignItems: 'center',
    },
});

class EmNav extends Component {
    state = {
        showDrawer: false,
        showDropdown: false,
        menuAnchorEl: null,
        anchorEl: null,
    };

    handleDrawerClick = () => {
        this.setState({ showDrawer: true });
    };

    handleDrawerClose = () => {
        this.setState({ showDrawer: false });
    };

    handleLogoutClick = () => {
        logout();
        this.props.logoutCallback();
    };

    handleSignUpClick = () => {
        logout(true);
        this.props.signupCallback();
    };

    handleSettingsClick = () => {
        this.props.history.push('/settings');
        this.handleMenuClose();
    };

    leftShowLogo = () => {
        if (
            window.location.pathname.includes('/sign-up') ||
            window.location.pathname.includes('/sign-in')
        )
            return null;
        else
            return (
                <>
                    <Link to="/" style={{ textAlign: 'left', paddingLeft: 16 }}>
                        <img
                            src={EmLogoWhite}
                            alt={'Entermedicare logo'}
                            width={180}
                        />
                    </Link>
                </>
            );
    };

    showLogoOnlyOnAuth = () => {
        if (
            window.location.pathname.includes('/sign-up') ||
            window.location.pathname.includes('/sign-in')
        ) {
            return (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={EmLogoWhite}
                            alt={'Entermedicare logo'}
                            width={180}
                        />
                    </div>
                </>
            );
        } else {
            return null;
        }
    };

    showSignUpAndIn = ({ isAuthorized }) => {
        const { classes } = this.props;
        if (
            this.props.location.pathname !== '/sign-in' &&
            this.props.location.pathname !== '/sign-up'
        )
            return (
                <>
                    <Button
                        className={classes.menuButton}
                        variant="text"
                        onClick={() => this.handleDrawerClick()}
                    >
                        Menu
                    </Button>
                    {this.state.showDrawer && (
                        <Drawer
                            drawerItems={this.setDrawerItemActiveStatus([])}
                            handleClose={this.handleDrawerClose}
                            isAuthorized={isAuthorized}
                            open={this.state.showDrawer}
                            signUp={this.handleSignUpClick}
                            signIn={this.handleLogoutClick}
                        />
                    )}
                    <Button
                        className={`${classes.logout} fs-unmask`}
                        variant="text"
                        onClick={() => this.handleSignUpClick()}
                        data-e2e={'signUpButtonNav'}
                    >
                        Sign Up
                    </Button>
                    <Button
                        className={`${classes.logout} fs-unmask`}
                        variant="text"
                        onClick={() => this.handleLogoutClick()}
                        data-e2e={'signInButtonNav'}
                    >
                        Sign In
                    </Button>
                </>
            );
    };

    setDrawerItemActiveStatus = drawerItems => {
        let path = get(this.props, 'history.location.pathname', '');
        return drawerItems.map(item =>
            path === item.url
                ? { ...item, isActive: true }
                : { ...item, isActive: false }
        );
    };

    handleMenuClick = event => {
        this.setState({ menuAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    };

    render() {
        const {
            classes,
            isAuthorized,
            drawerItems,
            logoutCallback,
            shouldRenderLeftNavPanel,
        } = this.props;
        let updatedDrawerItems = drawerItems;
        if (this.props.isBroker) {
            updatedDrawerItems = drawerItems.filter(
                item =>
                    item.forBrokers === true || item.forBrokers === undefined
            );
        } else {
            updatedDrawerItems = drawerItems.filter(
                item => item.forBrokers !== true
            );
        }
        return (
            <AppBar position="sticky" color="primary" elevation={0}>
                <Toolbar disableGutters className={classes.navToolBar}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={3}>
                            {this.leftShowLogo()}
                        </Grid>
                        <Grid item xs={6}>
                            {shouldRenderLeftNavPanel() && isLoggedIn() ? (
                                <CarouselNav
                                    items={updatedDrawerItems}
                                    profile={this.props.profile}
                                    style={{ margin: '0 auto' }}
                                />
                            ) : (
                                <>{this.showLogoOnlyOnAuth()}</>
                            )}
                            {this.state.showDrawer && isLoggedIn() && (
                                <Drawer
                                    drawerItems={this.setDrawerItemActiveStatus(
                                        updatedDrawerItems
                                    )}
                                    handleClose={this.handleDrawerClose}
                                    isAuthorized={isAuthorized}
                                    logoutCallback={logoutCallback}
                                    open={this.state.showDrawer}
                                    signUp={this.handleSignUpClick}
                                    signIn={this.handleLogoutClick}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                            {isLoggedIn() && shouldRenderLeftNavPanel() && (
                                <>
                                    {/* <Button
                                        className={`${classes.logout} fs-unmask`}
                                        variant="text"
                                        onClick={() => this.handleLogoutClick()}
                                    >
                                        Logout
                                    </Button>
                                    <Button
                                        className={`${classes.logout} fs-unmask`}
                                        variant="text"
                                        onClick={() => this.handleSettingsClick()}
                                    >
                                        Settings
                                    </Button> */}
                                    <IconButton
                                        className={`${classes.menuSettingsButton} fs-unmask`}
                                        aria-controls="user menu"
                                        aria-haspopup="true"
                                        onClick={this.handleMenuClick}
                                    >
                                        <PersonIcon style={{ fontSize: 40 }} />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.menuAnchorEl}
                                        keepMounted
                                        open={Boolean(this.state.menuAnchorEl)}
                                        onClose={this.handleMenuClose}
                                    >
                                        <MenuItem
                                            onClick={this.handleSettingsClick}
                                        >
                                            Settings
                                        </MenuItem>
                                        <MenuItem
                                            onClick={this.handleLogoutClick}
                                        >
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                    <Button
                                        className={classes.menuButton}
                                        variant="text"
                                        onClick={() => this.handleDrawerClick()}
                                    >
                                        Menu
                                    </Button>
                                </>
                            )}
                            {!isLoggedIn() &&
                                this.showSignUpAndIn({
                                    isAuthorized,
                                    drawerItems,
                                })}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles)(withRouter(EmNav));

EmNav.propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    appName: PropTypes.string.isRequired,
    drawerItems: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            icon: PropTypes.any,
        })
    ).isRequired,
    logoutCallback: PropTypes.func.isRequired,
    shouldRenderLeftNavPanel: PropTypes.func.isRequired,
};
