import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
    Typography,
    Paper,
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    DialogContent,
} from '@material-ui/core';
import EMFlatButton from '../components/EMFlatButton';
import { get, uniq } from 'lodash';
import { coursesStarted } from '../api';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'inset 0px 1px 0px #E4E9EF',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    headerTitle: {
        fontSize: 24,
        fontWeight: 700,
    },
    grayed: {
        opacity: 0.4,
    },
    cardBody: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    buttonStyle: {
        margin: 4,
        width: 200,
        height: 54,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    imgDimensions: {
        width: 208,
        height: 164,
        objectFit: 'contain',
        backgroundColor: '#F3F1FA',
        borderRadius: '8px',
    },
}));

function CourseCard(props) {
    const classes = useStyles();
    const { course, history, urlParams } = props;
    const [showModal, setShowModal] = useState(false);

    const shouldGray = () => {
        // return course.progress === 0 ? true : false;
        return false;
    };

    const shouldUseReviewButton = () => {
        return course.progress === 1;
    };

    const handleNavigation = async () => {
        window.hj('event', `Course ${course.id} clicked`);
        if (!get(props, 'isAuthorized', false)) {
            let visitedCourses =
                JSON.parse(window.localStorage.getItem('courseTracker')) || [];
            visitedCourses.push(course.id);
            visitedCourses = uniq(visitedCourses);
            window.localStorage.setItem(
                'courseTracker',
                JSON.stringify(visitedCourses)
            );
            if (visitedCourses.length % 3 === 0) {
                setShowModal(true);
            } else {
                history.push(`/course/${course.id}${urlParams}`);
            }
        } else {
            window.FS.event('Course Started', {
                course_id: course.id,
            });
            await coursesStarted({ course, url: window.location.href });
            history.push(`/course/${course.id}${urlParams}`);
        }
    };

    return (
        <Paper
            className={classes.root}
            onClick={handleNavigation}
            data-e2e={'course'}
        >
            <div className={classes.cardBody}>
                <img
                    alt={`EnterMedicare - ${course.title}`}
                    src={`/courses/${course.imageUrl}`}
                    className={`${classes.imgDimensions}`}
                />
                <div className={classes.cardContent}>
                    <Typography
                        gutterBottom
                        variant="subtitle2"
                        className={`${classes.headerTitle} ${
                            shouldGray() ? classes.grayed : ''
                        }`}
                    >
                        {course.title}
                    </Typography>
                    {course.description && (
                        <Typography
                            variant="subtitle2"
                            className={shouldGray() ? classes.grayed : ''}
                        >
                            {course.description}
                        </Typography>
                    )}
                    <EMFlatButton
                        className={classes.buttonStyle}
                        // onClick={handleNavigation}
                        text={course.ctaText}
                        data-e2e={'courseButton'}
                        variant={
                            shouldGray() || shouldUseReviewButton()
                                ? 'contained'
                                : 'outlined'
                        }
                        color={shouldUseReviewButton() ? 'inherit' : 'primary'}
                    />
                </div>
                <Dialog open={showModal} onClose={() => setShowModal(false)}>
                    <DialogTitle>Lets make an account</DialogTitle>
                    <DialogContent>
                        {`Now that you've seen some courses, feel free to either continue learning or make an account!
                        Your experience is better after an account is created and you'll be able to talk and schedule an appointment with
                        a Medicare expert.`}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowModal(false);
                                window.FS.event('Course Started', {
                                    course_id: course.id,
                                });
                                history.push(
                                    `/course/${course.id}${urlParams}`
                                );
                            }}
                        >
                            {' '}
                            Keep Learning{' '}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                window.FS.event('Modal to Signup');
                                history.push(`/sign-up${urlParams}`);
                            }}
                        >
                            Sign me up!
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Paper>
    );
}

export default withRouter(CourseCard);

CourseCard.propTypes = {
    course: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        statusText: PropTypes.string,
        ctaText: PropTypes.string,
        progress: PropTypes.number,
    }).isRequired,
};
