import { createMuiTheme } from '@material-ui/core/styles';

export const entermedicareTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#342996',
            light: '#D2CEED',
            white: '#ffffff',
        },
        secondary: {
            main: '#D22584',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1920,
        },
    },
    typography: {
        h1: {
            fontSize: 48,
            fontFamily: 'Halant, serif',
        },
        h2: {
            fontSize: 36,
            fontFamily: 'Halant, serif',
        },
        h3: {
            fontSize: 28,
            fontFamily: 'Halant, serif',
        },
        h4: {
            fontSize: 20,
            fontFamily: 'Halant, serif',
        },
        subtitle1: {
            fontSize: 24,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        subtitle2: {
            fontSize: 20,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        body1: {
            fontSize: 16,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
        body2: {
            fontSize: 12,
            fontFamily: 'Source Sans Pro, sans-serif',
        },
    },
    overrides: {
        MuiTableCell: {
            root: {
                verticalAlign: 'text-top',
            },
        },
    },
});

export const EMColor = {
    scoreCircle: 'rgba(186, 216, 194)',
    potentialCircle: 'rgba(63, 106, 96)',
    scoreFont: 'rgba(63, 106, 96)',
    scoreCardBackground: 'rgb(242,249,245)',
};

export const drawerMarginLeft = 202;
export const MIN_SEARCH_CHARS = 3;
export const EMFadedBlue = '#1F4160';
export const EMYellow = '#ffd757';
