import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { toCurrency } from '../../utils';
import {
    Favorite as EMFavoriteIcon,
    FavoriteBorder as EMFavoriteBorderIcon,
} from '@material-ui/icons';
import EMButton from '../EmButton';
import { startCase, toLower, get } from 'lodash';
import { determineCoverageColor } from './planHelpers';

const useStyles = makeStyles(theme => ({
    planContainer: {
        width: '27.8%',
        display: 'flex',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '85%',
            margin: '0 auto',
        },
        border: '1px solid #E1E7ED',
    },
    planContainerStandlaone: {
        width: 'auto',
        display: 'flex',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            // width: '85%',
            margin: '0 auto',
        },
    },
    importantText: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#092744',
    },
    leftColumn: {
        color: '#092744',
        paddingBottom: theme.spacing(1.5),
        textAlign: 'left',
        verticalAlign: 'top',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 20,
        textOverflow: 'ellipsis',
    },
    rightColumn: {
        color: '#092744',
        paddingBottom: theme.spacing(1.5),
        textAlign: 'right',
        verticalAlign: 'top',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 20,
        textOverflow: 'scroll',
    },
    drugCost: {
        display: 'flex',
        flexDirection: 'column',
    },
    planName: {
        minHeight: '2.5em',
        maxHeight: '3em',
        marginTop: '32px',
    },
    bottomButtons: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        '& button': {
            margin: 0,
            marginBottom: '32px',
            width: '100%',
            minWidth: 'inherit',
        },
    },
    suppBottomButtons: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        '& button': {
            margin: 0,
            marginBottom: '32px',
            width: '100%',
            minWidth: 'inherit',
        },
    },
    learnMoreButton: {
        backgroundColor: '#342996',
    },
    heartButton: {
        height: '40px',
        backgroundColor: '#e1e7ed',
        fill: '#37536C',
    },
    heartIcon: {
        fill: '#37536C',
    },
    faqShownWidth: {
        [theme.breakpoints.up('md')]: {
            width: '41.7%',
        },
    },
}));

const maybeRenderStarRating = ({ starRating, planType }) => {
    if (planType === 'SUPP') {
        return;
    }
    if (starRating) {
        return (
            <Rating
                name="starRating"
                value={starRating}
                precision={0.5}
                readOnly={true}
            />
        );
    } else {
        return (
            <Typography variant="subtitle1" style={{ color: '#B4B4B4' }}>
                No star rating available
            </Typography>
        );
    }
};

export default function PlanSelection({
    plan,
    appointmentScheduled,
    planType,
    planSelectedCallback,
    currentMonthlyPremium,
    planSavedCallback,
    standalone,
    partBPrice,
}) {
    const classes = useStyles();
    let {
        companyName = '',
        costShare,
        doctors,
        drugCoverage,
        drugDeductible,
        drugs,
        healthDeductible,
        planName,
        emName,
        referrals,
        starRating,
        premium,
        saved,
        doctorNetwork,
        partADeductible,
        partACoinsurance,
        partBDeductible,
        partBCoinsurance,
        partBExcess,
        foreignBenefit,
        skilledNursing,
        facilityCoinsurance,
        bloodBenefit,
    } = plan;

    const cardKeys = [
        { id: drugCoverage, display: 'Drug coverage' },
        { id: doctorNetwork, display: 'Doctor network' },
        { id: referrals, display: 'Referrals?' },
        { id: partADeductible, display: 'Part A Deductible' },
        { id: partACoinsurance, display: 'Part A Coinsurance' },
        { id: partBDeductible, display: 'Part B Deductible' },
        { id: partBCoinsurance, display: 'Part B Coinsurance' },
        { id: partBExcess, display: 'Part B excess charges' },
        { id: foreignBenefit, display: 'Foreign Travel Benefit' },
        { id: skilledNursing, display: 'Skilled Nursing' },
        { id: facilityCoinsurance, display: 'Facility Coinsurance' },
        { id: bloodBenefit, display: 'Benefit for blood' },
    ];

    if (!healthDeductible || healthDeductible === '0') healthDeductible = 0;
    if (!drugDeductible || drugDeductible === '0') drugDeductible = 0;

    const displayDrugCost = drug => {
        return (
            <div className={classes.drugCost}>
                {drug.details.coverageValue} {drug.details.coverageDescriptor}
            </div>
        );
    };

    const getFavIcon = () => {
        return saved ? (
            <EMFavoriteIcon
                className={classes.heartIcon}
                data-e2e="favoriteButton"
            />
        ) : (
            <EMFavoriteBorderIcon
                className={classes.heartIcon}
                data-e2e="favoriteButton"
            />
        );
    };

    const handleOutlierPlanType = planType => {
        switch (planType) {
            case 'MA_CORE':
                return 'Core';
            case 'MA_SUPP1':
                return 'Supplement 1';
            case 'MA_SUPP1A':
                return 'Supplement 1A';
            default:
                return planType;
        }
    };

    const tableStyling = planType => {
        if (!planType) return {};
        if (planType === 'PDP') {
            return {
                height: 180,
                marginTop: 10,
            };
        } else if (planType === 'SUPP') {
            return {
                marginTop: 10,
            };
        } else {
            if (standalone) {
                return {
                    height: 200,
                    marginTop: 10,
                };
            }
            return {
                height: 400,
                marginTop: 10,
            };
        }
    };

    const determinePlanName = ({ planType, plan }) => {
        if (!planType) return '';
        else if (planType === 'PDP') return plan.emName;
        else if (planType === 'SUPP') return `${companyName} ${plan.planType}`;
        else if (planType === 'MA') return plan.emName;
    };

    return (
        <div
            className={[
                `${
                    standalone
                        ? classes.planContainerStandlaone
                        : classes.planContainer
                }`,
            ]}
            data-e2e="planContainer"
        >
            {!standalone ? (
                <div style={{ display: 'block' }}>
                    {planType === 'SUPP' || planType === 'MA' ? (
                        <div
                            style={{ padding: '2px' }}
                            className={[
                                `${classes.importantText} " " ${classes.planName} fs-unmask`,
                            ]}
                        >
                            {planType === 'SUPP'
                                ? `${companyName} ${handleOutlierPlanType(
                                      plan.planType
                                  )}`
                                : emName}
                            <div style={{ display: 'block' }}>
                                {maybeRenderStarRating({
                                    starRating: starRating,
                                    planType,
                                })}
                            </div>
                        </div>
                    ) : (
                        <>
                            <p
                                style={{ padding: '2px' }}
                                className={[
                                    `${classes.importantText} " " ${classes.planName} fs-unmask`,
                                ]}
                            >
                                {determinePlanName({ planType, plan })}
                                <div style={{ display: 'block' }}>
                                    {maybeRenderStarRating({
                                        starRating: starRating,
                                        planType,
                                    })}
                                </div>
                            </p>
                        </>
                    )}
                </div>
            ) : (
                <></>
            )}

            <table style={tableStyling(planType)}>
                <tbody>
                    {
                        <tr>
                            <td className={classes.leftColumn}>
                                <span className={classes.importantText}>
                                    {'Monthly Premium'}
                                </span>
                            </td>
                            <td className={[classes.rightColumn]}>
                                <span className={classes.importantText}>
                                    {currentMonthlyPremium ||
                                        toCurrency(premium)}
                                </span>
                            </td>
                        </tr>
                    }

                    {planType === 'SUPP' ? (
                        cardKeys.map((ckey, i) => {
                            if (ckey.id) {
                                return (
                                    <tr key={i}>
                                        <td
                                            className={`${classes.leftColumn} fs-unmask`}
                                        >
                                            {' '}
                                            {ckey.display}{' '}
                                        </td>
                                        <td className={classes.rightColumn}>
                                            {ckey.id}
                                        </td>
                                    </tr>
                                );
                            } else return <></>;
                        })
                    ) : (
                        <></>
                    )}

                    {planType !== 'SUPP' && !standalone ? (
                        drugCoverage ? (
                            drugs.map((drug, i) => {
                                return (
                                    <tr key={i}>
                                        <td className={classes.leftColumn}>
                                            {drug.shortName}
                                        </td>
                                        <td className={classes.rightColumn}>
                                            {displayDrugCost(drug)}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className={classes.leftColumn}>
                                    {'Drugs'}
                                </td>
                                <td className={classes.rightColumn}>
                                    {'Not Covered'}
                                </td>
                            </tr>
                        )
                    ) : (
                        <></>
                    )}

                    {planType === 'MA' &&
                        !standalone &&
                        doctors.map((doctor, i) => {
                            return (
                                <tr key={i}>
                                    <td
                                        className={classes.leftColumn}
                                    >{`Dr. ${startCase(
                                        toLower(doctor.name)
                                    )}`}</td>
                                    <td className={classes.rightColumn}>
                                        {determineCoverageColor(
                                            doctor.doctorCoverage
                                        )}
                                    </td>
                                </tr>
                            );
                        })}

                    {planType === 'MA' && !standalone && (
                        <tr>
                            <td className={classes.leftColumn}>
                                {'Referral For Specialists?'}
                            </td>
                            <td className={classes.rightColumn}>
                                {referrals.specialist === 'No data'
                                    ? 'No data'
                                    : referrals.specialist === 1
                                    ? 'Yes'
                                    : 'No'}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && !standalone && (
                        <tr>
                            <td className={classes.leftColumn}>
                                {' '}
                                Primary Care{' '}
                            </td>
                            <td className={classes.rightColumn}>
                                {costShare.primary}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && !standalone && (
                        <tr>
                            <td className={classes.leftColumn}> Specialist </td>
                            <td className={classes.rightColumn}>
                                {costShare.specialist}
                            </td>
                        </tr>
                    )}

                    {planType === 'PDP' && (
                        <tr>
                            <td className={classes.leftColumn}>
                                Drug Deductible
                            </td>
                            <td className={classes.rightColumn}>
                                {toCurrency(drugDeductible)}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && (
                        <tr>
                            <td className={classes.leftColumn}>
                                Total Deductible
                            </td>
                            <td className={classes.rightColumn}>
                                {drugDeductible === 'No data'
                                    ? 'No Data'
                                    : toCurrency(
                                          healthDeductible + drugDeductible
                                      )}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && partBPrice && (
                        <tr>
                            <td className={classes.leftColumn}>
                                Part B Premium
                            </td>
                            <td className={classes.rightColumn}>
                                {drugDeductible === 'No data'
                                    ? 'No Data'
                                    : toCurrency(partBPrice)}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && !standalone && (
                        <tr>
                            <td className={classes.leftColumn}>
                                {'In-Network: Max Out of Pocket'}
                            </td>
                            <td className={classes.rightColumn}>
                                {toCurrency(costShare.MOOP.inNetwork)}
                            </td>
                        </tr>
                    )}

                    {planType === 'MA' && !standalone && (
                        <tr>
                            <td className={classes.leftColumn}>
                                {'Out-of-Network: Max out of Pocket'}
                            </td>
                            {get(costShare, 'MOOP.outOfNetwork') && (
                                <td className={classes.rightColumn}>
                                    {toCurrency(
                                        get(costShare, 'MOOP.outOfNetwork')
                                    )}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            {!standalone ? (
                <Grid
                    container
                    className={
                        planType !== 'SUPP'
                            ? classes.bottomButtons
                            : classes.suppBottomButtons
                    }
                    spacing={2}
                >
                    <Grid item xs={9}>
                        <span data-e2e="learnMoreButton">
                            <EMButton
                                className={classes.learnMoreButton}
                                onClick={() => {
                                    window.hj(
                                        'event',
                                        `Book Appointment Clicked`
                                    );
                                    planSelectedCallback();
                                }}
                                text={
                                    appointmentScheduled
                                        ? 'Appointment Scheduled'
                                        : 'Book Appointment'
                                }
                                disabled={appointmentScheduled}
                            />
                        </span>
                    </Grid>
                    <Grid item xs={3}>
                        <EMButton
                            className={classes.heartButton}
                            onClick={planSavedCallback}
                            text={getFavIcon()}
                        />
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </div>
    );
}
