import React, { Component } from 'react';
import {
    Card,
    CardActions,
    Link,
    Typography,
    Grid,
    withStyles,
} from '@material-ui/core';
import Calculator from '../assets/Calculator.png';
import { withRouter } from 'react-router-dom';
import EMButton from '../components/EmButton';
import EMDivider from '../components/EMDivider';
import { EMModal } from '../components';
import { NavBarHeight, EMRed } from '../constants';
import {
    getCompletionProgress,
    getProfile,
    sendExpertInterestEmail,
} from '../api';
import WomanWithTablet from '../assets/WomanWithTablet.png';

const styles = theme => ({
    actionCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2),
        minHeight: 160,
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    cardImage: {
        height: 97,
        width: 205,
        textAlign: 'center',
    },
    cardMainContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    enrollmentText: {
        marginTop: theme.spacing(3),
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
        alignItems: 'center',
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(12),
        marginRight: theme.spacing(12),
        marginTop: NavBarHeight,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(8),
        },
    },
    section: {
        marginBottom: theme.spacing(6),
    },
});

export class Home extends Component {
    state = {
        enrollmentWindows: [],
        sections: [],
        snackbar: { show: false, message: '', variant: '' },
        profileComplete: false,
        isModalVisible: false,
    };

    async componentDidMount() {
        const [completionProgress, profile] = await Promise.all([
            getCompletionProgress(),
            getProfile(),
        ]);

        this.setState({
            sections: completionProgress.result.sections,
            enrollmentWindows: completionProgress.result.enrollmentWindows,
            profileComplete: profile.result.isComplete,
        });
    }

    maybeRenderGetInTouchModal = () => {
        const modalContent = (
            <div>
                <Typography variant="subtitle2" style={{ marginBottom: '1em' }}>
                    {`We've notified a Medicare expert you were interested in enrolling. You can rest easy, knowing they will
                        be in touch soon.`}
                </Typography>

                <Typography variant="subtitle2">
                    {`We recommend you look through the FAQs found in Plan Search,
                        or visit the Learning Center to learn key topics for you.`}
                </Typography>
            </div>
        );

        return (
            <EMModal
                content={modalContent}
                handleClose={() => this.setState({ isModalVisible: false })}
                imageSize={'large'}
                imageSrc={Calculator}
                isOpen={this.state.isModalVisible}
                minHeight={200}
                primaryCtaCallback={() => {
                    this.setState({ isModalVisible: false });
                }}
                primaryCtaText={`Got it. Thanks`}
                buttonStyle={{
                    width: 250,
                }}
                size="sm"
                title={'Great'}
            />
        );
    };

    maybeRenderFtueHeader = () => {
        const { classes } = this.props;

        return !this.state.profileComplete ? (
            <div>
                <Typography id="introTitle" variant="h3" gutterBottom>
                    EnterMedicare is here to demystify Medicare
                </Typography>
                <EMDivider />
                <div className={classes.header}>
                    <Typography variant="subtitle2" gutterBottom>
                        Below are links to suggested next steps.
                        <b>
                            <Link href={'/profile'}>
                                {` Completing your profile `}
                            </Link>
                        </b>
                        will help you find a great plan, and
                        <b>
                            <Link href={'/'}>
                                {` starting your first course `}
                            </Link>
                        </b>
                        will show you the different resources available to
                        understand Medicare. You can always also use the menu to
                        the left.
                    </Typography>
                    <img
                        src={WomanWithTablet}
                        height="132"
                        alt="Entermedicare"
                    />
                </div>
            </div>
        ) : null;
    };

    handleCardClick = card => {
        if (card.id) {
            this.props.history.push(`/course/${card.id}`);
        } else {
            window.location.href = card.link;
        }
    };
    renderEnrollmentSection = enrollmentWindow => {
        const { classes } = this.props;
        return (
            <div className={classes.section} key={enrollmentWindow.header}>
                {this.renderSectionHeader(enrollmentWindow.header)}
                <Grid container>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            className={classes.enrollmentText}
                            style={{
                                color: !this.state.profileComplete
                                    ? 'black'
                                    : EMRed,
                                fontWeight: !this.state.profileComplete
                                    ? null
                                    : 600,
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: enrollmentWindow.body,
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {enrollmentWindow.enrollmentStatus ===
                            'ENROLLMENT_TIME' && (
                            <EMButton
                                style={{ float: 'right', width: 200 }}
                                text="Get In Touch"
                                onClick={async () => {
                                    await sendExpertInterestEmail({
                                        contractId: 'Home Screen',
                                        planName: 'Home Screen',
                                        planId: 'Home Screen',
                                    });
                                    this.setState({
                                        isModalVisible: true,
                                    });
                                }}
                            ></EMButton>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    };

    renderSectionHeader = headerText => {
        return (
            <div>
                <Typography variant="h3">{headerText}</Typography>
                <EMDivider />
            </div>
        );
    };

    renderSection = section => {
        return (
            <div key={section.header}>
                <Typography
                    variant="subtitle2"
                    style={{ marginBottom: '12px' }}
                >
                    {section.header}
                </Typography>
                {section.cards.map(card => this.renderActionCard(card))}
            </div>
        );
    };

    renderActionCard = card => {
        const { classes } = this.props;
        return (
            <Card className={classes.actionCard} key={card.title}>
                <Grid container justify="center">
                    <Grid item md={4}>
                        <div className={classes.cardImage}>
                            <img
                                style={{ height: 97 }}
                                src={card.imageUrl}
                                alt="Entermedicare"
                            />
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div className={classes.cardMainContent}>
                            <Typography variant="body2">
                                {card.completionStatus}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {card.title}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        {card.ctaText ? (
                            <CardActions style={{ float: 'right' }}>
                                <EMButton
                                    text={card.ctaText}
                                    onClick={() => this.handleCardClick(card)}
                                />
                            </CardActions>
                        ) : null}
                    </Grid>
                </Grid>
            </Card>
        );
    };

    render() {
        const { classes } = this.props;
        const { enrollmentWindows } = this.state;
        return (
            <div className={classes.pageContainer}>
                {this.maybeRenderGetInTouchModal()}
                {this.maybeRenderFtueHeader()}
                {enrollmentWindows.length
                    ? enrollmentWindows.map(enrollmentWindow =>
                          this.renderEnrollmentSection(enrollmentWindow)
                      )
                    : null}
                {this.state.sections.length ? (
                    <div>
                        <Typography variant="h3">
                            Based on your profile data, here are topics we
                            believe you should know:
                        </Typography>
                        <EMDivider />
                        {this.state.sections.map(section =>
                            this.renderSection(section)
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Home));
