import axios from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import {
    AuthenticationModalType,
    // plansResponse,
    //plansReponse is for location development and should be uncommented and pasted in with network values for a quoteId repsonse so that plan-rec does not have to run each time.
} from './constants';
import { getCookieDomain, isProd, logout } from './utils';
import moment from 'moment';

const emAxios = axios.create();
const webhookAxios = axios.create();

emAxios.interceptors.request.use(
    config => {
        let apiUrl = 'http://localhost:3002';

        if (process.env.REACT_APP_EM_ENV === 'prod') {
            apiUrl = 'https://api.entermedicare.com';
        }
        if (
            process.env.REACT_APP_EM_ENV === 'dev' &&
            !process.env.REACT_APP_IS_TRAVIS
        ) {
            apiUrl = 'https://api.entermedicare-dev.com';
        }

        config.url = apiUrl + config.url;

        config.headers.Accept = 'application/json';
        config.headers.Authorization = Cookies.get('accessToken');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

emAxios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (get(error, 'response.status', 400) === 401) {
            if (
                !window.location.pathname.includes('/learning-center') &&
                !window.location.pathname === '/'
            ) {
                // since learning center is open to all users now
                logout();
            }
        } else if (get(error, 'response.status', 400) === 403) {
            if (
                window.location.pathname.includes('/learning-center') &&
                window.location.pathname === '/'
            ) {
                // since learning center is open to all users now
                logout();
            }
        }
        return Promise.reject(error);
    }
);

export const fetchEmployer = async ({ referenceCode }) => {
    try {
        const { data } = await emAxios.get(`/employer/${referenceCode}`);
        return { result: data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'failed to fetch employer'),
        };
    }
};

export const authenticate = async ({
    email,
    password,
    isSignUp,
    referenceCode,
    brokerCode,
    campaignId,
    interaction,
    companyName,
}) => {
    const url = isSignUp ? '/auth/consumer/sign-up' : '/auth';
    const options = {
        method: 'POST',
        data: {
            identifier: email,
            password,
            referenceCode,
            brokerCode,
            campaignId,
            interaction,
            companyName,
        },
        url,
    };

    let result;
    try {
        result = await emAxios(options);
        Cookies.set('accessToken', result.data.accessToken, {
            expires: 1,
            domain: getCookieDomain(),
        });
        Cookies.set('refreshToken', result.data.refreshToken, {
            expires: 30,
            domain: getCookieDomain(),
        });
        return { result: result.data };
    } catch (e) {
        const message = get(
            e,
            'response.data.message',
            'Failed to authenticate'
        );
        const statusCode = get(e, 'response.data.statusCode', 400);
        return {
            error: message,
            showModal: isSignUp || statusCode === 402 ? true : false,
            modalType:
                statusCode === 402
                    ? AuthenticationModalType.PAY_WALL
                    : AuthenticationModalType.EMAIL_TAKEN,
        };
    }
};

export const refreshTokens = async refreshToken => {
    try {
        const result = await emAxios.post('/auth/refresh', {
            refreshToken,
        });
        Cookies.set('accessToken', result.data.accessToken, {
            expires: 1,
            domain: getCookieDomain(),
        });
        Cookies.set('refreshToken', result.data.refreshToken, {
            expires: 30,
            domain: getCookieDomain(),
        });
        return null;
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to refresh token'),
        };
    }
};

export const getCourses = async () => {
    try {
        const result = await emAxios.get('/learn/course');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to fetch courses'),
        };
    }
};

export const getCourse = async courseId => {
    try {
        const result = await emAxios.get(`/learn/course/${courseId}`);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to fetch course'),
        };
    }
};

export const updateCourseProgress = async ({ courseId, progress }) => {
    try {
        const result = await emAxios.post('/learn/course/progress', {
            id: courseId,
            progress,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to uppdate course progress'
            ),
        };
    }
};

export const sendExpertInterestEmail = async ({
    quoteId,
    planName,
    planId,
    contractId,
}) => {
    let result;
    try {
        result = await emAxios.get(
            `/quote/expert-interest?quoteId=${quoteId}&planName=${planName}&planId=${planId}&contractId=${contractId}`
        );
        return result.data;
    } catch (e) {
        return { error: 'Failed to send email' };
    }
};

export const getProfile = async () => {
    try {
        const result = await emAxios.get('/employee/profile');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to get user profile data'
            ),
        };
    }
};

export const updatePersonalDetails = async ({
    firstName,
    lastName,
    zip,
    dob,
    email,
}) => {
    try {
        const result = await emAxios.post('/employee/personal_details', {
            firstName,
            lastName,
            zip,
            dob,
            email,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to get user profile data'
            ),
        };
    }
};

export const getBroker = async () => {
    try {
        const result = await emAxios.get('/employee/broker');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to get users broker'
            ),
        };
    }
};

export const seenOnboardingModal = async () => {
    try {
        const result = await emAxios.post(
            '/employee/profile/seenOnboardingModal'
        );
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to set onboarding modal to seen'
            ),
        };
    }
};

export const getCompletionProgress = async () => {
    try {
        const result = await emAxios.get('/employee/progress');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to uppdate course progress'
            ),
        };
    }
};

export const submitContact = async ({ subject, body }) => {
    try {
        const result = await emAxios.post('/employee/contact', {
            subject,
            body,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to send message. Please try again'
            ),
        };
    }
};

export const forgotPassword = async email => {
    try {
        const result = await emAxios.post('/user/forgotPassword/sendLink', {
            email,
        });

        return result.data;
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to send reset link'),
        };
    }
};

export const resetPassword = async ({ password, secret }) => {
    try {
        const result = await emAxios.post('/user/forgotPassword/reset', {
            password,
            secret,
        });

        return result.data;
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to reset password'),
        };
    }
};

export const unlockAccount = async secret => {
    try {
        const result = await emAxios.post('/user/unlock', {
            secret,
        });

        return result.data;
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to reset password'),
        };
    }
};

export const getReviewInfo = async accessToken => {
    try {
        const result = await emAxios.post(
            '/quote/create-from-employee-center',
            {},
            {
                headers: { Authorization: accessToken },
            }
        );
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to fetch info'),
        };
    }
};

export const getPlansForQuote = async ({
    quoteId,
    carrierFilter,
    userPrefersNoReferral,
}) => {
    try {
        const result = await emAxios.get(
            `/quote/${quoteId}?carrierFilter=${
                carrierFilter ? 1 : 0
            }&referralPref=${userPrefersNoReferral === '1' ? 1 : 0}`
        );

        // const result = { data: plansResponse };

        return {
            plans: result.data['plan'],
            quoteId: result.data.quoteId,
            input: result.data.input,
            broker: result.data.broker,
            planType: result.data.planType,
        };
    } catch (e) {
        return {
            error: 'Failed to run quote',
        };
    }
};

export const coursesStarted = async ({ course, url }) => {
    const airtableHook = {
        nonProd:
            'https://hooks.airtable.com/workflows/v1/genericWebhook/appn0h8P2FcnsJqeM/wflZiIdd6g8Ufodgb/wtrssMX4FPRGIQDZy',
        prod:
            'https://hooks.airtable.com/workflows/v1/genericWebhook/appWzNMMhxBOeDF3C/wflyReRal8xl1is51/wtr11iB1UHg7uKSOo',
    };
    const dataObj = {
        courseId: course.id,
        courseUrl: course.courseUrl,
        date: moment().format('lll'),
        url,
    };
    try {
        await webhookAxios.post(
            isProd() ? airtableHook.prod : airtableHook.nonProd,
            dataObj,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (e) {
        console.log('error logging to airtable', e);
    }
};

export const getCurrentPlan = async () => {
    try {
        const result = await emAxios.get('/employee/plan');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to fetch current plan'
            ),
        };
    }
};

export const uploadCensus = async data => {
    try {
        const result = await emAxios.post('/census', data);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Census upload failed'),
        };
    }
};

export const getAppointments = async () => {
    try {
        const result = await emAxios.get('/appointments');
        return { result: result.data };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data.message',
                'Failed to fetch appointments'
            ),
        };
    }
};

export default {
    getProfile,
    getCompletionProgress,
    getCourses,
    seenOnboardingModal,
    getPlansForQuote,
    uploadCensus,
};
