import React, { Component } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography,
    withStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCourses, getProfile, seenOnboardingModal } from '../api';
import { CourseCard, AuthenticatedOnboardingModal } from '../components';
import { EMCtaGold, fadedBlue, TPMODisclaimer } from '../constants';
import Computer from '../assets/Computer.png';
import CheckboxJourney from '../components/CheckboxJourney';
import { InlineWidget, openPopupWidget } from 'react-calendly';
import { Close } from '@material-ui/icons';
import { get } from 'lodash';

const styles = theme => ({
    ctaContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    ctaFont: {
        fontWeight: 700,
        marginLeft: 8,
        color: fadedBlue,
    },
    ctaButtons: {
        display: 'flex',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
    },
    ctaLeft: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    headerCta: {
        marginTop: theme.spacing(4),
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: EMCtaGold,
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    headerFirstRow: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    headerCtaImg: {
        width: 48,
        height: 26,
    },
    headerImg: {
        width: 64,
        height: 39,
        marginLeft: theme.spacing(2),
    },
    headerPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        borderRadius: 8,
    },
    headerTitle: {
        fontSize: 32,
        fontWeight: 700,
    },
    pageContainer: {
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
        },
    },
    formControlLabel: {
        color: 'black',
    },
    progressCheckbox: {
        '&.Mui-disabled': {
            color: '#5D47BD',
        },
    },
    calendly: {
        height: 300,
        width: '100%',
    },
});

class LearningCenter extends Component {
    state = {
        broker: {},
        courses: [],
        progressStr: '',
        snackbar: { show: false, message: '', variant: '' },
        shouldRenderAuthenticatedOnboardingModal: null,
        brokerCode: '',
        referenceCode: '',
        profile: {},
        hasMadeProgress: false,
        bookFirst: false,
    };

    async componentDidMount() {
        let customer = null;
        try {
            const { result } = await getProfile();
            console.log('profile', result);
            window.hj('identify', get(result, 'profile.id'), {
                employerId: get(result, 'employerId', 'unknown'),
            });
            let bookFirst = false;
            if (window.location.search === '?bookFirst=true') {
                bookFirst = true;
            }
            this.setState({
                profile: result,
                broker: result.broker,
                bookFirst,
            });
        } catch (e) {
            console.log('the user wasnt authenticated', e);
        }

        if (customer && customer.seenOnboardingModal) {
            this.setState({
                shouldRenderAuthenticatedOnboardingModal: false,
            });
        } else {
            this.setState({
                shouldRenderAuthenticatedOnboardingModal: true,
            });
        }

        const { result, error } = await getCourses();
        if (!error) {
            this.setState({
                courses: result.courses,
                progressStr: result.totalProgress,
                hasMadeProgress:
                    Boolean(
                        result.courses.filter(course => course.progress !== 0)
                            .length
                    ) > 0,
            });
        } else {
            this.setState({
                snackbar: { show: true, message: error, variant: 'error' },
            });
        }
    }

    handleCustomizeProfileClick = () => {
        this.props.history.push('/profile');
    };

    renderFTUEHeader = () => {
        const { classes } = this.props;
        const { profile } = this.state;
        return (
            <Paper className={classes.headerPaper} elevation={0}>
                <div className={classes.headerFirstRow}>
                    <img
                        src={Computer}
                        className={classes.headerImg}
                        alt="Entermedicare"
                    />
                    <div className={classes.columnContainer}>
                        <Typography
                            className={classes.headerTitle}
                            variant="subtitle2"
                        >
                            Start here
                        </Typography>
                        {profile ? (
                            <Typography variant="subtitle2" gutterBottom>
                                {`You are now in the learning center. Here you will find courses about the most common questions in Medicare.`}
                            </Typography>
                        ) : (
                            <Typography variant="subtitle2" gutterBottom>
                                {`Welcome to EnterMedicare. Your employer has provided this free-to-use service to help you learn more about Medicare. Click “Sign Up” above to create an account so you can compare your plan costs to available Medicare plans and save your course progress. `}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className={classes.ctaContainer}>
                    {this.props.customer ? (
                        <CheckboxJourney
                            profile={profile}
                            courseProgress={this.state.hasMadeProgress}
                        />
                    ) : null}
                </div>
            </Paper>
        );
    };

    setOnboardingModalToSeen = () => {
        seenOnboardingModal();
    };

    renderFTUE = () => {
        const { customer } = this.props;

        if (
            customer &&
            this.state.shouldRenderAuthenticatedOnboardingModal != null &&
            this.state.shouldRenderAuthenticatedOnboardingModal &&
            !customer.seenOnboardingModal
        ) {
            return (
                <>
                    <AuthenticatedOnboardingModal
                        hideModalCallback={() => {
                            this.setState({
                                shouldRenderAuthenticatedOnboardingModal: false,
                            });
                            this.setOnboardingModalToSeen();
                        }}
                    />
                    {this.renderFTUEHeader()}
                </>
            );
        } else {
            return <>{this.renderFTUEHeader()}</>;
        }
    };

    maybeRenderAppointmentModal = () => {
        const classes = this.props;
        const broker = this.state.broker
            ? this.state.broker
            : {
                  firstName: 'Entermedicare',
                  lastName: 'Team',
                  agencyName: 'EnterMedicare',
                  email: 'hello@entermedicare.com',
                  calendarLink: 'https://calendly.com/dan-jones-3',
              };
        if (broker.firstname) {
            const displayName =
                broker.agencyName || broker.firstname + ' ' + broker.lastname;
            message = `${displayName} has been notified and will reach out shortly. You may also reach them via email at ${broker.email}`;
        }
        const modalContent = (
            <div>
                <Typography variant="subtitle2" style={{ marginBottom: '1em' }}>
                    We found unbiased experts are the best way to get a plan
                    that fits you.
                </Typography>

                <Typography variant="subtitle2">
                    Click the button below for a Licensed Medicare Agent to
                    connect with you by phone or email to discuss your Medicare
                    options.{' '}
                    <a
                        href="https://entermedicare.com/partners"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Click here
                    </a>{' '}
                    to see the list of our agency partners.
                </Typography>
            </div>
        );
        return (
            <Dialog
                content={modalContent}
                size="lg"
                open={this.state.bookFirst}
                onClose={() => this.setState({ bookFirst: false })}
                style={{ minHeight: 200 }}
                className={classes.calendly}
                PaperProps={{
                    style: {
                        width: '100%',
                    },
                }}
            >
                <DialogTitle>
                    {' '}
                    <Typography variant="h3">
                        {' '}
                        Schedule a Meeting
                        <IconButton
                            onClick={() => this.setState({ bookFirst: false })}
                            style={{ float: 'right' }}
                        >
                            <Close />
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ paddding: 0 }}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {TPMODisclaimer}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        Please note that all voice communications will be
                        recorded in accordance with CMS rules.
                    </Typography>

                    <InlineWidget
                        url={broker.calendarLink}
                        prefill={{
                            email: this.state.profile.email,
                        }}
                        // doing some heinous stuff to pass userEmail unchanged.
                        utm={{
                            utmMedium: process.env.REACT_APP_EM_ENV,
                            utmContent: this.state.profile.email,
                            utmCampaign: this.state.profile.employerId,
                            utmSource: get(
                                this,
                                'state.profile.broker.id',
                                null
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        );
    };

    render() {
        const { classes } = this.props;
        const { courses } = this.state;
        return (
            <div className={classes.pageContainer}>
                {this.renderFTUE()}
                <div>
                    {courses.map(course => (
                        <CourseCard
                            key={course.title}
                            course={course}
                            urlParams={this.props.location.search}
                            isAuthorized={this.props.isAuthorized}
                            profile={this.state.profile}
                        />
                    ))}
                </div>
                {this.maybeRenderAppointmentModal()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    customer: state.customer,
});

export default withRouter(
    connect(mapStateToProps)(withStyles(styles)(LearningCenter))
);
