import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import EMFlatButton from '../components/EMFlatButton';
import {
    CircularProgress,
    Paper,
    Typography,
    withStyles,
} from '@material-ui/core';
import { uploadCensus, fetchEmployer } from '../api';
import { GetApp, Publish } from '@material-ui/icons';

const styles = theme => ({
    buttonStyle: {
        margin: 4,
        width: 200,
        height: 54,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    pageContainer: {
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
        },
    },
    headerPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        borderRadius: 8,
    },
    headerTitle: {
        fontSize: 32,
        fontWeight: 700,
    },
    headerFirstRow: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '60%',
        margin: '0 auto',
    },
    companyLogo: {
        maxWidth: '250px',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    instructions: {
        marginLeft: '20px',
    },
});

class CensusUpload extends Component {
    state = {
        selectedFile: null,
        referenceCode: null,
        employer: {},
        errors: [],
        finishedUpload: false,
        loading: false,
    };
    fileUploadButton = React.createRef(null);

    async componentDidMount() {
        const { referenceCode } = queryString.parse(this.props.location.search);

        this.setState({
            referenceCode,
        });

        const { result } = await fetchEmployer({ referenceCode });
        this.setState({ employer: result });
    }

    onFileChange = async event => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.name) {
            this.setState({ selectedFile, loading: true });
            const formData = new FormData();

            // Update the formData object
            formData.append('census', selectedFile, selectedFile.name);
            formData.append('referenceCode', this.state.referenceCode);

            // Request made to the backend api
            // Send formData object
            const { result } = await uploadCensus(formData);

            if (result.message === 'error') {
                this.setState({
                    errors: result.errors,
                    finishedUpload: false,
                    loading: false,
                });
            } else {
                this.setState({
                    errors: [],
                    finishedUpload: true,
                    loading: false,
                });
            }
        }
    };

    fileUploadButtonClicked = () => {
        this.fileUploadButton.current.click();
        this.setState({ selectedFile: null });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.pageContainer}>
                <Paper className={classes.headerPaper} elevation={0}>
                    <div>
                        <div className={classes.headerFirstRow}>
                            <div className={classes.columnContainer}>
                                <div className={classes.titleRow}>
                                    <Typography
                                        className={classes.headerTitle}
                                        variant="subtitle2"
                                    >
                                        Census Upload
                                    </Typography>

                                    {this.state.employer.image_url && (
                                        <img
                                            className={classes.companyLogo}
                                            src={this.state.employer.image_url}
                                            alt={'company logo'}
                                        ></img>
                                    )}
                                </div>

                                <Typography variant="subtitle2" gutterBottom>
                                    {`Welcome to EnterMedicare. You can use this page to download a sample EnterMedicare 
                                    census, upload your first employee census, or upload a refreshed employee census 
                                    to help us better serve your employees. We recommend the following workflow:`}
                                </Typography>
                                <div className={classes.instructions}>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {`1. Download the sample census using the button below`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {`2. View the column structure in Excel`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {`3. Format your own employee census to have the same columns as our sample file`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {`4. Export the data as a "csv" file within Excel (confirm that it ends with .csv)`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        {`5. Upload the census using the upload button below`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonRow}>
                            <EMFlatButton
                                className={classes.buttonStyle}
                                icon={<GetApp />}
                                text={'Sample Census'}
                                data-e2e={'downloadSampleCensus'}
                                variant={'outlined'}
                                color={'inherit'}
                                href={
                                    process.env.PUBLIC_URL +
                                    '/sample_census.csv'
                                }
                            />

                            <div className={classes.wrapper}>
                                <EMFlatButton
                                    className={classes.buttonStyle}
                                    icon={<Publish />}
                                    onClick={this.fileUploadButtonClicked}
                                    disabled={this.state.loading}
                                    text={'Upload Census'}
                                    data-e2e={'uploadCensus'}
                                    variant={'outlined'}
                                    color={'inherit'}
                                />
                                {this.state.loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </div>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={this.onFileChange}
                            ref={this.fileUploadButton}
                            hidden
                        />

                        {this.state.errors.length > 0 ? (
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant="h3">
                                    Upload Failed
                                </Typography>
                                {this.state.errors.map((error, i) => (
                                    <p key={i} style={{ color: 'red' }}>
                                        {!isEmpty(error) ? error : 'Unknown'}
                                    </p>
                                ))}
                                <p>
                                    Please correct the census and try again. You
                                    can reach out to the EnterMedicare team if
                                    you have any questions.
                                </p>
                            </div>
                        ) : null}

                        {this.state.finishedUpload && (
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant="h3">
                                    {' '}
                                    Upload Successful{' '}
                                </Typography>
                                <p>
                                    Thank you for uploading your census. The
                                    EnterMedicare team will take it from here
                                    and update our user records.
                                </p>
                            </div>
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(CensusUpload));
