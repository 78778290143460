import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getProfile, getCourses } from '../api';
import { ProfileSection } from '../components';
import Computer from '../assets/Computer.png';
import { fadedBlue } from '../constants';
import CheckboxJourney from '../components/CheckboxJourney';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
    profileSectionContainer: {
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
        },
    },
    ctaContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    ctaFont: {
        fontWeight: 700,
        marginLeft: 8,
        color: fadedBlue,
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
    },
    ctaLeft: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    headerFirstRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerCtaImg: {
        width: 48,
        height: 26,
    },
    headerImg: {
        width: 64,
        height: 39,
        marginLeft: theme.spacing(2),
    },
    headerPaper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        borderRadius: 8,
    },
    headerTitle: {
        fontSize: 32,
        fontWeight: 700,
    },
}));

const Profile = () => {
    const classes = useStyles();
    const [profileData, setProfileData] = useState(null);
    const [courses, setCourses] = useState([]);
    const [profile, setProfile] = useState({});
    // eslint-disable-next-line
    const [progressStr, setProgressStr] = useState(null);

    const fetch = async () => {
        const { result } = await getProfile();
        window.hj('identify', get(result, 'profile.id'), {
            employerId: get(result, 'employerId', 'unknown'),
        });
        const fetchedCourses = await getCourses();
        setProfile({ result });

        if (result && result.sections) {
            let sectionsData = [];
            result.sections.forEach(section => {
                sectionsData = sectionsData.concat(section.data);
            });
            let combinedSection = result.sections[0];
            combinedSection.data = sectionsData;
            setProfileData(combinedSection);
        }

        if (!fetchedCourses.error) {
            setCourses(fetchedCourses.result.courses);
            setProgressStr(fetchedCourses.result.totalProgress);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const hasMadeProgress = () => {
        if (courses.length === 0) {
            return false;
        } else {
            return courses.filter(course => course.progress !== 0).length > 0;
        }
    };

    const renderFTUEHeader = () => {
        return (
            <Paper className={classes.headerPaper} elevation={0}>
                <div className={classes.headerFirstRow}>
                    <img
                        src={Computer}
                        className={classes.headerImg}
                        alt="Entermedicare"
                    />
                    <div className={classes.columnContainer}>
                        <Typography
                            className={classes.headerTitle}
                            variant="subtitle2"
                        >
                            Profile
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            {`You are now in the profile section. Here you can update information about yourself to help us better understand your Medicare needs.`}
                        </Typography>
                    </div>
                </div>
                <div className={classes.ctaContainer}>
                    {profile ? (
                        <CheckboxJourney
                            profile={profile}
                            courseProgress={hasMadeProgress()}
                        />
                    ) : null}
                    {/* 16px width when both buttons are present */}
                    {hasMadeProgress() && profile && !profile.isComplete ? (
                        <div style={{ width: '16px' }} />
                    ) : null}
                </div>
            </Paper>
        );
    };

    return (
        <div className={classes.profileSectionContainer}>
            {renderFTUEHeader()}
            <Grid container>
                <Grid item lg={12}>
                    {profileData ? (
                        <ProfileSection section={profileData} />
                    ) : null}
                </Grid>
            </Grid>
        </div>
    );
};

export default Profile;
