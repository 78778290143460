import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { amber, green, red, blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Close, CheckCircle, Warning, Info, Error } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    info: {
        backgroundColor: blue[500],
    },
    error: {
        backgroundColor: red[600],
    },
    warning: {
        backgroundColor: amber[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function SnackbarContentWrapper(props) {
    const classes = useStyles();
    const { className, message, onClose, variant, ...other } = props;

    const getIconForVariant = variant => {
        switch (variant) {
            case 'success':
                return <CheckCircle />;
            case 'warning':
                return <Warning />;
            case 'error':
                return <Error />;
            case 'info':
                return <Info />;
        }
    };

    return (
        <SnackbarContent
            className={`${classes[variant]} ${className}`}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {getIconForVariant(variant)}
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                >
                    {<Close />}
                </IconButton>,
            ]}
            {...other}
        />
    );
}

SnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
        .isRequired,
};

export default function EMSnackbar(props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { variant, message } = props;

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={variant}
                    message={message}
                />
            </Snackbar>
        </div>
    );
}
