import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    button: {
        minWidth: '132px',
        margin: 10,
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 600,
    },
    centerProgress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circularProgress: {
        margin: 0,
    },
}));

const uppercaseFirst = text => {
    // rolling our own because lodash capitalize function  lowercases everything else.
    // We run into some problems when IT18 passes a component function back to us, so when that happens, just return the component back.
    // only capitalize when text.
    if (!text || typeof text !== 'string') return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export default function EMButton({ isLoading, ...props }) {
    const classes = useStyles();
    const fsUnmask = 'fs-unmask';
    return (
        <Button
            {...props}
            className={`${classes.button} ${
                props.className ? props.className : ''
            }`}
            color={props.color || 'primary'}
            onClick={props.onClick}
            variant={props.variant || 'contained'}
            disabled={props.disabled || isLoading}
        >
            <div className={classes.centerProgress}>
                {isLoading && (
                    <CircularProgress
                        color={props.loaderColor || 'primary'}
                        size={props.loaderSize || 24}
                        className={classes.circularProgress}
                    />
                )}
            </div>
            <span className={fsUnmask}>
                {!isLoading && uppercaseFirst(props.text)}
            </span>
        </Button>
    );
}

EMButton.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
    variant: PropTypes.string,
};
