import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import EMButton from '../components/EmButton';
import { getCourse, getCourses, updateCourseProgress } from '../api';
import { CourseCTAModal } from '../components';
import { maxBy, find } from 'lodash';

const styles = theme => ({
    fabContainer: {
        position: 'fixed',
        top: theme.spacing(2.7),
        right: 10,
        marginRight: '20%',
        zIndex: 9999,

        [theme.breakpoints.down('md')]: {
            marginRight: '15%',
        },
    },
    iframeContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
});

class CoursePage extends Component {
    state = {
        course: {
            id: this.props.match.params,
        },
        snackbar: { show: false, message: '', variant: '' },
        shouldRenderCTAModal: false,
    };

    findNextCourseId = (course, courses) => {
        const lastCourse = maxBy(courses, 'order');
        if (course.id !== lastCourse.id) {
            const nextCourse = find(courses, { order: course.order + 1 });
            return nextCourse.id;
        } else {
            return null;
        }
    };

    async componentDidMount() {
        const { id } = this.props.match.params;
        const { result, error } = await getCourse(id);
        if (error) {
            this.setState({
                snackbar: { show: true, message: error, variant: 'error' },
            });
        } else if (!this.props.isAuthorized) {
            this.setState({ course: result });
        } else {
            const courseObject = await getCourses();
            const { courses } = courseObject.result;
            result.nextCourseId = this.findNextCourseId(result, courses);
            this.setState({ course: result });

            if (result.progress === 0) {
                await updateCourseProgress({ courseId: id, progress: 0.01 });
            }
        }
    }

    shouldComponentUpdate(prevProps, nextProps) {
        if (
            nextProps.history &&
            nextProps.history.location.pathname !==
                prevProps.history.location.pathname
        ) {
            return true;
        } else return true;
    }

    renderCTAModal = () => {
        this.setState({ shouldRenderCTAModal: true });
    };

    maybeRenderCTAModal = () => {
        const { shouldRenderCTAModal } = this.state;
        return shouldRenderCTAModal ? (
            <CourseCTAModal
                show
                nextCourseId={this.state.course.nextCourseId}
                profileComplete={this.props.profileComplete}
                hideModalCallback={() => {
                    this.setState({ shouldRenderCTAModal: false });
                    this.componentDidMount();
                }}
            />
        ) : null;
    };

    renderNavButtons = classes => {
        let primaryButtonText = 'Save Progress';
        let secondaryButtonText = 'Complete course';
        let primaryAction = this.handleSaveProgress;
        let secondaryAction = this.handleCompleteCourse;
        if (this.props.isAuthorized) {
            return (
                <div className={classes.fabContainer}>
                    <EMButton
                        color={'default'}
                        variant={'text'}
                        onClick={primaryAction}
                        style={{ color: '#fff' }}
                        text={primaryButtonText}
                    />
                    <EMButton
                        color={'default'}
                        onClick={secondaryAction}
                        text={secondaryButtonText}
                    />
                </div>
            );
        }
    };

    handleCompleteCourse = async () => {
        const { course } = this.state;
        await updateCourseProgress({
            courseId: this.state.course.id,
            progress: 1.0,
        });
        if (course.nextCourseId || !this.props.profileComplete) {
            this.renderCTAModal();
        } else {
            this.props.history.push('/learning-center');
        }
    };

    handleSaveProgress = () => {
        const { course } = this.state;
        if (course.nextCourseId || !this.props.profileComplete) {
            this.renderCTAModal();
        } else {
            this.props.history.push('/learning-center');
        }
    };

    backToSite = () => {
        window.location = 'https://www.entermedicare.com/webapp';
    };

    toSignUp = () => {
        this.props.history.push('/sign-up');
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.iframeContainer}>
                    {this.state.course.courseUrl && (
                        <iframe
                            title={'EnterMedicare Course'}
                            style={{ flexGrow: 1 }}
                            src={`/courses/${this.state.course.courseUrl}`}
                            data-hj-allow-iframe=""
                        />
                    )}
                    {this.renderNavButtons(classes)}
                </div>
                {this.maybeRenderCTAModal()}
            </>
        );
    }
}

export default withRouter(withStyles(styles)(CoursePage));
