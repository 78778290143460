import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import EMModal from './Modal';
import { EMPurple } from './../constants';
import EMButton from '../components/EmButton';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import OnboardedModal1Profile from '../assets/OnboardedModal1Profile.png';
import OnboardedModal2Learn from '../assets/OnboardedModal2Learn.png';
import OnboardedModal3Plans from '../assets/OnboardedModal3Plans.png';
// import OnboardedModal5GetStarted from '../assets/OnboardedModal5GetStarted.png';

const useStyles = makeStyles(() => ({
    modalContent: {
        width: '100%',
    },
    onboardingTitle: {
        fontWeight: '700',
        fontSize: '34px',
        color: '#092744',
    },
    onboardingSubtitle: {
        fontWeight: '500',
        fontSize: '28px',
        color: '#092744',
    },
    onboardingBody: {
        fontSize: '24px',
        color: '#092744',
    },
    onboardingButtonContainer: {
        height: '54px',
        marginTop: '32px',
        marginBottom: '32px',
    },
    onboardingButtonBack: {
        marginLeft: '0px',
        height: '44px',
        width: '30%',
        float: 'left',
        backgroundColor: 'transparent',
        color: EMPurple,
        border: '1px solid ' + EMPurple,
        boxSizing: 'border-box',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    onboardingButtonNext: {
        marginRight: '0px',
        height: '44px',
        width: '30%',
        float: 'right',
        backgroundColor: EMPurple,
    },
    onboardingGrid: {
        background: 'linear-gradient(180deg, #FAFBFC 0%, #EFF2F5 100%)',
        padding: '32px',
    },
    onboardingGridImage: {
        maxHeight: '448px',
    },
    onboardingTextContainer: {
        marginLeft: '5%',
        // maxWidth: '464px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    onboardingPageIndicators: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    onboardingIndicator: {
        height: '4px',
        backgroundColor: '#A9BBCB',
        width: '30%',
        marginBottom: '64px',
    },
    onboardingIndicatorSelected: {
        height: '4px',
        backgroundColor: '#1F4160',
        width: '30%',
        marginBottom: '64px',
    },
    onboardingImage: {
        maxHeight: '194px',
    },
    imageDisclaimer: {
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '480px',
    },
}));

function AuthenticatedOnboardingModal(props) {
    const { hideModalCallback } = props;
    const classes = useStyles();
    const [currentId, setCurrentId] = useState(0);
    const next = () => {
        if (currentId === 2) {
            hideModalCallback(false);
            setCurrentId(0);
        }
        setCurrentId(currentId + 1 < 3 ? currentId + 1 : 2);
    };
    const back = () => {
        setCurrentId(currentId - 1 >= 0 ? currentId - 1 : 0);
    };

    let modalTextContent = [
        {
            image: OnboardedModal2Learn,
            altText:
                'A screenshot of the learn tab which showcases the different lessons to learn about medicare',
            title: 'LEARN',
            subTitle:
                'Courses with information on different parts of Medicare.',
            body:
                'Courses include Medicare Advantage (Part C), Supplements and Prescription Drug Plans (Pard D). You can do as much or as little as you want. ',
        },
        {
            image: OnboardedModal1Profile,
            altText:
                'A Screenshot of the Profile Tab showcasing how your information will be stored.',
            title: 'PROFILE',
            subTitle:
                'Here we store the questions you’ve answered. Your responses help guide the options you see in the plans section.',
            body: 'You can make edits on the profile screen.',
        },
        {
            image: OnboardedModal3Plans,
            altText: 'a screenshot of the plans tab.',
            title: 'PLANS',
            subTitle: 'Plans is where you see Medicare plan options.',
            body:
                'You can click PLANS any time to view plans that may be right for you.',
        },
        // {
        //     image: OnboardedModal5GetStarted,
        //     altText: 'Done with the tutorial image!',
        //     title: "Let's Get Started!",
        //     body:
        //         'That’s it for now! Click the Finish button below to close these instructions. On the next screen, we recommend you use the yellow buttons to either edit your profile or take your first course.',
        // },
    ];
    const indicatorClass = indicatorNum => {
        return currentId === indicatorNum
            ? classes.onboardingIndicatorSelected
            : classes.onboardingIndicator;
    };
    const modalContent = (
        <div className={classes.modalContent}>
            <div className={classes.onboardingPageIndicators}>
                {modalTextContent.map(function(_, index) {
                    return (
                        <div
                            className={indicatorClass(index)}
                            key={`pageIndicator-${index}`}
                        ></div>
                    );
                })}
            </div>
            <Grid
                className={classes.onboardingGrid}
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
            >
                <Grid
                    className={classes.onboardingGridImage}
                    item
                    xs={12}
                    md={6}
                    align="center"
                    justify="center"
                >
                    <img
                        alt={modalTextContent[currentId].altText}
                        className={classes.onboardingImage}
                        src={modalTextContent[currentId].image}
                    />
                    <Typography className={classes.imageDisclaimer}>
                        (The image above is not clickable. Use the buttons at
                        the bottom to proceed when ready.)
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.onboardingTextContainer}>
                        <div className={classes.onboardingTextCenter}>
                            <Typography className={classes.onboardingTitle}>
                                {modalTextContent[currentId].title}
                            </Typography>
                            <Typography className={classes.onboardingSubtitle}>
                                {modalTextContent[currentId].subTitle}
                            </Typography>
                            <Typography>
                                {modalTextContent[currentId].body}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.onboardingButtonContainer}>
                <EMButton
                    className={classes.onboardingButtonBack}
                    color="secondary"
                    variant="contained"
                    text={'Back'}
                    onClick={back}
                />
                <EMButton
                    className={classes.onboardingButtonNext}
                    color="secondary"
                    variant="contained"
                    text={'Next'}
                    onClick={next}
                />
            </div>
        </div>
    );
    return (
        <EMModal
            className={classes.modalContent}
            title={''}
            isOpen={true}
            content={modalContent}
            size="xl"
            handleClose={() => hideModalCallback(false)}
        />
    );
}

export default withRouter(AuthenticatedOnboardingModal);
