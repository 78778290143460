import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Typography,
    Grid,
    Paper,
    Button,
    MenuItem,
    Toolbar,
    Select,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import EMTextField from '../EMTextfield';
import EMButton from '../EmButton';
import { FavoriteBorder } from '@material-ui/icons';
import { PlanSelection } from '../Plans';
import { makeStyles } from '@material-ui/core/styles';
import { navbarBlue, EMCtaGold } from '../../constants';
import Computer from '../../assets/Computer.png';
import PlansSavedEmptyState from '../../assets/PlansSavedEmptyState.png';

const useStyles = makeStyles(theme => ({
    defaultButton: {
        textTransform: 'none !important',
        color: navbarBlue,
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Source Sans Pro, sans-serif',
        marginRight: 8,
    },

    title: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        marginTop: 0,
        color: '#092744',
        fontWeight: 'bold',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    titleMobile: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        marginTop: 0,
        color: '#092744',
        fontWeight: 'bold',
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    textFieldStyle: {
        textAlign: 'center',
    },
    sectionContainer: {
        marginBottom: 20,
    },
    toggleButton: {},
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',
        },
    },
    plansContainer: {
        width: '100%',
        textAlign: 'left',
        backgroundColor: 'white',
        display: 'flex',
        borderRadius: 8,
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign: 'left',
            justifyContent: 'space-around',
        },
        flexWrap: 'wrap',
    },
    subtitle: {
        color: '#092744',
        lineHeight: '30px',
        fontSize: '20px',
        marginBottom: '16px',
    },
    selectMenuItem: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textAlign: 'center',
    },
    paperHeader: {
        borderRadius: '8px 8px 0px 0px',
        border: '1px solid #E1E7ED',
        marginBottom: '4px',
        padding: theme.spacing(4),
    },
    titleCardContainer: {
        display: 'flex',
    },
    titleImageContainer: {
        marginRight: 8,
    },
    titleBodyContainer: {
        width: '100%',
    },
    paperBody: {
        borderRadius: '0px 0px 8px 8px',
        paddingTop: 0,
        paddingBottom: 0,
    },
    primaryButton: {
        borderRadius: '4px',
        textTransform: 'none !important',
        color: '#fff',
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    savedPlansButtonActive: {
        backgroundColor: EMCtaGold,
    },
    savedPlansButtonInactive: {
        backgroundColor: '#37536C',
    },
    saveInstructionsContainer: {
        width: '100%',
    },
    saveInstructions: {
        marginTop: '64px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    saveInstructionsText: {
        color: '#1F4160',
        fontSize: '32px',
        maxWidth: '576px',
        margin: 'auto',
        width: '50%',
        marginTop: '64px',
        marginBottom: '219px',
        textAlign: 'center',
    },
}));

function PlanDisplay({
    plans,
    planType,
    handleTypeChange,
    planSelectedCallback,
    showSaved,
    showSavedCallback,
    planSavedCallback,
    handleFilterCallback,
    filter,
}) {
    const numberOfPlansPerPage = 6;
    const [appointmentScheduled, setAppointmentScheduled] = useState(
        localStorage.getItem('appointment_scheduled')
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [displayedPlans, setDisplayedPlans] = useState(
        plans.slice(
            (pageNumber - 1) * numberOfPlansPerPage,
            pageNumber * numberOfPlansPerPage
        )
    );
    const [showMorePlans, setShowMorePlans] = useState(false);

    useEffect(() => {
        if (showSaved) {
            setPageNumber(1);
        }
    }, [showSaved]);

    useEffect(() => {
        setDisplayedPlans(
            plans.slice(
                (pageNumber - 1) * numberOfPlansPerPage,
                pageNumber * numberOfPlansPerPage
            )
        );
    }, [pageNumber, plans]);

    useEffect(() => {
        function isCalendlyEvent(e) {
            return e.data.event && e.data.event.indexOf('calendly') === 0;
        }

        window.addEventListener('message', function(e) {
            if (isCalendlyEvent(e)) {
                if (e.data.event === 'calendly.event_scheduled') {
                    window.FS.event('Appointment Scheduled', {
                        appointment: e.data,
                    });
                    setAppointmentScheduled(true);
                    localStorage.setItem(
                        'appointment_scheduled',
                        JSON.stringify(true)
                    );
                }
            }
        });
    }, []);

    const classes = useStyles();
    const renderPlanTypeSelect = () => {
        const options = [
            { id: 'MA', display: 'Medicare Advantage' },
            { id: 'SUPP', display: 'Medicare Supplement' },
            { id: 'PDP', display: 'Prescription Drug Plan' },
        ];
        const menu = options.map(name => (
            <MenuItem
                key={name.id}
                value={name.id}
                className={`${classes.selectMenuItem} fs-unmask`}
            >
                {name.display}
            </MenuItem>
        ));

        const selectStyle = {
            color: '#092744',
            fontSize: 20,
            fontWeight: 700,
            height: 48,
        };

        return (
            <div>
                <EMTextField
                    fullWidth
                    select
                    variant="outlined"
                    id="plan-em-select"
                    SelectProps={{
                        style: selectStyle,
                    }}
                    InputProps={{
                        style: {},
                    }}
                    value={planType}
                    disabled={showSaved}
                    onChange={handleTypeChange}
                    size={'medium'}
                    className={classes.textFieldStyle}
                >
                    {menu}
                </EMTextField>
            </div>
        );
    };

    const renderSaveInstructions = (plans, showSaved) => {
        if (showSaved && plans.length === 0) {
            return (
                <div className={classes.saveInstructionsContainer}>
                    <img
                        alt={'instructions on how to save plans'}
                        src={PlansSavedEmptyState}
                        className={classes.saveInstructions}
                        width={400}
                        height={393}
                    />
                    <h2 className={classes.saveInstructionsText}>
                        Click the{' '}
                        <FavoriteBorder className={classes.heartIcon} /> Icon on
                        a Plan Card to compare plans here.
                    </h2>
                </div>
            );
        }
    };

    const planViewOptions = () => {
        if (!showSaved) {
            return (
                <Grid container>
                    <Grid item sm={6}>
                        <div style={{ marginRight: 8, minHeight: 54 }}>
                            {renderPlanTypeSelect()}
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            className={`${classes.primaryButton} ${
                                showSaved
                                    ? classes.savedPlansButtonActive
                                    : classes.savedPlansButtonInactive
                            }`}
                            variant="contained"
                            onClick={showSavedCallback}
                        >
                            Compare Plans
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container>
                    <Grid item sm={12}>
                        <Button
                            fullWidth
                            className={`${classes.primaryButton} ${
                                showSaved
                                    ? classes.savedPlansButtonActive
                                    : classes.savedPlansButtonInactive
                            } `}
                            variant="contained"
                            onClick={showSavedCallback}
                        >
                            Back to All Plans
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    };

    return (
        <div className={classes.sectionContainer}>
            <Paper className={classes.paperHeader} elevation={0}>
                <div className={classes.titleCardContainer}>
                    <div className={classes.titleImageContainer}>
                        <img
                            alt={'computer'}
                            src={Computer}
                            width={64}
                            style={{ marginRight: '8px' }}
                        />
                    </div>

                    <div className={classes.titleBodyContainer}>
                        <div className={classes.topContainer}>
                            <p
                                className={`${classes.title} fs-unmask`}
                                id={`plandisplay-title`}
                            >
                                Plans For You
                            </p>
                        </div>
                        <p
                            className={`${classes.titleMobile} fs-unmask`}
                            id={`plandisplay-title`}
                        >
                            Plans For You
                        </p>
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.subtitle}
                        >
                            Click the box below to change plan type.
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.subtitle}
                        >
                            Plan names not displayed below due to CMS
                            regulations. Click &apos;Learn More&apos; for
                            additional information.
                        </Typography>
                        {planViewOptions()}
                    </div>
                </div>
            </Paper>
            {planType === 'MA' && (
                <Toolbar style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <Typography
                        style={{
                            flex: 1,
                            color: '#092744',
                            fontSize: 20,
                            fontFamily: 'Source Sans Pro, sans-serif',
                            fontWeight: 'bold',
                        }}
                    >
                        Filter By:{' '}
                    </Typography>
                    <Select
                        native
                        value={filter}
                        onChange={({ target: { value } }) =>
                            handleFilterCallback(value)
                        }
                        inputProps={{ name: 'filter' }}
                    >
                        <option value={'starRating'}>Star Rating</option>
                        <option value={'premium'}>Premium</option>
                        <option value={'inNetworkDoctors'}>
                            In Network Doctors
                        </option>
                    </Select>
                </Toolbar>
            )}
            <Paper className={classes.paperBody}>
                <div className={classes.plansContainer}>
                    {renderSaveInstructions(plans, showSaved)}
                    {displayedPlans.map((plan, i) => (
                        <PlanSelection
                            appointmentScheduled={appointmentScheduled}
                            key={i}
                            planType={planType}
                            plan={plan}
                            planSelectedCallback={() => {
                                console.log(plan);
                                planSelectedCallback(plan);
                            }}
                            showFAQ={true}
                            planSavedCallback={() => planSavedCallback(plan)}
                        />
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        padding: 30,
                        justifyContent: 'center',
                    }}
                >
                    {!showMorePlans && (
                        <EMButton
                            onClick={() => setShowMorePlans(true)}
                            text={' See More Plans '}
                        />
                    )}
                    {showMorePlans && (
                        <Pagination
                            variant="text"
                            showFirstButton={false}
                            showLastButton={false}
                            onChange={(e, pageNumber) =>
                                setPageNumber(pageNumber)
                            }
                            renderItem={i => {
                                if (
                                    i['aria-label'] === 'Go to previous page' &&
                                    i.page > 0
                                ) {
                                    return (
                                        <Button
                                            onClick={() => i.onClick()}
                                            variant="contained"
                                        >
                                            {' '}
                                            {i['aria-label']}{' '}
                                        </Button>
                                    );
                                }
                                if (
                                    i['aria-label'] === 'Go to next page' &&
                                    i.page <=
                                        Math.ceil(
                                            plans.length / numberOfPlansPerPage
                                        )
                                ) {
                                    return (
                                        <Button
                                            onClick={() => i.onClick()}
                                            variant="contained"
                                        >
                                            {' '}
                                            {i['aria-label']}{' '}
                                        </Button>
                                    );
                                } else return null;
                            }}
                            boundaryCount={0}
                            siblingCount={0}
                            count={Math.ceil(
                                plans.length / numberOfPlansPerPage
                            )}
                        />
                    )}
                    {/* Default ranges */}
                </div>
            </Paper>
        </div>
    );
}

export default withRouter(PlanDisplay);
