import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { refreshTokens } from './api';

export const isProd = () => {
    return process.env.REACT_APP_EM_ENV === 'prod' ? true : false;
};

export const ascentUrlForEnv = () => {
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        return 'https://plans.entermedicare.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        return 'https://plans.entermedicare-dev.com';
    } else {
        return 'http://localhost:3005';
    }
};

export const getCookieDomain = () => {
    let domain = '';
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        domain = '.entermedicare.com';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        domain = '.entermedicare-dev.com';
    }
    return domain;
};

export const setTrackingCookies = () => {
    let sessionId = Cookies.get('sid');
    let trackingId = Cookies.get('tid');

    if (!sessionId) {
        sessionId = uuidv4();
        Cookies.set('sid', sessionId, {
            expires: 1,
            domain: getCookieDomain(),
        }); // 1 days
    }

    if (!trackingId) {
        trackingId = uuidv4();
        Cookies.set('tid', trackingId, {
            expires: 30,
            domain: getCookieDomain(),
        }); // 30 days
    }
};

export const getTrackingIds = () => {
    const sessionId = Cookies.get('sid');
    const trackingId = Cookies.get('tid');
    return { sessionId, trackingId };
};

export const urlForEnv = () => {
    let link = '';
    if (process.env.REACT_APP_EM_ENV === 'prod') {
        link = 'https://learn.entermedicare.com/';
    } else if (process.env.REACT_APP_EM_ENV === 'dev') {
        link = 'https://learn.entermedicare-dev.com/';
    } else {
        link = 'http://localhost:2999/';
    }
    return link;
};

export const isLoggedIn = () => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
        const expiry = accessToken.split('@')[1];
        if (Date.now() < expiry) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const toCurrency = s => {
    if (s === 0) return '$0.00';
    if (!s) return s;
    if (typeof s === 'string') {
        const cleanStr = s.replace(/,/g, '');
        return isNaN(cleanStr)
            ? s
            : `${s.includes('$') ? '' : '$'}${parseFloat(cleanStr).toFixed(2)}`;
    } else {
        // Then it's an integer or float
        return `$${s.toFixed(2)}`;
    }
};

export const logout = toSignUp => {
    Cookies.remove('accessToken', {
        domain: getCookieDomain(),
    });
    Cookies.remove('refreshToken', {
        domain: getCookieDomain(),
    });
    clearInterval(refreshToken());
    localStorage.clear();
    if (!toSignUp) {
        if (window.location.pathname !== '/sign-in') {
            window.location.href = '/sign-in';
        }
    }
};

export const refreshToken = () => {
    const refreshTokenInterval = setInterval(() => {
        if (Cookies.get('refreshToken')) {
            refreshTokens(Cookies.get('refreshToken'));
        }
    }, 840000);
    return refreshTokenInterval;
};

export const linkWithAccessToken = link => {
    if (!link) {
        return '';
    }
    if (link.includes('?')) {
        return link + `&accessToken=${Cookies.get('accessToken')}`;
    } else {
        return link + `?accessToken=${Cookies.get('accessToken')}`;
    }
};

export const formatPhoneNumber = phoneNumberString => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }
    return null;
};
