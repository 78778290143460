import React from 'react';
import { TextField } from '@material-ui/core';

export default function EMTextField({ InputLabelProps, ...props }) {
    return (
        <TextField
            {...props}
            variant={props.variant || 'outlined'}
            InputLabelProps={{ required: false, ...InputLabelProps }}
        />
    );
}
