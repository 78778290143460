import React from 'react';
import {
    Typography,
    Grid,
    Paper,
    Button,
    ListItem,
    List,
    ListItemText,
    ListItemAvatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    ArrowForward,
    CheckCircle,
    RadioButtonUnchecked,
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { EMYellow, navbarBlue } from '../constants';
import Personal from '../assets/Personal.png';
import Coverage from '../assets/CoverageCoins.png';
import { get } from 'lodash';
import { linkWithAccessToken } from '../utils';

const useStyles = makeStyles(theme => ({
    cardDivider: {
        backgroundColor: '#EFF2F5',
        height: '4px',
    },
    defaultButton: {
        textTransform: 'none !important',
        color: navbarBlue,
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Source Sans Pro, sans-serif',
        marginRight: 8,
    },
    primaryButton: {
        backgroundColor: '#5d47bd',
        borderRadius: '4px',
        textTransform: 'none !important',
        color: '#fff',
        fontSize: 20,
        fontWeight: 700,
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    title: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        marginTop: 0,
        color: '#092744',
        fontWeight: 'bold',
        marginBottom: 0,
    },
    sectionContainer: {
        marginBottom: 20,
        display: 'block',
    },
    editLink: {
        color: '#5D47BD',
    },
    headerDivider: {
        backgroundColor: EMYellow,
        marginBottom: theme.spacing(2),
    },
    sectionTitleContainer: {
        borderBottom: `1px solid ${EMYellow}`,
        height: '28px',
    },
    sectionTitle: {
        fontWeight: 600,
    },
    section: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    paperHeader: {
        borderRadius: 8,
        border: '1px solid #E1E7ED',
        marginBottom: '4px',
        padding: theme.spacing(4),
    },
    paperBody: {
        borderRadius: 8,
        paddingTop: 0,
        paddingBottom: 0,
    },
    subtitle: {
        color: '#092744',
        lineHeight: '30px',
        fontSize: '20px',
        marginBottom: '16px',
    },
    existsCheckmark: {
        color: '#6E8CA8',
    },
    profileLabels: {
        fontWeight: 700,
    },
    avatar: {
        // marginLeft: 16,
        // marginRight: 16,
    },
    titleBodyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    titleCardContainer: {},
    titleImageContainer: {
        marginRight: 8,
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    arrayItem: {
        background: '#EFF2F5',
        color: '#1F4160',
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    titleRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    buttonContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    hidden: {
        visibility: 'hidden',
    },
}));

const profileCategories = {
    'profile-personal': {
        alt: 'Personal section image of a clipboard',
        imageSrc: Personal,
    },
    'profile-current': {
        alt: 'current coverage and preferences image of stacked coins',
        imageSrc: Coverage,
    },
};

function formatAddress(address) {
    const parsedAddress = `${get(address, 'street_line_1', '')} ${get(
        address,
        'street_line_2',
        ''
    )}, ${get(address, 'city', '')} ${get(address, 'state', '')}, ${get(
        address,
        'zip_code',
        ''
    )}`;
    return parsedAddress;
}

function ProfileSection(props) {
    const classes = useStyles();
    const { section } = props;

    const profileStarted = () => {
        if (section.data && section.data.length === 0) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <div className={classes.sectionContainer}>
            <Paper className={classes.paperHeader} elevation={0}>
                <Grid container>
                    <Grid item xs={12} sm={1}>
                        <div className={classes.titleImageContainer}>
                            <img
                                alt={profileCategories[section.key].alt}
                                src={profileCategories[section.key].imageSrc}
                                width={64}
                                style={{ marginRight: '8px' }}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={11}>
                        <div className={classes.titleCardContainer}>
                            <div className={classes.titleRow}>
                                <div className={classes.titleBodyContainer}>
                                    <div style={{ flexDirection: 'column' }}>
                                        <p
                                            className={`${classes.title} fs-unmask`}
                                            id={`${section.title}-title`}
                                        >
                                            {section.title}
                                        </p>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            className={`${classes.subtitle} fs-unmask`}
                                        >
                                            {section.subtitle}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <Grid container className={classes.buttonContainer}>
                                {profileStarted() ? (
                                    <Grid item xs={12} sm={12} md={6}></Grid>
                                ) : null}

                                {/* {!section.isComplete && ( */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div style={{ padding: 4 }}>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            className={`${classes.primaryButton} fs-unmask`}
                                            variant="contained"
                                            href={linkWithAccessToken(
                                                `${section.conversationLink}?conversationId=em2022q1`
                                            )}
                                            endIcon={<ArrowForward />}
                                        >
                                            {section.isComplete
                                                ? `Restart Onboarding Conversation`
                                                : `Begin`}
                                        </Button>
                                    </div>
                                </Grid>
                                {/* )} */}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paperBody}>
                <List className={classes.paperBody}>
                    {section.data &&
                        section.data
                            .filter(x => {
                                if (x.label === 'Doctors:') {
                                    x.value.forEach((val, index) => {
                                        if (val.indexOf(':') === -1) {
                                            const address = JSON.parse(
                                                x.selectedAddress[index]
                                            );
                                            x.value[index] =
                                                val +
                                                ': ' +
                                                formatAddress(address);
                                        }
                                    });
                                }
                                if (
                                    x.label !== 'Name:' &&
                                    x.label !== 'Name:' &&
                                    x.decisionType !== 'ZIP_CODE' &&
                                    x.decisionType !== 'BIRTH_DATE'
                                ) {
                                    return x;
                                }
                            })
                            .map((x, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ListItem
                                            divider
                                            centerRipple
                                            button={
                                                get(x, 'value') &&
                                                get(x, 'label') !== 'Name:'
                                            }
                                            onClick={() => {
                                                if (
                                                    get(x, 'value') &&
                                                    get(x, 'label') !== 'Name:'
                                                ) {
                                                    window.location.href = linkWithAccessToken(
                                                        get(x, 'editLink')
                                                    );
                                                }
                                            }}
                                            // style={{ paddingRight: 42 }}
                                        >
                                            <ListItemAvatar
                                                className={classes.avatar}
                                            >
                                                {get(x, 'value') ? (
                                                    <CheckCircle
                                                        className={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                ) : (
                                                    <RadioButtonUnchecked
                                                        classname={
                                                            classes.existsCheckmark
                                                        }
                                                    />
                                                )}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <span
                                                        className={`${classes.profileLabels} fs-unmask`}
                                                    >
                                                        {get(x, 'label', '')}
                                                    </span>
                                                }
                                                secondary={
                                                    get(x, 'value') ? (
                                                        get(x, 'value')
                                                    ) : (
                                                        <i> {`not provided`}</i>
                                                    )
                                                }
                                            />
                                            <a
                                                className={
                                                    x !== 'Name:'
                                                        ? `${classes.editLink} fs-unmask`
                                                        : classes.hidden
                                                }
                                                onClick={() =>
                                                    window.FS.event(
                                                        'Edit Section Clicked',
                                                        {
                                                            section: x.label,
                                                        }
                                                    )
                                                }
                                                href={linkWithAccessToken(
                                                    get(x, 'editLink')
                                                )}
                                            >
                                                Edit
                                            </a>
                                        </ListItem>
                                        {Array.isArray(get(x, 'value'))
                                            ? get(x, 'value').map((x, i) => {
                                                  return (
                                                      <ListItem
                                                          key={i}
                                                          divider
                                                          className={
                                                              classes.arrayItem
                                                          }
                                                      >
                                                          <ListItemAvatar
                                                              className={`${classes.avatar} fs-unmask`}
                                                          >
                                                              <RadioButtonUnchecked
                                                                  style={{
                                                                      visibility:
                                                                          'hidden',
                                                                  }}
                                                              />
                                                          </ListItemAvatar>
                                                          <ListItemText
                                                              disableTypography
                                                              primary={x}
                                                          />
                                                      </ListItem>
                                                  );
                                              })
                                            : null}
                                    </React.Fragment>
                                );
                            })}
                </List>
            </Paper>
        </div>
    );
}

export default withRouter(ProfileSection);
