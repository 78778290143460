import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Stories from 'react-insta-stories';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Auth, Slide } from '../components';
import Slide1 from '../assets/slide1.png';
import Slide2 from '../assets/slide2.png';
import Slide3 from '../assets/slide3.png';
import Slide4 from '../assets/slide4.png';
import Slide5 from '../assets/slide5.png';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    shoes: {
        margin: theme.spacing(2),
    },
    submit: {
        margin: '30px 0px !important',
    },
    modalButtonContainer: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    desktopAuthContainer: {
        background: '#fff',
        height: 640,
        border: '1px solid #E1E7ED',
        fontFamily: 'Halant, serif',
        borderRadius: 8,
        margin: 10,
        [theme.breakpoints.down('sm')]: {},
    },
    desktopStoriesContainer: {
        width: '100%',
        borderRadius: '8px !important',
    },
    mobileButtons: {
        margin: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },

    authContainer: {
        background: 'transparent',
        borderRadius: 8,
        marginTop: 28,
        maxWidth: 1200,
        margin: '0 auto',
        paddingBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(6),
        },
    },
    paperTitle: {
        height: 130,
        textAlign: 'center',
        fontFamily: 'Halant, serif',
        fontSize: 32,
        fontWeight: 700,
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    conditionalStoriesContainer: {
        margin: 10,
        [theme.breakpoints.up('sm')]: {
            height: 660,
        },
        [theme.breakpoints.down('sm')]: {
            height: 450,
            overflow: 'hidden',
        },
    },
});

class Authentication extends Component {
    state = {
        brokerId: '',
        email: '',
        error: '',
        modalType: '',
        redirectUrl: '',
        referenceCode: '',
        brokerCode: '',
    };

    auth = React.createRef();

    componentDidMount() {
        const imagesPreload = [Slide1, Slide2, Slide3, Slide4, Slide5];
        imagesPreload.forEach(image => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
        });

        const {
            brokerId,
            redirectUrl,
            referenceCode,
            brokerCode,
        } = queryString.parse(this.props.location.search);
        this.setState({ brokerId, redirectUrl, referenceCode, brokerCode });
    }

    render() {
        const { classes, isSignUp, history } = this.props;
        const cta = isSignUp ? 'Sign up' : 'Sign in';

        const stories = [
            {
                image: Slide1,
                text:
                    'We support you in deciding if Medicare is a good choice for you.',
            },
            {
                image: Slide2,
                text:
                    'When you complete your profile, you unlock custom learning & tips.',
            },
            {
                image: Slide3,
                text:
                    'We help lookout for your timelines AND help tailor your search, so you find the right plan for you.',
            },
            {
                image: Slide4,
                text:
                    'We created our courses and features with no financial support from plans or brokers.',
            },
            {
                image: Slide5,
                text:
                    'Next after you create an account, we will collect basic information and set up EnterMedicare for you.',
            },
        ].map(x => {
            return {
                content: () => <Slide image={x.image} text={x.text} />,
            };
        });

        return (
            <>
                <div className={classes.authContainer}>
                    <Grid container>
                        <Grid
                            item
                            md={8}
                            sm={12}
                            className={classes.desktopStoriesContainer}
                        >
                            <div
                                className={classes.conditionalStoriesContainer}
                            >
                                <Stories
                                    stories={stories}
                                    defaultInterval={6500}
                                    width={'100%'}
                                    loop={true}
                                />
                            </div>
                            <div className={classes.mobileButtons}>
                                <Button
                                    onClick={() => {
                                        history.push('/sign-up');
                                        this.auth.current.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'end',
                                        });
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    style={{
                                        height: 54,
                                        backgroundColor: '#342996',
                                    }}
                                >
                                    Sign up
                                </Button>
                                <Button
                                    onClick={() => {
                                        history.push('/sign-in');
                                        this.auth.current.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'end',
                                        });
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    style={{
                                        height: 54,
                                        color: '#342996',
                                    }}
                                >
                                    Login
                                </Button>
                            </div>
                        </Grid>

                        <Grid item md={4} sm={12}>
                            <div
                                ref={this.auth}
                                className={classes.desktopAuthContainer}
                            >
                                <div className={classes.paperTitle}>
                                    <p data-e2e={'pageTitle'}>{cta}</p>
                                </div>

                                <Auth
                                    isSignUp={isSignUp}
                                    cta={cta}
                                    authenticate={this.props.authenticate}
                                    authenticatedCallback={
                                        this.props.authenticatedCallback
                                    }
                                    referenceCode={this.state.referenceCode}
                                    brokerCode={this.state.brokerCode}
                                    brokerId={this.state.brokerId}
                                    redirectUrl={this.state.redirectUrl}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }
}

Authentication.propTypes = {
    isSignUp: PropTypes.bool,
    redirectToOnSuccess: PropTypes.string.isRequired,
    authenticate: PropTypes.func.isRequired,
    authenticatedCallback: PropTypes.func.isRequired,
    subscriptionType: PropTypes.string,
};

export default withStyles(styles)(withRouter(Authentication));
