import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import EMButton from '../components/EmButton';
import EMModal from './Modal';

const useStyles = makeStyles(theme => ({
    modalContent: {
        paddingTop: theme.spacing(12),
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(24),
        fontFamily: theme.typography.h4.fontFamily,
        fontSize: 54,
        fontWeight: 500,
    },
    divider: {
        marginTop: theme.spacing(4.5),
        color: 'yellow',
        background: '#f9d76d',
        width: '110%',
        marginBottom: theme.spacing(4.5),
    },
    buttonGroup: {
        width: '100%',
        marginTop: theme.spacing(4.5),
        display: 'flex',
        flexDirection: 'row',
    },
    ctaButton: {
        width: '50%',
        fontWeight: 'bold',
    },
    modalBodyFont: {
        fontWeight: 600,
        fontSize: 20,
    },
}));

function ProfileIncompleteModal(props) {
    const { show } = props;

    const classes = useStyles();

    const handleViewProfileClick = () => {
        props.hideModalCallback();
        props.history.push('/profile');
    };

    const modalContent = (
        <div className={classes.modalContent}>
            <div>
                <Typography variant="h1">
                    You still need to <br /> finish your profile
                </Typography>
                <Divider light classes={{ root: classes.divider }} />
                <Typography
                    variant="subtitle2"
                    className={classes.modalBodyFont}
                >
                    Your profile helps find options that fit you, based on
                    <br /> your prescriptions, doctors, household, and other
                    <br /> characteristics.
                </Typography>
                <div className={classes.buttonGroup}>
                    <EMButton
                        className={classes.ctaButton}
                        color="primary"
                        variant="contained"
                        onClick={handleViewProfileClick}
                        text={'Go to my profile'}
                        style={{ marginLeft: 0 }}
                    />
                </div>
            </div>
        </div>
    );
    return show ? (
        <EMModal
            title={''}
            isOpen={show}
            content={modalContent}
            size="lg"
            handleClose={handleViewProfileClick}
        />
    ) : null;
}

export default withRouter(ProfileIncompleteModal);
