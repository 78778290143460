import React, { Component } from 'react';
import { Typography, withStyles, Grid } from '@material-ui/core';
import EMButton from '../components/EmButton';
import EMTextField from '../components/EMTextfield';
import EMSnackbar from '../components/EMSnackbar';
import { get } from 'lodash';

import { submitContact, getProfile } from '../api';

const styles = theme => ({
    pageContainer: {
        background: theme.palette.primary.white,
        border: '1px solid #E1E7ED',
        boxShadow: '0 1px 8px 0 rgb(0 0 0 / 8%)',
        borderRadius: 8,
        marginTop: 66,
        padding: 0,
        paddingBottom: theme.spacing(4),
        margin: '0 auto',
    },
    header: {
        lineHeight: 0.5,
        borderBottom: '2px solid #EFF2F5',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 32,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        whiteSpace: 'pre-wrap',
        color: '#4d5055',
    },
    subheader: {
        padding: theme.spacing(4),
        paddingBottom: 0,
        color: '#4d5055',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        paddingTop: '16px',
    },
    submitButton: {
        width: '180px',
        marginLeft: 'auto',
        marginRight: 0,
        float: 'right',
    },
});

const promptText = `Write us a note.`;
const promptSubText =
    'We will make sure the right expert gets in touch with you quickly.';
const successText = 'We will be in touch soon!';
const successSubText =
    'We are finding the right expert for your message, and will get back in touch!';
class Contact extends Component {
    state = {
        body: '',
        error: false,
        isLoading: false,
        subject: '',
        submitSuccessful: false,
        snackbar: { show: false, message: '', variant: '' },
        profile: {
            broker: {},
        },
    };

    handleSubmit = async () => {
        const { subject, body } = this.state;
        if (!subject || !body) {
            this.setState({ error: true });
            return;
        }
        this.setState({ isLoading: true });
        const { error } = await submitContact({ subject, body });
        if (!error) {
            this.setState({ isLoading: false, submitSuccessful: true });
        } else {
            this.setState({
                isLoading: false,
                snackbar: {
                    show: true,
                    message: error,
                    variant: 'error',
                },
            });
        }
    };

    async componentDidMount() {
        // grab the customer's broker;
        const { result } = await getProfile();
        window.hj('identify', get(result, 'profile.id'), {
            employerId: get(result, 'employerId', 'unknown'),
        });
        if (!result.broker) {
            // How to handle if user has no broker? Use default broker
            result.broker = {
                firstName: 'Entermedicare',
                lastName: 'Team',
                agencyName: 'EnterMedicare',
                email: 'hello@entermedicare.com',
                calendarLink: 'https://calendly.com/dan-jones-3',
            };
        }
        this.setState({
            profile: result,
        });
    }

    maybeRenderSnackbar = () => {
        const { snackbar } = this.state;
        return (
            snackbar.show && (
                <EMSnackbar
                    variant={snackbar.variant}
                    message={snackbar.message}
                />
            )
        );
    };

    render() {
        const { classes } = this.props;
        const { error, isLoading, submitSuccessful } = this.state;
        return (
            <div className={classes.root}>
                <Grid container className={classes.gridParent}>
                    {this.state.brokerlink ? (
                        <>
                            <Grid item sm={4}>
                                <div className={classes.pageContainer}>
                                    <div
                                        className={classes.header}
                                        id={'contact-header-content'}
                                    >
                                        {submitSuccessful
                                            ? successText
                                            : promptText}
                                    </div>
                                    <div className={classes.subheader}>
                                        <Typography variant="subtitle2">
                                            {submitSuccessful
                                                ? successSubText
                                                : promptSubText}
                                        </Typography>
                                    </div>
                                    {!submitSuccessful && (
                                        <div className={classes.form}>
                                            <EMTextField
                                                margin="normal"
                                                required
                                                error={error}
                                                fullWidth
                                                id="subject"
                                                label="Click to write your subject here"
                                                name="subject"
                                                onFocus={() =>
                                                    this.setState({
                                                        error: false,
                                                    })
                                                }
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    this.setState({
                                                        subject: value,
                                                    })
                                                }
                                            />
                                            <EMTextField
                                                margin="normal"
                                                data-test="body"
                                                error={error}
                                                required
                                                fullWidth
                                                multiline
                                                rows={4}
                                                id="body"
                                                label="Click to write your message here"
                                                name="body"
                                                onFocus={() =>
                                                    this.setState({
                                                        error: false,
                                                    })
                                                }
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    this.setState({
                                                        body: value,
                                                    })
                                                }
                                            />
                                            <EMButton
                                                isLoading={isLoading}
                                                className={classes.submitButton}
                                                onClick={this.handleSubmit}
                                                text={'Submit'}
                                                color="primary"
                                            />
                                        </div>
                                    )}
                                    {this.maybeRenderSnackbar()}
                                </div>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item sm={2} />
                            <Grid item sm={8} style={{ height: 1200 }}>
                                <div className={classes.pageContainer}>
                                    <div
                                        className={classes.header}
                                        id={'contact-header-content'}
                                    >
                                        {submitSuccessful
                                            ? successText
                                            : promptText}
                                    </div>
                                    <div className={classes.subheader}>
                                        <Typography variant="subtitle2">
                                            {submitSuccessful
                                                ? successSubText
                                                : promptSubText}
                                        </Typography>
                                    </div>
                                    {!submitSuccessful && (
                                        <div className={classes.form}>
                                            <EMTextField
                                                margin="normal"
                                                required
                                                error={error}
                                                fullWidth
                                                id="subject"
                                                label="Click to write your subject here"
                                                name="subject"
                                                onFocus={() =>
                                                    this.setState({
                                                        error: false,
                                                    })
                                                }
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    this.setState({
                                                        subject: value,
                                                    })
                                                }
                                            />
                                            <EMTextField
                                                margin="normal"
                                                data-test="body"
                                                error={error}
                                                required
                                                fullWidth
                                                multiline
                                                rows={4}
                                                id="body"
                                                label="Click to write your message here"
                                                name="body"
                                                onFocus={() =>
                                                    this.setState({
                                                        error: false,
                                                    })
                                                }
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    this.setState({
                                                        body: value,
                                                    })
                                                }
                                            />
                                            <EMButton
                                                isLoading={isLoading}
                                                className={classes.submitButton}
                                                onClick={this.handleSubmit}
                                                text={'Submit'}
                                                color="primary"
                                            />
                                            {process.env.REACT_APP_EM_ENV !==
                                                'prod' && (
                                                <div
                                                    style={{
                                                        fontFamily:
                                                            'Times New Roman',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    By completing the form
                                                    above, I understand that a
                                                    licensed insurance agent
                                                    from [agency name] may call
                                                    or email me regarding
                                                    Medicare insurance options,
                                                    including Medicare
                                                    Advantage, Medicare
                                                    Supplement Insurance, and/or
                                                    Prescription Drug Plans.
                                                    Calls may be made by auto
                                                    dialer. Calls are for
                                                    marketing purposes. Cellular
                                                    carrier charges may apply.
                                                    Providing permission does
                                                    not impact eligibility to
                                                    enroll or the provision of
                                                    services. You can change
                                                    permission preferences at
                                                    any time by contacting
                                                    [phone #]. This is a
                                                    solicitation for insurance.
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {this.maybeRenderSnackbar()}
                                </div>
                            </Grid>
                            <Grid item sm={2}>
                                {' '}
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Contact);
