import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, find } from 'lodash';
import { EMCtaGold } from '../constants';

const useStyles = makeStyles(theme => ({
    headerCta: {
        marginTop: theme.spacing(4),
        paddingLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: EMCtaGold,
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    progressCheckbox: {
        '&.Mui-disabled': {
            color: '#5D47BD',
        },
    },
    formControlLabel: {
        color: 'black',
    },
}));

function CheckboxJourney({ profile, courseProgress }) {
    const classes = useStyles();
    const data = get(profile, 'result.sections[0].data', []);

    const handleChecked = data => {
        const name = get(find(data, { label: 'Name:' }), 'value');
        const zipcode = get(find(data, { decisionType: 'ZIP_CODE' }), 'value');
        const gender = get(find(data, { decisionType: 'GENDER' }), 'value');
        const smoke = get(find(data, { decisionType: 'SMOKE' }), 'value');
        const generic = get(find(data, { decisionType: 'GENERICS' }), 'value');
        return !!name && !!zipcode && !!gender && !!smoke && !!generic;
    };

    const enrolledAandB = () => {
        const enrollmentDates = get(
            find(data, { decisionType: 'ENROLLMENT_DATES' }),
            'value'
        );
        if (enrollmentDates) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.headerCta}>
            <FormControlLabel
                control={
                    <Checkbox
                        className={classes.progressCheckbox}
                        checked={handleChecked(data)}
                        color="secondary"
                        disabled
                    />
                }
                style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: 16,
                }}
                label={
                    <span className={classes.formControlLabel}>
                        Completed your profile
                    </span>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        className={classes.progressCheckbox}
                        checked={courseProgress}
                        color="secondary"
                        disabled
                    />
                }
                style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: 16,
                }}
                label={
                    <span className={classes.formControlLabel}>
                        Taken your first course
                    </span>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        className={classes.progressCheckbox}
                        checked={enrolledAandB()}
                        color="secondary"
                        disabled
                    />
                }
                style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: 16,
                }}
                label={
                    <span className={classes.formControlLabel}>
                        Enrolled in Medicare Parts A & B
                    </span>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        className={classes.progressCheckbox}
                        checked={profile && !!profile.appointment}
                        color="secondary"
                        disabled
                    />
                }
                style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: 16,
                }}
                label={
                    <span className={classes.formControlLabel}>
                        Scheduled time with expert
                    </span>
                }
            />
        </div>
    );
}

export default CheckboxJourney;
