import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    indicator: {
        color: theme.palette.primary.main,
    },
}));

export default function EMProgressIndicator(props) {
    const classes = useStyles();
    return <CircularProgress {...props} className={classes.indicator} />;
}
