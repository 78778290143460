import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Typography,
    withStyles,
    Container,
    Paper,
    Link,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import {
    getReviewInfo,
    getCurrentPlan,
    getPlansForQuote,
    getProfile,
    sendExpertInterestEmail,
} from '../api';
import {
    EMCtaGold,
    fadedBlue,
    loadingMessages,
    TPMODisclaimer,
} from '../constants';
import EMProgressIndicator from '../components/EMProgressIndicator';
import Computer from '../assets/Computer.png';
import Notepad from '../assets/Notepad.png';
import Coverage from '../assets/Coverage.png';
import { PlanSelection } from '../components/Plans';
// import { linkWithAccessToken } from '../utils';
import { find, get } from 'lodash';
import { useTour } from '@reactour/tour';
import { ChevronRight, Close } from '@material-ui/icons';
import { InlineWidget } from 'react-calendly';

const styles = theme => ({
    ctaLeft: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    calendly: {
        height: 300,
        width: '100%',
    },
    headerCta: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: EMCtaGold,
    },
    headerFirstRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerCtaImg: {
        width: 48,
        height: 26,
    },
    headerImg: {
        width: 64,
        height: 39,
        marginLeft: theme.spacing(2),
    },
    headerTitle: {
        fontSize: 32,
        fontWeight: 700,
    },
    headerPaper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
    },
    headerRightContainer: {
        marginLeft: theme.spacing(2),
    },
    img: {
        height: 64,
        width: 64,
        objectFit: 'contain',
    },
    infoItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(4),
    },
    infoItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoItemLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    directCompareContainer: {
        padding: '27px',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        minHeight: '291px',
    },
    directCompareBottom: {
        padding: '27px',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
    },
    directCompareImage: {
        height: '35px',
        width: '63px',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    directCompareDescription: {
        display: 'flex',
        marginLeft: '78px',
        marginBottom: theme.spacing(3),
    },
    directCompareCurrent: {
        border: 'none',
        width: 'auto',
    },
    bottomButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '35px',
        marginRight: '35px',
    },
    bottomNavButton: {
        display: 'flex',
        width: '193px',
        backgroundColor: EMCtaGold,
        color: '#2F4645',
        '&:hover': {
            backgroundColor: EMCtaGold,
        },
    },
    viewPlansButton: {
        color: '#fff',
        margin: 10,
        textTransform: 'capitalize',
        backgroundColor: '#5d47bd',
        '&:hover': {
            backgroundColor: '#5d47bd',
        },
    },

    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
    },
});

const useStyles = makeStyles(() => ({
    topBeginButton: {
        backgroundColor: '#5d47bd',
        '&:hover': {
            backgroundColor: '#34237d',
        },
        color: 'white',
        textTransform: 'capitalize',
    },
}));

const BeginButton = ({ onClick, isLoading, profile }) => {
    const classes = useStyles();
    const { setIsOpen } = useTour();
    if (window.localStorage.getItem('closeModal') === 'true') {
        setIsOpen(false);
    } else if (!isLoading) {
        if (!get(profile, 'seenOnboardingModal')) {
            setIsOpen(true);
        }
    }

    return (
        <Button
            onClick={() => onClick()}
            className={classes.topBeginButton}
            data-e2e={'beginButton'}
            data-tour={'step-1'}
            endIcon={<ChevronRight />}
            style={{ margin: '10px' }}
        >
            View All Plans
        </Button>
    );
};

class ReviewInfo extends Component {
    state = {
        quoteIds: [],
        quoteToShowFirst: null,
        preferNoReferralSpecialist: null,
        snackbar: { show: false, message: '', variant: '' },
        currentMonthlyPremium: '',
        loadingTextIndex: 0,
        loadingMessages,
        isLoading: true,
        showCalendar: false,
        broker: {},
        MAPlansData: {},
        calendlyModelOpen: false,
    };

    async componentDidMount() {
        this.setState({
            loadingTextIndex: 0,
            loadingMessages,
        });

        const accessToken = Cookies.get('accessToken');
        this.timeout = setInterval(() => {
            let currentIdx = this.state.loadingTextIndex;
            this.setState({
                loadingTextIndex:
                    (currentIdx + 1) % this.state.loadingMessages.length,
            });
        }, 3000);
        try {
            let [reviewInfoData, currentPlanData, profile] = await Promise.all([
                getReviewInfo(accessToken),
                getCurrentPlan(),
                getProfile(),
            ]);

            this.setState({
                customerInfo: reviewInfoData.result.recData,
                quoteIds: reviewInfoData.result.quoteIds,
                quoteToShowFirst: reviewInfoData.result.quoteToShowFirst,
                preferNoReferralSpecialist:
                    reviewInfoData.result.preferNoReferralSpecialist,
                currentPlan: currentPlanData.result,
                currentMonthlyPremium: find(profile.result.sections[1].data, {
                    responseType: 'PREMIUM',
                }).value,
                loadingTextIndex: 0,
                loadingMessages,
                profile: get(profile, 'result'),
            });

            if (currentPlanData.result.premium) {
                const medicareAdvantagePlansData = await getPlansForQuote({
                    quoteId: this.state.quoteIds[0],
                    carrierFilter: false,
                    userPrefersNoReferral: false,
                });

                clearInterval(this.timeout);

                this.setState({
                    firstAdvantagePlan: medicareAdvantagePlansData.plans[0],
                    MAPlansData: medicareAdvantagePlansData,
                    planType: medicareAdvantagePlansData.planType,
                });
            }

            this.setState({ isLoading: false });
        } catch (e) {
            console.log(e);
            this.setState({
                snackbar: { show: true, message: e, variant: 'error' },
            });
            this.setState({ isLoading: false });
        }
    }

    handleOpenCalendar = async () => {
        window.hj('event', `Review Info Talk To Broker Button Clicked`);
        const broker = await sendExpertInterestEmail({
            quoteId: this.state.MAPlansData.quoteId,
            contractId: this.state.firstAdvantagePlan.contractId,
            planName: this.state.firstAdvantagePlan.planName,
            planId: this.state.firstAdvantagePlan.planId,
        });

        this.setState({
            broker,
        });

        this.setState({
            calendlyModelOpen: true,
        });
    };

    getImgForItem = item => {
        switch (item.type) {
            case 'DRUG':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/pills.png',
                    alt: 'pills',
                };
            case 'DOCTOR':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/doctor.png',
                    alt: 'doctor',
                };
            case 'BENEFIT':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/shoes.png',
                    alt: 'shoes',
                };
            case 'PREFERENCES':
                return {
                    img: 'https://d31vqacjwzpnz3.cloudfront.net/shoes.png',
                    alt: 'shoes',
                };
            default:
                return { img: '', alt: '' };
        }
    };

    handleBeginClick = async () => {
        const {
            quoteIds,
            quoteToShowFirst,
            preferNoReferralSpecialist,
        } = this.state;

        window.hj('event', 'View All Plans');
        this.props.history.push({
            pathname: '/quote',
            search: `?quoteIds=${quoteIds.toString()}&quoteToShowFirst=${quoteToShowFirst}&referralPref=${preferNoReferralSpecialist}`,
            maQuoteId: quoteIds[0],
            suppQuoteId: quoteIds[1],
            pdpQuoteId: quoteIds[2],
            MAPlansData: this.state.MAPlansData,
        });
    };

    renderHeader = () => {
        const { classes } = this.props;
        return (
            <Paper className={classes.headerPaper}>
                <Grid item xs={12}>
                    <img
                        src={Computer}
                        className={classes.headerImg}
                        alt="Entermedicare"
                    />
                    <div className={classes.headerRightContainer}>
                        <Typography className={classes.headerTitle}>
                            Plans For You
                        </Typography>
                        <Typography variant="subtitle2">
                            Below is a brief comparison between your current
                            plan and a Medicare advantage plan. Once you&apos;re
                            ready, view plans to learn more.
                        </Typography>
                    </div>
                </Grid>
                <div className={classes.headerCta}>
                    <Grid item md={6} xs={12}>
                        <div className={classes.ctaLeft}>
                            <img
                                src={Notepad}
                                className={classes.headerCtaImg}
                                alt="Entermedicare"
                            />
                            <Typography
                                variant="subtitle2"
                                style={{
                                    fontWeight: 700,
                                    marginLeft: 8,
                                    color: fadedBlue,
                                }}
                            >
                                Looks good, view plans
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {!this.state.isLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <BeginButton
                                    isLoading={this.state.isLoading}
                                    onClick={this.handleBeginClick}
                                    profile={this.state.profile}
                                />

                                <Button
                                    className={classes.viewPlansButton}
                                    onClick={this.handleOpenCalendar}
                                >
                                    Speak to licensed agent
                                </Button>
                            </div>
                        )}
                    </Grid>
                </div>
            </Paper>
        );
    };

    renderCompare = () => {
        const { classes } = this.props;
        const {
            currentPlan,
            firstAdvantagePlan,
            planType,
            currentMonthlyPremium,
        } = this.state;
        if (currentPlan && currentPlan.premium) {
            return (
                <Grid container>
                    <Grid item md={6}>
                        {this.state.currentPlan ? (
                            <Paper className={classes.directCompareContainer}>
                                <div className={classes.ctaLeft}>
                                    <img
                                        src={Notepad}
                                        className={classes.directCompareImage}
                                        alt="Entermedicare"
                                    />
                                    <div className={classes.titleRow}>
                                        <div>
                                            <Typography
                                                variant="subtitle2"
                                                // className={classes.infoItemsContainer}
                                                style={{
                                                    fontWeight: 700,
                                                    marginLeft: 8,
                                                    color: fadedBlue,
                                                }}
                                            >
                                                Your Current Coverage
                                            </Typography>
                                        </div>
                                        <div>
                                            <Link href="/profile">Update</Link>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={classes.directCompareDescription}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontWeight: 400,
                                            marginLeft: 8,
                                            color: fadedBlue,
                                        }}
                                    >
                                        Review the information below. Click
                                        ‘Update’ to edit the values used
                                        below.&nbsp;
                                        <Link href="/profile">
                                            Click here
                                        </Link>{' '}
                                        to view profile and make further
                                        revisions.
                                    </Typography>
                                </div>
                                <PlanSelection
                                    planType={'MA'}
                                    currentMonthlyPremium={
                                        currentMonthlyPremium
                                    }
                                    plan={this.state.currentPlan}
                                    appointmentScheduled={null}
                                    showFAQ={false}
                                    planSelectedCallback={() => {}}
                                    planSavedCallback={() => {}}
                                    key={1}
                                    currentPlan={true}
                                    standalone={true}
                                    className={classes.directCompareCurrent}
                                />
                            </Paper>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item md={6}>
                        <Paper className={classes.directCompareContainer}>
                            <div className={classes.ctaLeft}>
                                <img
                                    src={Coverage}
                                    className={classes.directCompareImage}
                                    alt="Entermedicare"
                                />
                                <div className={classes.titleRow}>
                                    <div>
                                        <Typography
                                            variant="subtitle2"
                                            // className={classes.infoItemsContainer}
                                            style={{
                                                fontWeight: 700,
                                                marginLeft: 8,
                                                color: fadedBlue,
                                            }}
                                        >
                                            Example Individual Plan in Your Area
                                            (Zip Code)*
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.directCompareDescription}>
                                <Typography
                                    variant="subtitle2"
                                    style={{
                                        fontWeight: 400,
                                        marginLeft: 8,
                                        color: fadedBlue,
                                    }}
                                >
                                    Compare Plans to speak with a licensed
                                    Medicare agent and better understand your
                                    expected out-of-pocket costs.
                                </Typography>
                            </div>
                            <PlanSelection
                                partBPrice={this.state.currentPlan.partBPrice}
                                planType={planType}
                                plan={firstAdvantagePlan}
                                appointmentScheduled={null}
                                showFAQ={false}
                                planSelectedCallback={() => {}}
                                planSavedCallback={() => {}}
                                key={1}
                                standalone={true}
                                className={classes.directCompareCurrent}
                            />
                        </Paper>
                        <p>
                            We do not offer every plan available in your area.
                            Any information we provide is limited to those plans
                            we do offer in your area. Please contact
                            Medicare.gov or 1-800-MEDICARE to get information on
                            all of your options.
                        </p>
                    </Grid>
                </Grid>
            );
        }
    };

    renderUserResponses = () => {
        const { classes } = this.props;
        const { customerInfo, currentPlan } = this.state;
        if (customerInfo && currentPlan && !currentPlan.premium) {
            return (
                <div className={classes.pageContainer}>
                    <div className={classes.infoItemsContainer}>
                        {customerInfo.map((item, i) => {
                            const { img, alt } = this.getImgForItem(item);
                            return (
                                <Paper
                                    className={classes.infoItemContainer}
                                    key={i}
                                >
                                    <div className={classes.infoItemLeft}>
                                        <img
                                            className={classes.img}
                                            src={img}
                                            alt={alt}
                                        />
                                        <Typography
                                            variant="subtitle2"
                                            dangerouslySetInnerHTML={item.text}
                                            style={{
                                                marginLeft: 32,
                                                marginRight: 32,
                                            }}
                                        />
                                    </div>
                                    {/* {item.editLink && (
                                        <Link
                                            href={linkWithAccessToken(
                                                item.editLink
                                            )}
                                        >
                                            Edit
                                        </Link>
                                    )} */}
                                </Paper>
                            );
                        })}
                    </div>
                </div>
            );
        }
    };

    maybeRenderCalendlyModal = () => {
        const classes = this.props;
        return (
            <Dialog
                size="lg"
                onClose={() => this.setState({ calendlyModelOpen: false })}
                open={this.state.calendlyModelOpen}
                className={classes.calendly}
                style={{ minHeight: 200 }}
                PaperProps={{
                    style: {
                        width: '100%',
                    },
                }}
            >
                <DialogTitle>
                    {' '}
                    <Typography variant="h3">
                        {' '}
                        Schedule a Meeting
                        <IconButton
                            onClick={() =>
                                this.setState({ calendlyModelOpen: false })
                            }
                            style={{ float: 'right' }}
                        >
                            <Close />
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ paddding: 0 }}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {TPMODisclaimer}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        Please note that all voice communications will be
                        recorded in accordance with CMS rules.
                    </Typography>

                    <InlineWidget
                        url={this.state.profile.broker.calendarLink}
                        prefill={{
                            email: this.state.profile.email,
                        }}
                        // doing some heinous stuff to pass userEmail unchanged.
                        utm={{
                            utmMedium: process.env.REACT_APP_EM_ENV,
                            utmContent: this.state.profile.email,
                            utmCampaign: this.state.profile.employerId,
                            utmSource: get(
                                this,
                                'state.profile.broker.id',
                                null
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        );
    };

    render() {
        const { classes } = this.props;
        const { isLoading, loadingMessages, loadingTextIndex } = this.state;
        return isLoading ? (
            <div className={classes.progressContainer}>
                <EMProgressIndicator className={classes.progress} />
                <Typography variant="body1" color="textPrimary">
                    {loadingMessages[loadingTextIndex]}
                </Typography>
            </div>
        ) : (
            <Container maxWidth={'x1'}>
                <Grid container alignItems={'center'} justifyContent="center">
                    {this.renderHeader({ profile: this.props.profile })}
                    {this.renderCompare()}
                    {this.renderUserResponses()}
                    {this.maybeRenderCalendlyModal()}
                </Grid>
            </Container>
        );
    }
}

export default withRouter(withStyles(styles)(ReviewInfo));
