import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    footer: {
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '40px',
        marginBottom: '20px',
        fontSize: '12px',
        fontFamily: 'Times New Roman',
        textAlign: 'center',
    },
}));
const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            We do not offer every plan available in your area. Any information
            we provide is limited to those plans we do offer in your area.
            Please contact Medicare.gov or 1-800-MEDICARE to get information on
            all of your options.
        </div>
    );
};

export default Footer;
