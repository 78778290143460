import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { get, orderBy, reduce } from 'lodash';
import {
    Grid,
    withStyles,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    darken,
} from '@material-ui/core';
import {
    PlanType,
    EMPurple,
    loadingMessages,
    TPMODisclaimer,
} from '../constants';
import { getPlansForQuote, sendExpertInterestEmail, getProfile } from '../api';
import EMSnackbar from '../components/EMSnackbar';
import EMProgressIndicator from '../components/EMProgressIndicator';
import { PlanFaq, PlanDisplay } from '../components/Plans';
import { InlineWidget, openPopupWidget } from 'react-calendly';
import { Close } from '@material-ui/icons';

const styles = theme => ({
    footer: {
        marginTop: 'calc(5% + 40px)',
        bottom: 0,
        width: '100%',
        paddingBottom: 30,
    },
    link: {
        color: EMPurple,
        '&:hover': {
            cursor: 'pointer',
            color: darken(EMPurple, 0.1),
        },
    },
    profileSectionContainer: {
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
        },
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        justifyContent: 'space-between',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
    },
    scheduleButton: {
        borderRadius: 4,
        background: 'white',
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            right: '100px',
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            margin: '0 auto',
        },
    },
    planDisplayHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    rightContainer: {
        width: '100%',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(8),
        paddingRight: 0,
        paddingLeft: 0,
        backgroundColor: '#F9F9FB',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
        borderRadius: '8px 8px 0px 0px',
    },
    scheduleContainer: {
        textAlign: 'right',
        bottom: '10px',
        right: '20px',
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: 0,
            right: 0,
        },
    },
    fullContainer: {
        width: '100%',
    },
    twoThirdsContainer: {
        width: '66.6%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    oneThirdContainer: {
        width: '33.3%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    faqButton: {
        float: 'right',
        backgroundColor: 'white',
        marginTop: '7px',
    },
    calendly: {
        height: 300,
        width: '100%',
    },
});

class Plan extends React.Component {
    faqRef = null;

    state = {
        broker: {},
        isLoading: false,
        plans: [],
        filter: null,
        planType: PlanType.MA,
        currentQuoteIdIndex: 0,
        quoteIds: [],
        maQuoteId: '',
        suppQuoteId: '',
        pdpQuoteId: '',
        selectedPlanName: '',
        userPrefersNoReferral: 0,
        showSelectedPlanDialog: false,
        showNoScheduleDialog: false,
        showPlanSelectedModal: false,
        plansToDisplay: [],
        snackbar: {
            show: false,
            message: '',
            variant: '',
        },
        activeTab: 0,
        carrierFilterVal: false,
        talkToExpertModalButtonClicked: false,
        selectedPlan: {},
        showFAQ: true,
        showSaved: false,
        savedPlans: [],
        profile: {},
        loadingTextIndex: 0,
        loadingMessages,
        timeoutIntervalId: null,
    };

    async componentDidMount() {
        const { quoteIds, referralPref } = queryString.parse(
            this.props.location.search
        );
        window.hj('event', `Plan Page Reached`);
        const carrierFilterVal = false;
        // Triggered when coming from employee center conversation
        // Runs multiple quotes at once
        // ?quoteIds=abc123,def234
        if (!quoteIds) {
            this.props.history.push('/review-your-info');
        } else {
            const quoteIdsArr = quoteIds.split(',');
            const maQuoteId = get(
                this.props,
                'location.maQuoteId',
                quoteIdsArr[0]
            );
            const suppQuoteId = get(
                this.props,
                'location.suppQuoteId',
                quoteIdsArr[1]
            );
            const pdpQuoteId = get(
                this.props,
                'location.pdpQuoteId',
                quoteIdsArr[2]
            );

            const beginningStateObj = {
                quoteIds: quoteIdsArr,
                currentQuoteIdIndex: 0,
                carrierFilterVal,
                maQuoteId,
                suppQuoteId,
                pdpQuoteId,
                userPrefersNoReferral: referralPref,
                planType: 'MA',
                loadingTextIndex: 0,
                loadingMessages,
            };

            this.setState(beginningStateObj);
            this.fetchPlans({
                quoteId: maQuoteId,
                carrierFilter: carrierFilterVal,
                userPrefersNoReferral: referralPref,
            });
            const { result } = await getProfile();
            window.hj('identify', get(result, 'profile.id'), {
                employerId: get(result, 'employerId', 'unknown'),
            });
            let timeoutIntervalId = setInterval(() => {
                let currentIdx = this.state.loadingTextIndex;
                this.setState({
                    loadingTextIndex:
                        (currentIdx + 1) % this.state.loadingMessages.length,
                });
            }, 3000);
            this.setState({
                profile: result,
                broker: result.broker,
                timeoutIntervalId,
            });
        }
    }

    fetchPlans = async ({ quoteId, carrierFilter, userPrefersNoReferral }) => {
        this.setState({ isLoading: true });
        if (
            get(this.props, 'location.MAPlansData.plans') &&
            (this.state.planType === 'MA' || !this.state.planType)
        ) {
            if (this.state.timeoutIntervalId) {
                clearInterval(this.state.timeoutIntervalId);
            }
            this.setState({
                plans: get(this.props, 'location.MAPlansData.plans', []).map(
                    (plan, index) => ({
                        ...plan,
                        emName: `Plan ${index + 1} `,
                    })
                ),
                savedPlans: [],
                isLoading: false,
                timeoutIntervalId: null,
            });
        } else {
            const { plans, error, planType } = await getPlansForQuote({
                quoteId,
                carrierFilter,
                userPrefersNoReferral,
            });
            if (!error) {
                if (planType === PlanType.SUPP) {
                    if (this.state.timeoutIntervalId) {
                        clearInterval(this.state.timeoutIntervalId);
                    }
                    this.props.history.push({
                        SuppPlansData: plans,
                    });
                    this.setState({
                        plans,
                        savedPlans: [],
                        isLoading: false,
                        timeoutIntervalId: null,
                    });
                } else if (
                    planType === PlanType.MA ||
                    planType === PlanType.PDP
                ) {
                    if (this.state.timeoutIntervalId) {
                        clearInterval(this.state.timeoutIntervalId);
                    }
                    this.props.history.push({
                        PDPPlansData: plans,
                    });
                    this.setState({
                        plans: plans.map((plan, index) => ({
                            ...plan,
                            emName: `Plan ${index + 1} `,
                        })),
                        savedPlans: [],
                        isLoading: false,
                        timeoutIntervalId: null,
                    });
                } else {
                    if (this.state.timeoutIntervalId) {
                        clearInterval(this.state.timeoutIntervalId);
                    }
                    this.setState({
                        snackbar: {
                            show: true,
                            message: error,
                            variant: 'error',
                        },
                        isLoading: false,
                        timeoutIntervalId: null,
                    });
                }
            }
        }
    };

    maybeRenderSnackbar = () => {
        const { snackbar } = this.state;
        return snackbar.show ? (
            <EMSnackbar variant={snackbar.variant} message={snackbar.message} />
        ) : null;
    };

    handleTalkToExpertButtonClick = async () => {
        const quoteIds = this.state.quoteIds;
        let theQuoteId = get(this.props, 'match.params.quoteId', '');
        if (!theQuoteId && quoteIds.length > 0) {
            theQuoteId = quoteIds[0];
        }
        const broker = await sendExpertInterestEmail({
            quoteId: theQuoteId,
            contractId: this.state.selectedPlan.contractId,
            planName: this.state.selectedPlan.planName,
            planId: this.state.selectedPlan.planId,
        });

        this.setState({
            talkToExpertModalButtonClicked: true,
        });
    };

    handleSelectChange = async e => {
        const { maQuoteId, pdpQuoteId, suppQuoteId } = this.state;

        // Operating under the assumption quoteId1 is MA, quoteId2 is supp and quoteId3 is PDP (optional)
        const newPlanType = e.target.value;
        let newActiveQuoteId = '';

        let trackPlanView = 'Medicare Advantage';
        if (newPlanType === PlanType.MA) {
            newActiveQuoteId = maQuoteId;
            trackPlanView = 'Medicare Advantage';
        } else if (newPlanType === PlanType.SUPP) {
            newActiveQuoteId = suppQuoteId;
            trackPlanView = 'Medicare Supplement';
        } else if (newPlanType === PlanType.PDP) {
            newActiveQuoteId = pdpQuoteId;
            trackPlanView = 'Prescription Drug Plan';
        }
        window.hj('event', `Switch Plan View To ${trackPlanView}`);

        this.setState({ planType: newPlanType, isLoading: true }, async () => {
            const cachedMAPlans = get(
                this.props,
                'history.location.MAPlansData.plans'
            );
            if (newPlanType === 'MA' && cachedMAPlans) {
                if (this.state.timeoutIntervalId) {
                    clearInterval(this.state.timeoutIntervalId);
                }
                this.setState({
                    plans: cachedMAPlans.map((plan, index) => ({
                        ...plan,
                        emName: `Plan ${index + 1} `,
                    })),
                    planType: 'MA',
                    isLoading: false,
                    savedPlans: [],
                    timeoutIntervalId: null,
                });
            } else if (newPlanType === 'SUPP' && this.state.cachedSuppPlans) {
                if (this.state.timeoutIntervalId) {
                    clearInterval(this.state.timeoutIntervalId);
                }
                this.setState({
                    plans: this.state.cachedSuppPlans,
                    planType: 'SUPP',
                    isLoading: false,
                    savedPlans: [],
                    timeoutIntervalId: null,
                });
            } else if (newPlanType === 'PDP' && this.state.cachedPDPPlans) {
                if (this.state.timeoutIntervalId) {
                    clearInterval(this.state.timeoutIntervalId);
                }
                this.setState({
                    plans: this.state.cachedPDPPlans,
                    planType: 'PDP',
                    isLoading: false,
                    savedPlans: [],
                    timeoutIntervalId: null,
                });
            } else {
                let { plans, planType } = await getPlansForQuote({
                    quoteId: newActiveQuoteId,
                });
                if (planType === 'PDP') {
                    plans = plans.map((plan, index) => ({
                        ...plan,
                        emName: `Plan ${index + 1} `,
                    }));
                    this.setState({
                        cachedPDPPlans: plans,
                    });
                } else {
                    this.setState({
                        cachedSuppPlans: plans,
                    });
                }
                if (this.state.timeoutIntervalId) {
                    clearInterval(this.state.timeoutIntervalId);
                }
                this.setState({
                    plans,
                    planType,
                    savedPlans: [],
                    isLoading: false,
                    timeoutIntervalId: null,
                });
            }
        });
    };

    handlePlanSelected = plan => {
        this.setState(
            {
                showPlanSelectedModal: true,
                selectedPlan: plan,
            },
            async () => await this.handleTalkToExpertButtonClick()
        );
    };

    saveAndFilter = ({ plan, plans }) => {
        if (this.state.planType === 'SUPP') {
            const updatedPlans = plans.map(p =>
                `${p.companyName} ${p.planType}` ===
                `${plan.companyName} ${plan.planType}`
                    ? { ...p, saved: !p.saved }
                    : p
            );
            const savedPlans = updatedPlans.filter(p => p.saved);
            return { updatedPlans, savedPlans };
        } else {
            const updatedPlans = plans.map(p =>
                p.planName === plan.planName ? { ...p, saved: !p.saved } : p
            );
            const savedPlans = updatedPlans.filter(p => p.saved);
            return { updatedPlans, savedPlans };
        }
    };

    handlePlanSaved = plan => {
        const { plans } = this.state;
        const { updatedPlans, savedPlans } = this.saveAndFilter({
            plan,
            plans,
        });
        this.setState({ plans: updatedPlans, savedPlans });
    };

    returnToPlanSearch = () => {
        this.setState({ showPlanSelectedModal: false });
    };

    maybeRenderPlanSelectedModal = () => {
        const classes = this.props;
        let message = '';
        let { broker } = this.state;
        if (!broker) {
            // How to handle if user has no broker? Use default broker
            broker = {
                firstName: 'Entermedicare',
                lastName: 'Team',
                agencyName: 'EnterMedicare',
                email: 'hello@entermedicare.com',
                calendarLink: 'https://calendly.com/dan-jones-3',
            };
        }
        if (broker.firstname) {
            const displayName =
                broker.agencyName || broker.firstname + ' ' + broker.lastname;
            message = `${displayName} has been notified and will reach out shortly. You may also reach them via email at ${broker.email}`;
        }
        const modalContent = !this.state.talkToExpertModalButtonClicked ? (
            <div>
                <Typography variant="subtitle2" style={{ marginBottom: '1em' }}>
                    We found unbiased experts are the best way to get a plan
                    that fits you.
                </Typography>

                <Typography variant="subtitle2">
                    Click the button below for a Licensed Medicare Agent to
                    connect with you by phone or email to discuss your Medicare
                    options.{' '}
                    <a
                        href="https://entermedicare.com/partners"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Click here
                    </a>{' '}
                    to see the list of our agency partners.
                </Typography>
            </div>
        ) : (
            <div>
                <Typography variant="subtitle2" style={{ marginBottom: '1em' }}>
                    <span data-e2e="expertNotifiedText">{message}</span>
                </Typography>

                {!broker.calendarLink && (
                    <Typography variant="subtitle2">
                        {`We recommend you look through the FAQs found in Plan Search,
                        or visit the Learning Center to learn key topics for you.`}
                    </Typography>
                )}
            </div>
        );

        return (
            <Dialog
                content={modalContent}
                size="lg"
                onClose={() => this.setState({ showPlanSelectedModal: false })}
                onBackdropClick={this.returnToPlanSearch}
                open={this.state.showPlanSelectedModal}
                className={classes.calendly}
                style={{ minHeight: 200 }}
                PaperProps={{
                    style: {
                        width: '100%',
                    },
                }}
            >
                <DialogTitle>
                    {' '}
                    <Typography variant="h3">
                        {' '}
                        Schedule a Meeting
                        <IconButton
                            onClick={this.returnToPlanSearch}
                            style={{ float: 'right' }}
                        >
                            <Close />
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ paddding: 0 }}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {TPMODisclaimer}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        style={{
                            marginBottom: '1em',
                            textAlign: 'center',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        Please note that all voice communications will be
                        recorded in accordance with CMS rules.
                    </Typography>

                    <InlineWidget
                        url={broker.calendarLink}
                        prefill={{
                            email: this.state.profile.email,
                        }}
                        // doing some heinous stuff to pass userEmail unchanged.
                        utm={{
                            utmMedium: process.env.REACT_APP_EM_ENV,
                            utmContent: this.state.profile.email,
                            utmCampaign: this.state.profile.employerId,
                            utmSource: get(
                                this,
                                'state.profile.broker.id',
                                null
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        );
    };

    handleFilterCallback = selectedFilter => {
        let plansOrder = [];
        if (selectedFilter === 'premium') {
            plansOrder = orderBy(this.state.plans, 'premium', 'asc');
        } else if (selectedFilter === 'starRating') {
            const rankByStarRating = orderBy(
                this.state.plans,
                [i => !!i.starRating, i => i.starRating],
                ['desc', 'desc']
            );
            plansOrder = rankByStarRating;
        } else if (selectedFilter === 'inNetworkDoctors') {
            plansOrder = orderBy(
                this.state.plans,
                [
                    i =>
                        reduce(
                            i.doctors,
                            (sum, doctor) =>
                                doctor.doctorCoverage === 'In-Network'
                                    ? sum + 1
                                    : null,
                            0
                        ),
                ],
                ['asc']
            );
        }
        this.setState({
            filter: selectedFilter,
            plans: plansOrder,
        });
    };

    handleLearnMoreClick = () => {
        this.setState({
            showSelectedPlanDialog: true,
        });
    };

    handleShowSaved = () => this.setState({ showSaved: !this.state.showSaved });

    handleOpenCalendar = () => {
        let { calendarLink } = this.state.broker;
        if (calendarLink) {
            if (calendarLink.indexOf('https://') < 0) {
                calendarLink = 'https://' + calendarLink;
            }
            window.open(calendarLink, '_blank');
            window.focus();
            this.setState({ showPlanSelectedModal: false });
        }
    };
    maybeRenderPriceDisclaimer = () => {
        return this.state.planType === 'SUPP' ? (
            <Typography variant="body2" color="textSecondary">
                *This quote reflects current Medicare Supplement prices. If you
                are not currently eligible for Medicare, these prices may change
                before you are eligible to enroll.
            </Typography>
        ) : null;
    };

    render() {
        const {
            isLoading,
            planType,
            showFAQ,
            showSaved,
            broker,
            savedPlans,
            plans,
            loadingMessages,
            loadingTextIndex,
        } = this.state;
        const { classes } = this.props;
        if (!isLoading && this.state.timeoutIntervalId) {
            clearInterval(this.state.timeoutIntervalId);
            this.setState({ timeoutIntervalId: null });
        }
        return isLoading ? (
            <div className={classes.progressContainer}>
                <EMProgressIndicator className={classes.progress} />
                <br />
                <Typography variant="body1" color="textPrimary">
                    {loadingMessages[loadingTextIndex]}
                </Typography>
            </div>
        ) : (
            <div className={classes.profileSectionContainer}>
                <Grid container alignItems="flex-start">
                    <Grid className={classes.fullContainer} item>
                        <PlanDisplay
                            showFAQ={showFAQ}
                            filter={this.state.filter}
                            handleFilterCallback={this.handleFilterCallback}
                            plans={showSaved ? savedPlans : plans}
                            handleTypeChange={this.handleSelectChange}
                            planType={planType}
                            planSelectedCallback={this.handlePlanSelected}
                            showSaved={showSaved}
                            showSavedCallback={this.handleShowSaved}
                            planSavedCallback={this.handlePlanSaved}
                        />
                        {showFAQ && (
                            <PlanFaq
                                planType={planType}
                                scheduleCallCallback={this.handlePlanSelected}
                            />
                        )}
                    </Grid>
                    {plans.length > 0 && (
                        <div className={classes.footer}>
                            <Typography variant="body1">
                                Disclaimer: The online directory is for
                                reference only. While every effort is made to
                                ensure current, accurate data, changes occur
                                daily and may not be reflected here. Provider
                                information contained in this directory is
                                updated weekly, excluding holidays, Sundays, or
                                interruptions due to system maintenance,
                                upgrades or unplanned outages. This information
                                is subject to change at any time. Therefore
                                please check with the provider or schedule an
                                appointment with a Hub agent by clicking&nbsp;
                                {/*eslint-disable-next-line */}
                                <a
                                    href="#"
                                    className={classes.link}
                                    onClick={() =>
                                        openPopupWidget({
                                            url: broker.calendarLink,
                                        })
                                    }
                                >
                                    {'here'}
                                </a>
                            </Typography>
                        </div>
                    )}
                </Grid>
                {this.maybeRenderPlanSelectedModal()}
            </div>
        );
    }
}

Plan.propTypes = {
    quoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withRouter(withStyles(styles)(Plan));
