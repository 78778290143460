import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { EMFadedBlue } from './constants';
import EMButton from './EmButton';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: '#FBE4AD',
        color: EMFadedBlue,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

export default function EMFlatButton(props) {
    const classes = useStyles();

    const getIcon = () => {
        if (props.icon) {
            return props.icon;
        } else if (props.disableArrowIcon) {
            return undefined;
        } else {
            return <ArrowForward />;
        }
    };

    return (
        <EMButton
            className={`${classes.button} ${
                props.className ? props.className : ''
            }`}
            disableRipple
            disableElevation
            endIcon={getIcon()}
            {...props}
        />
    );
}

EMFlatButton.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    disableArrowIcon: PropTypes.bool,
    icon: PropTypes.any,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
    variant: PropTypes.string,
};
