import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { find, findIndex, get } from 'lodash';
import { navbarBlue } from '../constants';
import { linkWithAccessToken } from '../utils';

const useStyles = makeStyles(theme => ({
    navItem: {
        fontFamily: 'Source Sans Pro, sans-serif',
        display: 'inline-block',
        padding: '10px 20px',
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: 25,
        backgroundColor: navbarBlue,
        color: '#FFFFFF',
        transition: '0.3s',
        borderRadius: 4,
        minWidth: 110,
        textAlign: 'center',
        '&:hover': {
            filter: 'brightness(125%)',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 80,
        },
    },
    active: {
        color: '#092744',
        backgroundColor: '#E1E7ED',
        '&:hover': {
            filter: 'none',
        },
    },
    navContainer: {
        backgroundColor: navbarBlue,
        borderRadius: 4,
        width: 'fit-content',
    },
    navWrapper: {
        borderRadius: 4,
        display: 'inline-block',
        margin: '0 auto',
    },
}));

function CarouselNav(props) {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const handleTabClick = (e, index) => {
        console.log('just clicked', props.items[index]);
        if (
            props.items[index].displayName === 'Plans' &&
            !props.profile.isComplete
        ) {
            console.log('should open modal', props.profile);
            setShowPlanModal(true);
        } else {
            setActiveTab(index);
            props.history.push(props.items[index].url);
        }
    };

    useEffect(() => {
        props.location.pathname === '/quote'
            ? setActiveTab(2)
            : setActiveTab(
                  findIndex(props.items, ['url', props.location.pathname])
              );
    }, [activeTab, props.items, props.location.pathname]);

    const profileSection = find(
        get(props, 'profile.sections', []),
        section => section.key === 'profile-personal'
    );
    console.log('profileSection', profileSection);
    return (
        <div className={classes.navWrapper}>
            {activeTab >= 0 ? (
                <div className={classes.navContainer}>
                    {props.items.map(function(item, index) {
                        const style =
                            activeTab === index
                                ? `${classes.navItem} ${classes.active} fs-unmask`
                                : `${classes.navItem} fs-unmask`;
                        return (
                            <li
                                key={index}
                                data-tour={item.dataStep}
                                className={style}
                                onClick={e => handleTabClick(e, index)}
                            >
                                {item.displayName}
                            </li>
                        );
                    })}
                </div>
            ) : (
                <div className={classes.navContainer}>
                    {props.items.map(function(item, index) {
                        const style = `${classes.navItem} fs-unmask`;
                        return (
                            <li
                                key={index}
                                data-tour={item.dataStep}
                                className={style}
                                onClick={e => handleTabClick(e, index)}
                            >
                                {item.displayName}
                            </li>
                        );
                    })}
                </div>
            )}
            <Dialog
                open={showPlanModal}
                onClose={() => setShowPlanModal(false)}
            >
                <DialogTitle>Complete your profile</DialogTitle>
                <DialogContent>
                    {`You must complete your onboaring profile before you can see plans.`}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setShowPlanModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        href={linkWithAccessToken(
                            `${get(
                                profileSection,
                                'conversationLink',
                                ''
                            )}?conversationId=em2022q1`
                        )}
                    >
                        {' '}
                        Complete Onboarding{' '}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withRouter(CarouselNav);
