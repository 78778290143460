import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EMSnackbar from './EMSnackbar';
import EMTextField from './EMTextfield';
import EMPasswordValidator from './EMPasswordValidator';

import {
    Grid,
    InputAdornment,
    Link,
    Typography,
    withStyles,
    Container,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { AuthenticationModalType } from '../constants';
import { refreshToken, linkWithAccessToken, urlForEnv } from '../utils';
import Birdhouse from '../assets/Birdhouse.png';
import { get } from 'lodash';
import queryString from 'query-string';
import EMButton from '../components/EmButton';
import EMModal from '../components/Modal';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    shoes: {
        margin: theme.spacing(2),
    },
    submit: {
        margin: '30px 0px !important',
    },
    modalButtonContainer: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    desktopAuthContainer: {
        background: '#fff',
        height: 660,
        border: '1px solid #E1E7ED',
        fontFamily: 'Halant, serif',
        borderRadius: 8,
        margin: 10,
        [theme.breakpoints.down('sm')]: {},
    },
    desktopStoriesContainer: {
        width: '100%',
        borderRadius: '8px !important',
    },
    mobileButtons: {
        margin: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },

    authContainer: {
        background: 'transparent',
        borderRadius: 8,
        marginTop: 28,
        maxWidth: 1200,
        margin: '0 auto',
        paddingBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(6),
        },
    },
    paperTitle: {
        height: 130,
        textAlign: 'center',
        fontFamily: 'Halant, serif',
        fontSize: 32,
        fontWeight: 700,
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    conditionalStoriesContainer: {
        margin: 10,
        [theme.breakpoints.up('sm')]: {
            height: 660,
        },
        [theme.breakpoints.down('sm')]: {
            height: 450,
            overflow: 'hidden',
        },
    },
    capsLock: {
        marginTop: '0px',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

class Auth extends Component {
    state = {
        showError: false,
        showModal: false,
        showPassword: false,
        email: '',
        error: '',
        isPasswordValid: false,
        modalType: '',
        password: '',
        companyName: null,
        capsLock: false,
    };

    componentDidMount = () => {
        window.addEventListener('keydown', this.checkCaps);
    };

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.checkCaps);
    };

    checkCaps = e => {
        const stateModifier = get(e, 'getModifierState', null);
        if (stateModifier) {
            this.setState({ capsLock: e.getModifierState('CapsLock') });
        } else {
            this.setState({ capsLock: false });
        }
    };

    handleOnSubmit = async e => {
        this.setState({ showError: false });
        e.preventDefault();
        const { campaignId } = queryString.parse(this.props.location.search);
        const { email, password, redirectUrl, companyName } = this.state;
        const { result, error, showModal, modalType } = this.props.isSignUp
            ? await this.props.authenticate({
                  email,
                  password,
                  isSignUp: true,
                  referenceCode: this.props.referenceCode,
                  brokerCode: this.props.brokerCode,
                  campaignId: campaignId || null,
                  interaction: 'SIGN_UP',
                  companyName,
              })
            : await this.props.authenticate({
                  email,
                  password,
                  isSignUp: false,
                  campaignId: campaignId || null,
                  interaction: 'SIGN_IN',
              });
        if (error) {
            if (showModal) {
                this.setState({ showModal: true, error, modalType });
            } else {
                this.setState({ showError: true, error });
            }
        } else {
            if (this.props.isSignUp) {
                window.FS.identify(result.userData.id, {
                    email: result.userData.email,
                });
            }
            refreshToken();
            if (result.redirectUrl) {
                window.location.href = linkWithAccessToken(result.redirectUrl);
            } else {
                this.props.authenticatedCallback();
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    window.location.href = urlForEnv();
                }
            }
        }
    };

    handleEmailChange = ({ target: { value } }) => {
        this.setState({ email: value });
    };

    handlePasswordChange = ({ target: { value } }) => {
        this.setState({ password: value });
    };

    handleCompanyChange = ({ target: { value } }) => {
        this.setState({ companyName: value });
    };

    handlePasswordIsValidChange = valid => {
        if (valid !== this.state.isPasswordValid) {
            this.setState({
                isPasswordValid: valid,
            });
        }
    };

    errorToHtml = error => {
        const phrases = error.split('\n\n');
        return phrases.map(ph => <span key={ph}>{ph}</span>);
    };

    renderSignUpModalContent = () => {
        const { classes, history } = this.props;
        return (
            <div>
                <Typography variant="subtitle2">
                    {`If you already have an account, click the "Sign in" button below to navigate to the sign in page. Otherwise use a different email to create an account.`}
                </Typography>
                <div className={classes.modalButtonContainer}>
                    <EMButton
                        onClick={() =>
                            history.push(
                                `/sign-in${this.props.location.search}`
                            )
                        }
                        text={'Go to sign in'}
                        color="primary"
                    />
                    <EMButton
                        onClick={() => this.setState({ showModal: false })}
                        text={'Create account'}
                        color="secondary"
                    />
                </div>
            </div>
        );
    };

    maybeRenderModal = () => {
        const { showModal, error, modalType } = this.state;
        if (showModal) {
            if (modalType === AuthenticationModalType.PAY_WALL) {
                return (
                    <EMModal
                        title={'Please get in touch'}
                        imageSrc={Birdhouse}
                        isOpen
                        content={
                            <Typography
                                variant="subtitle2"
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {error}
                            </Typography>
                        }
                        size="sm"
                        handleClose={() => this.setState({ showModal: false })}
                    />
                );
            } else {
                return (
                    <EMModal
                        title={error}
                        imageSrc={Birdhouse}
                        isOpen
                        content={this.renderSignUpModalContent()}
                        size="sm"
                        handleClose={() => this.setState({ showModal: false })}
                    />
                );
            }
        } else {
            return null;
        }
    };

    maybeRenderError = () => {
        return this.state.showError ? (
            <EMSnackbar variant={'error'} message={this.state.error} />
        ) : null;
    };

    renderAlreadyHaveAccount = () => {
        return (
            <span>
                {'Already have an account? '}
                <Link
                    href={`/sign-in${this.props.location.search}`}
                    variant="body2"
                >
                    Sign In here.
                </Link>
            </span>
        );
    };

    renderForgotPassword = () => {
        return (
            <Grid
                container
                style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    fontSize: 16,
                }}
            >
                <Grid item xs>
                    <Link href="/forgot-password">Forgot password?</Link>
                </Grid>
                <Grid
                    item
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Link href={`/sign-up${this.props.location.search}`}>
                        Don&apos;t have an account?
                        <br />
                        Sign Up
                    </Link>
                </Grid>
            </Grid>
        );
    };

    handleShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    };

    showCapsLock = () => {
        if (this.state.capsLock) {
            return (
                <p className={this.props.classes.capsLock}>
                    Your CAPS lock is currently ON.
                </p>
            );
        }
    };

    showComapnyField = () => {
        if (!this.props.referenceCode) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { classes, isSignUp, cta } = this.props;
        const { showPassword } = this.state;
        return (
            <Container component="main" maxWidth="sm">
                <div className={classes.paper}>
                    <Typography
                        component="h1"
                        variant="h5"
                        className={classes.title}
                    ></Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={this.handleOnSubmit}
                    >
                        <EMTextField
                            margin="normal"
                            className={'fs-unmask'}
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={this.handleEmailChange}
                        />
                        {isSignUp ? (
                            <>
                                <EMTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                onClick={
                                                    this.handleShowPassword
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                position="end"
                                            >
                                                {showPassword
                                                    ? 'Hide Password'
                                                    : 'Show Password'}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.showComapnyField() && (
                                    <EMTextField
                                        margin="normal"
                                        // required
                                        fullWidth
                                        type="text"
                                        name="company"
                                        label="company"
                                        id="company"
                                        onChange={this.handleCompanyChange}
                                    />
                                )}
                                <div
                                    style={{
                                        backgroundColor: '#F7F8FA',
                                        padding: 10,
                                        color: '#1F4160',
                                        border: '1px solid #E1E7ED',
                                        borderRadius: 8,
                                    }}
                                >
                                    <EMPasswordValidator
                                        style={{
                                            color: '#1F4160',
                                        }}
                                        isValid={
                                            this.handlePasswordIsValidChange
                                        }
                                        password={this.state.password}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <EMTextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    onKeyDown={this.handlePasswordChange}
                                    onChange={this.handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                onClick={
                                                    this.handleShowPassword
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                position="end"
                                            >
                                                {showPassword
                                                    ? 'Hide Password'
                                                    : 'Show Password'}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {this.showCapsLock()}
                            </>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="secondary" />
                            }
                            style={{
                                fontFamily: 'Source Sans Pro, sans-serif',
                                fontSize: 16,
                            }}
                            label="Remember me"
                        />
                        <EMButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={
                                isSignUp ? !this.state.isPasswordValid : false
                            }
                            text={cta}
                            onClick={() => ({})}
                        />
                        {isSignUp
                            ? this.renderAlreadyHaveAccount()
                            : this.renderForgotPassword()}
                    </form>
                </div>
                {this.maybeRenderModal()}
                {this.maybeRenderError()}
            </Container>
        );
    }
}

export default withStyles(styles)(withRouter(Auth));
